<template lang="pug">
.hero.is-fullheight.is-info.is-bold
  .hero-body
    .container
      .column.is-6.is-offset-3
        .title.has-text-centered
          img(src="@/assets/images/logo/metacom-license-logo.svg" alt="METACOM Lizenzverwaltung" width="316" height="30")
          h3.title Backend-Login
        .box
          error-message(
            :error="requestError"
            :allow-retry="false"
            title="Fehler beim Anmelden: "
          )
          b-field(label="Benutzer")
            b-input(v-model="loginData.username", name="username", type="text")
          b-field(label="Passwort")
            b-input(v-model="loginData.password", type="password", name="password", password-reveal)
          .field
            button.button.is-fullwidth.is-info.is-large(
              @click="login()",
              :class="{'is-loading': this.isLoading}"
            ) Anmelden
          .buttons.is-centered
            router-link.button(to="/login") Zurück zur Anmeldung mit Lizenz

</template>

<script>
import ErrorMessage from '@/components/general/ErrorMessage'
export default {
  components: { ErrorMessage },
  data: () => {
    return {
      isLoading: false,
      requestError: null,
      loginData: {
        username: '',
        password: ''
      }
    }
  },
  methods: {
    async login () {
      this.isLoading = true
      try {
        await this.$store.dispatch('auth/login', { type: 'admin', data: this.loginData })
        this.isLoading = false
        this.$router.push('/backend')
      } catch (error) {
        console.error(error)
        this.isLoading = false
        if (!error.response) {
          error.message = 'Keine Verbindung. Bitte versuchen Sie es später erneut. '
        } else if (error.response.status >= 500 && error.response.status < 600) {
          error.message = 'Ein interner Fehler ist aufgetreten. Handlungsbedarf :)'
        } else if (error.response.status === 401) {
          error.message = 'Ungültige Kombination von Benutzer und Passwort'
        }
        this.requestError = error
      }
    }
  }
}
</script>
