import Vue from 'vue'
import Router from 'vue-router'
import LicenseLogin from '@/views/customer/LicenseLogin'
import Metacom9LicenseAssistant from '@/views/customer/Metacom9LicenseAssistant'
import LicenseLayout from '@/views/customer/LicenseLayout'
import LicenseOverview from '@/views/customer/LicenseOverview'
import DownloadView from '@/views/customer/DownloadView'
import MetacomTerms from '@/views/customer/MetacomTerms'

import BackendLogin from '@/views/admin/BackendLogin'
import BackendLayout from '@/views/admin/BackendLayout'
// import BackendDashboard from '@/views/admin/BackendDashboard'
import BackendLicenseOverview from '@/views/admin/license/BackendLicenseOverview'
import BackendLicenseSingle from '@/views/admin/license/BackendLicenseSingle'
import BackendLicenseCreate from '@/views/admin/license/BackendLicenseCreate'
import BackendOrderGenerationOverview from '@/views/admin/order-generation/BackendOrderGenerationOverview'
import BackendOrderGenerationSingle from '@/views/admin/order-generation/BackendOrderGenerationSingle'
import BackendDeliveryOverview from '@/views/admin/delivery/BackendDeliveryOverview'
import BackendDeliverySingle from '@/views/admin/delivery/BackendDeliverySingle'
import BackendDownloadOverview from '@/views/admin/download/BackendDownloadOverview'
import BackendUpgradeSessionSingle from '@/views/admin/upgrade-session/BackendUpgradeSessionSingle'
import BackendUpgradeSessionOverview from '@/views/admin/upgrade-session/BackendUpgradeSessionOverview'
import LicenseRecoveryView from '@/views/customer/LicenseRecoveryView'

import store from '../store'

Vue.use(Router)

const router = new Router({
  linkActiveClass: 'is-active',
  mode: 'history',
  routes: [
    {
      path: '/login',
      component: LicenseLogin,
      meta: { requiresAuth: false }
    },
    {
      path: '/m9-assistent',
      name: 'm9-license-assistant',
      component: Metacom9LicenseAssistant,
      meta: { requiresAuth: false }
    },
    {
      path: '/direct-login',
      redirect: '/login',
      meta: { requiresAuth: false }
    },
    {
      path: '/lizenz-wiederherstellen',
      name: 'license-recovery',
      component: LicenseRecoveryView,
      meta: { requiresAuth: false }
    },
    {
      path: '/',
      component: LicenseLayout,
      children: [
        {
          path: '',
          component: LicenseOverview,
          meta: { requiresAuth: true, licenseAuth: true, adminAuth: false }
        },
        {
          path: 'download',
          component: DownloadView,
          meta: { requiresAuth: true, licenseAuth: true, adminAuth: false }
        },
        {
          path: 'terms',
          component: MetacomTerms,
          meta: { requiresAuth: true, licenseAuth: true, adminAuth: false }
        }
      ]
    },
    {
      path: '/backend/login',
      component: BackendLogin,
      meta: { requiresAuth: false }
    },
    {
      path: '/backend',
      component: BackendLayout,
      children: [
        {
          path: '',
          redirect: '/backend/license'
        },
        {
          path: 'license',
          name: 'licenses',
          meta: { requiresAuth: true, licenseAuth: false, adminAuth: true },
          component: BackendLicenseOverview
        },
        {
          path: 'license/create',
          meta: { requiresAuth: true, licenseAuth: false, adminAuth: true },
          component: BackendLicenseCreate
        },
        {
          name: 'license-details',
          path: 'license/:id',
          meta: { requiresAuth: true, licenseAuth: false, adminAuth: true },
          component: BackendLicenseSingle
        },
        {
          path: 'order-generation',
          name: 'order-generations',
          meta: { requiresAuth: true, licenseAuth: false, adminAuth: true },
          component: BackendOrderGenerationOverview

        },
        {
          path: 'order-generation/:id',
          meta: { requiresAuth: true, licenseAuth: false, adminAuth: true },
          component: BackendOrderGenerationSingle
        },
        {
          path: 'delivery',
          name: 'deliveries',
          meta: { requiresAuth: true, licenseAuth: false, adminAuth: true },
          component: BackendDeliveryOverview

        },
        {
          path: 'delivery/:id',
          meta: { requiresAuth: true, licenseAuth: false, adminAuth: true },
          component: BackendDeliverySingle
        },
        {
          path: 'download',
          name: 'downloads',
          meta: { requiresAuth: true, licenseAuth: false, adminAuth: true },
          component: BackendDownloadOverview
        },
        {
          path: 'upgrade-session/:id',
          name: 'upgrade-session-details',
          meta: { requiresAuth: true, licenseAuth: false, adminAuth: true },
          component: BackendUpgradeSessionSingle
        },
        {
          path: 'upgrade-session',
          name: 'upgrade-sessions',
          meta: { requiresAuth: true, licenseAuth: false, adminAuth: true },
          component: BackendUpgradeSessionOverview
        }
      ]
    }

  ]
})

router.beforeEach(async (to, from, next) => {
  if (!to.meta.requiresAuth) {
    return next()
  }

  try {
    await store.dispatch('auth/ensureCurrentUser')
    if (to.meta.licenseAuth) {
      if (store.getters['auth/isAuthenticated']) {
        const userType = store.getters['auth/userType']
        if (userType === 'license') {
          next()
        } else if (userType === 'admin') {
          next('/backend')
        } else {
          next('/login')
        }
      } else {
        next('/login')
      }
    } else {
      if (store.getters['auth/isAuthenticated']) {
        const userType = store.getters['auth/userType']
        if (userType === 'admin') {
          next()
        } else if (userType === 'license') {
          next('/')
        } else {
          next('/login')
        }
      } else {
        next('/backend/login')
      }
    }
  } catch (err) {
    console.error('Error fetching user: ' + err)
    if (to.meta.adminAuth) {
      next('/backend/login')
    } else {
      next('/login')
    }
  }
})
export default router
