<template lang="pug">
  article.message.is-danger(v-if="error")
    .message-header
      .level(style="flex-grow: 1")
        .level-left
          p {{ title }}
        .level-right
          b-taglist
            b-tag(type="is-danger") Fehler-Code
            b-tag(type="is-white" v-if="error.response && error.response.status") {{ error.response.status }}
            b-tag(type="is-white" v-else) Keine Verbindung
        //- v-if="error.response && error.response.status"
        //- span(v-if="error.response") Status {{ error.response.status }}
        //- span(v-if="!error.response") Keine Verbindung
    .message-body
      p(v-if="prefix") {{ prefix }}
      p {{ message }}
      p.raw-data(v-if="showRaw && error.response"): vue-json-pretty(:data="error.response.data")
      p.raw-data(v-if="error.printOriginalMessage"): vue-json-pretty(:data="error.originalMessage")
      p.control
        button.button(
          v-if="allowRetry"
          :class="{'is-loading': isLoading}"
          @click="$emit('retry')"
        ) Erneut versuchen
</template>

<script>
import VueJsonPretty from 'vue-json-pretty'

const DEFAULT_STATUS_MESSAGES = {
  500: 'Ups, da ist etwas schiefgelaufen. Wir wurden informiert und kümmern uns darum - bitte versuchen Sie es in Kürze erneut.'
}

export default {
  components: {
    VueJsonPretty
  },
  props: {
    error: {
      type: Error,
      default: null
    },
    prefix: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: 'Fehler beim Abrufen:'
    },
    messageByStatus: {
      type: Object,
      default: null
    },
    showRaw: {
      type: Boolean,
      default: false
    },
    allowRetry: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mergedMessagesByStatus () {
      return {
        ...DEFAULT_STATUS_MESSAGES,
        ...(this.messageByStatus ?? {})
      }
    },
    message () {
      const status = this.error?.response?.status
      const messages = this.mergedMessagesByStatus
      if (status != null) {
        if (messages[status]) {
          return messages[status]
        }

        if (messages.default) {
          return messages.default
        }
      }

      return this.error.message
    }
  }
}
</script>

<style lang="scss" scoped>
  .raw-data {
    margin-top: 1em;
  }
  button {
    margin-top: 1em;
  }
</style>
