<template lang="pug">
widget-panel(title="Besondere Berechtigungen", icon="fa-user-lock")
  template(slot="actions")
  template(slot="content")
    table.table: tbody: tr: td
      special-permissions-list(
        :editing="editing"
        :permissions="permissions"
        @update:permissions="permissionsChanged($event)"
      )

</template>

<script>
import licenseMixin from '@/mixins/license'
import WidgetPanel from '@/components/general/WidgetPanel'
import SpecialPermissionsList from '@/components/admin/SpecialPermissionsList'
export default {
  components: { WidgetPanel, SpecialPermissionsList },
  mixins: [licenseMixin],
  props: {
    permissions: {
      type: Array,
      default: function () { return [] }
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    permissionsChanged (event) {
      this.$emit('update:permissions', event)
    }
  }
}
</script>
