<template lang="pug">
  #license-create
    .container.page
      license-create-form
</template>

<script>
import LicenseCreateForm from '@/components/admin/license/LicenseCreateForm'

export default {
  components: { LicenseCreateForm }
}
</script>
