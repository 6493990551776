<template>
  <div class="country-select">
    <b-field>
      <b-select
        :value="value"
        placeholder="Land auswählen.."
        expanded
        @input="$emit('input', $event)"
      >
        <option
          v-for="country in countries"
          :key="country.key"
          :value="country.key"
        >
          {{ country.name }}
        </option>
      </b-select>
    </b-field>
  </div>
</template>

<script>
import countryDefinitions from '@/lib/countries'
export default {
  props: {
    value: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      countries: Object.values(countryDefinitions)
    }
  }
}
</script>
