import axios from 'axios'

export default {
  /**
   * Retrieve all deliveries
   */
  list: async function (query = null) {
    return axios.get('api/v1/delivery' + (query ? '?' + query : ''))
  },

  /**
   * Get a delivery by id
   * @param {String} deliveryID - the delivery id
   */
  getByID: async function (deliveryID) {
    return axios.get(`api/v1/delivery/${deliveryID}`)
  },

  /**
   * Repeat a delivery by id
   * @param {String} deliveryID - the delivery id
   */
  repeatByID: async function (deliveryID) {
    return axios.post(`api/v1/delivery/${deliveryID}`)
  }
}
