<template>
  <div class="license-recovery-view">
    <div class="container">
      <div class="columns">
        <div class="column pt-6">
          <div
            v-if="!submitted"
            class="content"
          >
            <h1 class="title">
              Lizenzdokument erneut zusenden
            </h1>
            <p>Sie können sich ihr Lizenzdokument erneut per E-Mail zusenden lassen, falls Sie es verlegt haben. Dies funktioniert nur für gebundene Lizenzen.</p>
            <b-field
              label="E-Mail Adresse des Lizenznehmers"
              :type="errors.has('email') ? 'is-danger' : ''"
              :message="errors.first('email')"
            >
              <b-input
                v-model="email"
                v-validate="'required|email'"

                name="email"
                data-vv-as="E-Mail Adresse"
                type="email"
                placeholder="Ihre E-Mail Adresse"
              />
            </b-field>
            <error-message
              title="Fehler beim Erstellen der Wiederherstellungs-Anfrage"
              :error="error"
              :is-loading="isLoading"
              @retry="postRecovery()"
            />
            <b-button
              :disabled="errors.any()"
              type="is-primary"
              :loading="isLoading"
              @click="handleSubmit"
            >
              Lizenzdokument zusenden
            </b-button>
          </div>
          <div
            v-else
            class="content"
          >
            <h1 class="title">
              Anfrage zur Wiederherstellung entgegengenommen
            </h1>
            <p>Wir haben Ihre Wiederherstellungs-Anfrage entgegengenommen. Wenn in unserem System gebundene Lizenzen für die E-Mail Adresse „{{ email }}” hinterlegt sind, erhalten Sie innerhalb weniger Minuten eine E-Mail von der Adresse bestellung@metacom.shop. Bitte kontrollieren Sie auch Ihren Spam-Ordner!</p>
            <p>Bitte haben Sie Verständnis dafür, dass wir an dieser Stelle keine Auskunft darüber geben können, ob wir Lizenzen zu dieser E-Mail Adresse finden konnten oder nicht.</p>
            <b-button @click="reset">
              Eine weitere Wiederherstellungs-Anfrage senden
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <footer-bar />
  </div>
</template>

<script>
import FooterBar from '@/components/general/FooterBar'
import LicenseRecovery from '@/services/api/LicenseRecovery'
import ErrorMessage from '@/components/general/ErrorMessage.vue'
export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    FooterBar, ErrorMessage
  },
  data () {
    return {
      isLoading: false,
      error: null,
      email: null,
      submitted: false
    }
  },
  methods: {
    handleSubmit () {
      if (this.isLoading) {
        return
      }
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.postRecovery()
        }
      })
    },
    reset () {
      this.isLoading = false
      this.error = null
      this.email = null
      this.submitted = false
    },
    async postRecovery () {
      this.isLoading = true
      this.error = null

      try {
        await LicenseRecovery.createLicenseRecovery({ email: this.email })
        this.submitted = true
      } catch (error) {
        this.error = error
        console.error(error)
      } finally {
        this.isLoading = false
      }
    }
  }
}
</script>
