import axios from 'axios'

export default {
  /**
   * Get the currently logged in user
   */
  async getCurrent () {
    return axios.get('api/v1/auth/user')
  },

  /**
   * Get the status of a license by key
   */
  async getLicenseStatusByKey (key) {
    return axios.post('api/v1/auth/license-status', {
      key
    })
  },

  /**
   * Authenticate a user
   * @param {'license'|'admin'} userType - user type to authenticate
   * @param {*} data - login data
   */
  async authenticateUserOfType (userType, data) {
    return axios.post(`/api/v1/auth/${userType}`, data)
  }
}
