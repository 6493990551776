<template lang="pug">
  #upgrade-session-single
    section.section
      .container.page
        error-message(
          :error="fetchDataError"
          :isLoading="isLoading"
          show-raw, @retry="fetchData()"
          prefix="Fehler beim Abrufen der Upgrade-Session."
        )
        .nav.panel
          p.panel-heading Details der Upgrade-Session {{ $route.params.id }}
          .panel-block
            b-loading(:active.sync="isLoading")
            upgrade-session-details(:upgrade-session="upgradeSession", v-if="upgradeSession != null")
</template>

<script>
import UpgradeSessionAPI from '@/services/api/UpgradeSession'
import ErrorMessage from '@/components/general/ErrorMessage'
import UpgradeSessionDetails from '@/components/admin/upgrade-session/UpgradeSessionDetails'

export default {
  components: {
    ErrorMessage, UpgradeSessionDetails
  },
  data: function () {
    return {
      isLoading: false,
      fetchDataError: null,
      upgradeSession: null
    }
  },
  mounted: function () {
    this.fetchData()
  },
  methods: {
    /**
     * Fetch order-generation from api
     */
    fetchData: async function () {
      this.isLoading = true
      this.fetchDataError = null
      try {
        this.isLoading = false
        this.upgradeSession = (await UpgradeSessionAPI.getByID(this.$route.params.id)).data.data.session
      } catch (error) {
        this.isLoading = false
        this.fetchDataError = error
        console.error(error)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .panel-block .order-generation-details {
    width: 100%;
  }
</style>
