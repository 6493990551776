<template lang="pug">
widget-panel(title="Allgemein", icon="fa-info-circle")
  template(slot="actions")
  template(slot="content")

    table.table
      tbody
        tr
          th Status
          td(v-if="!editing") {{ getFormattedLicenseStatus(status) }}
          td(v-if="editing")
            b-select(
              :value="status"
              @input="statusUpdated($event)"
            )
              option(value="unbound") Ungebunden
              option(value="bound") Gebunden
              option(value="disabled") Deaktiviert
        tr
          th Lizenzschlüssel
          td
            copy-code(:copyValue="licenseKey", :displayValue="getFormattedLicenseKey(licenseKey)")
        tr
          th Startcode
          td
            copy-code(:copyValue="startCode", :displayValue="getFormattedLicenseStartCode(startCode)")
        tr
          th Aktueller Authentifizierungscode
          td(v-if="!editing")
            copy-code(:copyValue="authenticationCode")
          td(v-if="editing")
            b-input(
              :value="authenticationCode"
              @input="authenticationCodeUpdated($event)"
              type="text")
        tr
          th Direkt-Login URL
          td
            copy-code(displayValue="URL kopieren", :copyValue="getDirectLoginURLWithComponents(licenseKey, authenticationCode)")
        tr
          th Erzeugt durch
          td
            order-tag(v-if="typeOfEntry === 'order'" :orderNumber="String(entryReference)")
            span.tag(v-else-if="typeOfEntry === 'manual'")
              span Benutzer {{ entryReference }}
</template>

<script>
import licenseMixin from '@/mixins/license'
import WidgetPanel from '@/components/general/WidgetPanel'
import CopyCode from '@/components/admin/CopyCode'
import OrderTag from '@/components/general/OrderTag'
export default {
  components: { WidgetPanel, CopyCode, OrderTag },
  mixins: [licenseMixin],
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    status: {
      type: String,
      required: true
    },
    licenseKey: {
      type: String,
      required: true
    },
    startCode: {
      type: String,
      required: true
    },
    authenticationCode: {
      type: String,
      required: true
    },
    typeOfEntry: {
      type: String,
      required: true
    },
    entryReference: {
      type: String,
      required: true
    }
  },
  methods: {
    /**
     * Notify parent of updated license status
     */
    statusUpdated: function ($event) {
      this.$emit('update:status', $event)
    },
    /**
     * Notify parent of authentication code change
     */
    authenticationCodeUpdated: function ($event) {
      this.$emit('update:authenticationCode', $event)
    }
  }
}
</script>
