<template lang="pug">
  .license-preview
    b-message(
      title="Login mit dieser Lizenz möglicherweise nicht möglich"
      :active="hasLicenseAuthenticationCodeWarning(license)"
      type="is-warning"
    ) Der Authentifizierungscode stimmt nicht mit dem Namen des Lizenznehmers überein. Die Eigenschaft „Aktueller Authentifizierungscode” bestimmt, was der Nutzer eingeben muss, um sich mit einer Lizenz anzumelden. Wenn der Lizenznehmer hier manuell eingetragen wurde, muss der Wert des Lizenznehmers als Authentifizierungscode übernommen werden.

    .tag.is-fullwidth.is-warning(v-if="isEditing") In Bearbeitung
    article.media
      b-loading(:active.sync="isLoading")
      figure.media-left
        p.image.is-64x64
          img(:src="getLicenseImageOfLicense(license)")
      .media-content
        router-link.is-pulled-right(
          :to="'/backend/license/' + license._id"
          target="_blank"
          title="In neuem Tab öffnen (Shift-Klick für neues Fenster)"
        ): span.icon: i.fas.fa-external-link
        .content
          h3 {{ getFormattedNameOfLicense(license) }}
          b-tabs
            //- PROPERTIES TAB
            b-tab-item(label="Eigenschaften").properties
              //- Actions
              .buttons(v-show="!isEditing")
                .button.is-primary(@click="edit()") Bearbeiten

              .columns(v-if="license.updatedToLicense")
                .column
                  b-message(type="is-info")
                    p Für diese Lizenz wurde ein Update durchgeführt. Neue Lizenz:
                    router-link(tag="a" target="_blank" :to="{ name: 'license-details', params: { id: license.updatedToLicense }}") {{ license.updatedToLicense }}

              .columns
                .column
                  //- Notes
                  license-notes-widget(
                    :notes="localLicense.notes"
                    @update:notes="localLicense.notes = $event"
                    :editing="isEditing"
                  )

              .columns
                .column
                  //- Properties
                  license-properties-widget(
                    :editing="isEditing"
                    :version="localLicense.version"
                    :license-class="localLicense.properties.class"
                    :type="localLicense.properties.type"
                    :size="localLicense.properties.size"
                    :delivery="localLicense.properties.delivery"
                    :dvd-count="localLicense.properties.dvdCount"

                    @update:version="localLicense.version = $event"
                    @update:licenseClass="localLicense.properties.class = $event"
                    @update:type="localLicense.properties.type = $event"
                    @update:size="localLicense.properties.size = $event"
                    @update:delivery="localLicense.properties.delivery = $event"
                    @update:dvdCount="localLicense.properties.dvdCount = $event"
                  )

                  //- General
                  license-general-widget(
                    :editing="isEditing"
                    :status="localLicense.status"
                    :licenseKey="localLicense.key"
                    :startCode="localLicense.startCode",
                    :authenticationCode="localLicense.authenticationCode"
                    :typeOfEntry="localLicense.typeOfEntry"
                    :entryReference="localLicense.entryReference"

                    @update:status="localLicense.status = $event"
                    @update:authenticationCode="localLicense.authenticationCode = $event"
                    :class="{'is-warning': hasLicenseAuthenticationCodeWarning(license)}"
                  )
                .column
                  //- License Holder
                  license-holder-widget(
                    :editing="isEditing"
                    :holder-type="localLicense.holder.holderType"
                    :name="localLicense.holder.name"
                    :email="localLicense.holder.email"
                    :contact="localLicense.holder.contact"
                    :bound-at="localLicense.boundAt"
                    :status="localLicense.status"

                    @update:holderType="localLicense.holder.holderType = $event"
                    @update:name="localLicense.holder.name = $event"
                    @update:email="localLicense.holder.email = $event"
                    @update:contact="localLicense.holder.contact = $event"
                  )

                  //- Documents
                  license-documents-widget(
                    :id="localLicense._id"
                    :starterDocument="localLicense.starterDocument"
                    :licenseDocument="localLicense.licenseDocument"
                    :status="localLicense.status"

                    @update:starterDocument="starterDocumentUpdated($event)"
                    @update:licenseDocument="licenseDocumentUpdated($event)"
                    :editing="isEditing"
                  )

                  //- Permissions
                  license-permissions-widget(
                    :permissions="localLicense.specialPermissions",
                    @update:permissions="localLicense.specialPermissions = $event"
                    :editing="isEditing"
                  )

                  //- Downloads
                  license-downloads-widget(
                    :metacomDownloads="localLicense.downloads.metacom",
                    :metasearchDownloads="localLicense.downloads.metasearch"
                    :license-id="localLicense._id"

                    :editing="isEditing"
                    @update:metacom-downloads="localLicense.downloads.metacom = $event"
                    @update:metasearch-downloads="localLicense.downloads.metasearch = $event"
                  )

              error-message(
                title="Fehler beim Speichern der Lizenz: "
                :error="requestError"
                :show-raw="true"
                @retry="save()"
                :is-loading="isLoading"
              )

              .buttons(v-show="isEditing")
                .button.is-danger(@click="abort()") Änderungen verwerfen
                .button.is-primary(@click="save()", :disabled="errors.any()") Änderungen speichern
            //- JSON TAB
            b-tab-item.json-tab(label="JSON")
              vue-json-pretty(:data="localLicense")

            //- ORDER GENERATIONS TAB
            b-tab-item(label="Shop-Generierung")
              license-generations-tab(:license="license")

            //- DELIVERIES TAB
            b-tab-item(label="Auslieferungen")
              license-deliveries-tab(
                :license="license"
              )
            b-tab-item(label="Upgrade-Sessions")
              license-upgrade-sessions-tab(
                :license="license"
              )
  </template>

<script>
import LicensePropertiesWidget from '@/components/admin/license/LicensePropertiesWidget'
import LicenseGeneralWidget from '@/components/admin/license/LicenseGeneralWidget'
import LicenseHolderWidget from '@/components/admin/license/LicenseHolderWidget'
import LicenseDocumentsWidget from '@/components/admin/license/LicenseDocumentsWidget'
import LicensePermissionsWidget from '@/components/admin/license/LicensePermissionsWidget'
import LicenseDownloadsWidget from '@/components/admin/license/LicenseDownloadsWidget'
import LicenseNotesWidget from '@/components/admin/license/LicenseNotesWidget'
import LicenseGenerationsTab from '@/components/admin/license/LicenseGenerationsTab'
import LicenseDeliveriesTab from '@/components/admin/license/LicenseDeliveriesTab'
import LicenseUpgradeSessionsTab from '@/components/admin/license/LicenseUpgradeSessionsTab'
import LicenseTag from '@/components/general/LicenseTag'

import ErrorMessage from '@/components/general/ErrorMessage'

import licenseMixin from '@/mixins/license'
import VueJsonPretty from 'vue-json-pretty'

import { cloneDeep } from 'lodash'

import LicenseAPI from '@/services/api/License'

export default {
  // Used components
  components: {
    VueJsonPretty,
    ErrorMessage,
    LicensePropertiesWidget,
    LicenseGeneralWidget,
    LicenseHolderWidget,
    LicenseDocumentsWidget,
    LicensePermissionsWidget,
    LicenseDownloadsWidget,
    LicenseNotesWidget,
    LicenseGenerationsTab,
    LicenseDeliveriesTab,
    LicenseTag,
    LicenseUpgradeSessionsTab
  },

  // Inherited functionality
  mixins: [licenseMixin],

  // Interface
  props: {
    license: {
      type: Object,
      required: true
    }
  },

  // Vee validate own scope
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },

  // Component data
  data: function () {
    return {
      isEditing: false,

      originalLicense: {},
      localLicense: {},

      requestError: null,
      isLoading: false
    }
  },
  watch: {
    // Watch for license changes and update local cloned object
    license: {
      handler: function (val) {
        if (!this.isEditing) {
          this.cloneLocalLicense()
        }
      },
      deep: true
    }
  },
  // Initialize cloned local license
  created: function () {
    this.cloneLocalLicense()
  },

  // Instance methods
  methods: {
    /**
     * Make a clone of the license property to be able to revert editing changes
     */
    cloneLocalLicense: function () {
      this.setLocalLicense(cloneDeep(this.license))
    },

    /**
     * Ensure all fields needed to display the license correctly are set
     */
    setLocalLicense: function (localLicense) {
      if (!localLicense.holder) {
        localLicense.holder = {}
      }

      if (!localLicense.downloads) {
        localLicense.downloads = {}
      }
      this.localLicense = localLicense
    },

    /**
     * Begin editing the license
     */
    edit () {
      this.isEditing = true
      this.originalLicense = cloneDeep(this.license)
    },

    /**
     * Get the processed local license data that can be send to the server
     */
    getLocalLicenseData () {
      const license = JSON.parse(JSON.stringify(this.localLicense))

      // Set empty license holder strings to undefiend
      if (license.holder && license.holder.holderType == null) { license.holder.holderType = null }
      if (license.holder && license.holder.name === '') { license.holder.name = null }
      if (license.holder && license.holder.contact === '') { license.holder.contact = null }
      if (license.holder && license.holder.email === '') { license.holder.email = null }

      return license
    },
    /**
     * Save changes to the license
     */
    async save () {
      try {
        await this.$validator.validateAll()
      } catch (validationError) {
        console.warn('ValidationError: ' + validationError)
        return
      }

      // Perform update
      this.isLoading = true
      try {
        const licenseData = this.getLocalLicenseData()
        const { license } = (await LicenseAPI.putLicense(licenseData)).data.data
        this.isLoading = false
        this.requestError = null

        this.setLocalLicense(license)
        this.isEditing = false
        this.$emit('update:license', this.localLicense)

        this.$buefy.toast.open({
          message: 'Lizenz aktualisiert',
          type: 'is-success'
        })
      } catch (error) {
        console.error(error)
        this.isLoading = false
        this.requestError = error
      }
    },

    /**
     * Abort editing the license
     */
    abort () {
      this.message = 'Changes discarded'
      this.setLocalLicense(this.originalLicense)
      this.isEditing = false
      this.hasMessage = true
      this.message = 'Update aborted'
    },

    /**
     * Handle updates of the starter document
     * When a new starter document is generated, update all license instances
     * originalLicense, localLicense
     */
    starterDocumentUpdated (starterDocument) {
      this.localLicense.starterDocument = starterDocument
      if (this.isEditing) {
        this.originalLicense.starterDocument = starterDocument
        this.$emit('update:license', this.originalLicense)
      } else {
        this.$emit('update:license', this.localLicense)
      }
    },

    /**
     * Handle updates of the license document
     * When a new license document is generated, update all license instances
     * originalLicense, localLicense
     */
    licenseDocumentUpdated (licenseDocument) {
      this.localLicense.licenseDocument = licenseDocument
      if (this.isEditing) {
        this.originalLicense.licenseDocument = licenseDocument
        this.$emit('update:license', this.originalLicense)
      } else {
        this.$emit('update:license', this.localLicense)
      }
    }
  }

}
</script>

<style lang="scss" scoped>
  .tag.is-fullwidth {
    margin-bottom: 1em;
  }
</style>
