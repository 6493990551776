<template lang="pug">
   .container.page
    h1.title Alle Shop-Generierungen
    .content
      p Eine Shop-Generierung ist der Auftrag, den der Metacom-Shop im Bestellstatus „Lizenzgenerierung” an die Lizenzverwaltung sendet. Daraufhin erzeugt die Lizenzverwaltung die Lizenzen der Bestellung und versendet diese ggf. per E-Mail.
      p Diese Liste der Shop-Generierungen gibt Aufschluss darüber, wann die Lizenzverwaltung Aufträge empfangen hat und wie deren Status ist. Nach erfolgreicher Generierung wird der Shop benachrichtigt und die Bestellungen fahren im Ablauf fort.

      .columns
        .column
          //- Filters
          query-builder(
            :fields="filterFields"
            :filters="filters"
            @update:filter="onFilterChange($event)"
          )
        .column
          b-field(label="Shop-Generierungen pro Seite")
            b-select(
              v-model="orderGenerationsPerPage"
              @input="onOrderGenerationsPerPageChange($event)"
            )
              option(:value="20") 20
              option(:value="50") 50
              option(:value="100") 100
              option(:value="Infinity") Alle

      //- Error message
      error-message(
        :error="fetchError"
        :is-loading="isLoading"
        @retry="fetchOrderGenerations()"
        show-raw
      )

    b-table(
      :data="orderGenerations"
      :loading="isLoading"

      paginated
      backend-pagination
      :total="totalOrderGenerations"
      :per-page="orderGenerationsPerPage"
      :current-page="currentPage"
      @page-change="onPageChange"

      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"

      detailed
      detail-key="_id"
    )

      b-table-column(
        field="licenses"
        label="Lizenzen"
        v-slot="props"
      ) {{ props.row.licenses.length }} Lizenz{{ props.row.licenses.length > 1 ? 'en' : ''}}
      b-table-column(
        field="order"
        label="Bestellung"
        v-slot="props"
      )
        order-tag(:orderNumber="String(props.row.order)")

      b-table-column(
        field="progress"
        label="Status"
        v-slot="props"
      )
        progress-tag(:progress="props.row.progress")
      b-table-column(
        field="createdAt"
        label="Erzeugt am"
        sortable
        v-slot="props"
      )
        .tags(v-if="props.row.createdAt")
          date-tag(:date="props.row.createdAt")
          time-tag(:date="props.row.createdAt")
      template(v-slot:detail="props")
        router-link.is-pulled-right(
          :to="'/backend/order-generation/' + props.row._id"
          target="_blank"
          title="In neuem Tab öffnen (Shift-Klick für neues Fenster)"
        ): span.icon: i.fas.fa-external-link
        .content: h3 Details der Shop-Generierung {{ props.row._id }}
        order-generation-details(:order-generation="props.row")
</template>

<script>
import FilterHelper from '@/lib/filter-helper'
import OrderGenerationAPI from '@/services/api/OrderGeneration'

import QueryBuilder from '@/components/admin/query-builder/QueryBuilder'
import ErrorMessage from '@/components/general/ErrorMessage'
import OrderGenerationDetails from '@/components/admin/order-generation/OrderGenerationDetails'

import OrderTag from '@/components/general/OrderTag'
import ProgressTag from '@/components/general/ProgressTag'
import DateTag from '@/components/general/DateTag'
import TimeTag from '@/components/general/TimeTag'

export default {
  components: {
    OrderGenerationDetails, ErrorMessage, QueryBuilder, ProgressTag, OrderTag, DateTag, TimeTag
  },

  /**
   * When the current route is updated
   */
  beforeRouteUpdate (to, from, next) {
    this.updateQueryFromRoute(to) // Load updated query from route
    this.fetchOrderGenerations() // Fetch the order generations
    next()
  },
  data: function () {
    return {
      isLoading: false,
      fetchError: null,

      orderGenerations: [],
      totalOrderGenerations: 0,

      orderGenerationsPerPage: 20,
      currentPage: 1,
      sortField: 'createdAt',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      filters: [],

      filterFields: FilterHelper.orderGenerationFields

    }
  },

  /**
   * When component is mounted
   */
  mounted () {
    this.updateQueryFromRoute(this.$route) // Load initial query from route
    this.fetchOrderGenerations() // Fetch the order generations
  },

  methods: {
    /**
     * Fetch generations
     */
    fetchOrderGenerations: async function () {
      this.isLoading = true
      try {
        const query = this.getCurrentQueryParams()
        const { orderGenerations, total } = (await OrderGenerationAPI.list(query)).data.data

        this.isLoading = false
        this.fetchError = null

        this.orderGenerations = orderGenerations
        this.totalOrderGenerations = total

        if (this.totalOrderGenerations > 0 && ((this.currentPage - 1) * this.orderGenerationsPerPage) > this.totalOrderGenerations) {
          // Current page is out of view (e.g. after filter change)
          this.currentPage = Math.ceil(this.totalOrderGenerations / this.orderGenerationsPerPage) // Change current route
          this.updateRouteForQuery()
        }
      } catch (error) {
        console.error(error)
        this.isLoading = false
        this.fetchError = error
      }
    },

    /**
     * Get the query params to fetch current data
     */
    getCurrentQueryParams () {
      const queryComponents = [
        `limit=${this.orderGenerationsPerPage}`,
        `skip=${(this.currentPage - 1) * this.orderGenerationsPerPage}`,
        `sort=${(this.sortOrder === 'desc' ? '-' : '') + this.sortField}`
      ]
      if (this.filters.length > 0) {
        // Translate filters into query and concatenate them
        this.filters.forEach(filter => {
          queryComponents.push(FilterHelper.getQueryForFilter(filter))
        })
      }
      return queryComponents.join('&')
    },

    /**
     * Update the data query from the current route
     * Will read sort order, per page count and filters from url
     */
    updateQueryFromRoute (route) {
      if (route.query.filter) {
        this.filters = FilterHelper.decodeFromURL(route.query.filter)
      } else {
        this.filters = []
      }

      if (route.query.limit) {
        this.orderGenerationsPerPage = Number.parseInt(route.query.limit)
      }
      if (route.query.skip) {
        this.currentPage = Math.floor(Number.parseInt(route.query.skip) / this.orderGenerationsPerPage) + 1
      }
      if (route.query.sort) {
        this.sortOrder = route.query.sort.startsWith('-') ? 'desc' : 'asc'
        this.sortField = route.query.sort.split('-').pop()
      }
    },

    /**
     * Update the route to represent the current data query
     * Will put sort order, per page count and filters into the url
     */
    updateRouteForQuery () {
      const query = {
        limit: this.orderGenerationsPerPage,
        skip: (this.currentPage - 1) * this.orderGenerationsPerPage,
        sort: (this.sortOrder === 'desc' ? '-' : '') + this.sortField
      }
      if (this.filters.length > 0) {
        query.filter = FilterHelper.encodeForURL(this.filters)
      }
      this.$router.push({
        name: 'order-generations',
        query
      })
    },

    /**
     * Handle changes to the current filter
     * Will update the route and fetch with the changed query
     */
    onFilterChange (filters) {
      this.filters = filters
      this.updateRouteForQuery()
    },

    /**
     * Handle page changes
     * Fetch order generations for the current page
     */
    onPageChange (page) {
      this.currentPage = page
      this.updateRouteForQuery()
    },

    /**
     * Handle sorting changes
     * Fetch order generations for the changed sorting
     */
    onSort (field, order) {
      this.sortField = field
      this.sortOrder = order
      this.updateRouteForQuery()
    },

    onOrderGenerationsPerPageChange (orderGenerationsPerPage) {
      this.orderGenerationsPerPage = orderGenerationsPerPage
      this.updateRouteForQuery()
    }

  }

}
</script>
