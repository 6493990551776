<template>
  <div class="manual-order">
    <b-field label="In welches Land soll die Bestellung gehen?">
      <country-select v-model="selectedCountry" />
    </b-field>

    <b-field
      v-if="!purchasedLicenseData"
      label="Artikel oder Artikelnummer"
    >
      <b-autocomplete
        v-model="query"
        :data="filteredProducts"
        placeholder="z.B. „M9-PRO” oder „METACOM 9 Desktop Pro-Lizenz”"
        icon="search"
        clearable
        :disabled="!selectedCountry"
        @select="onProductSelected"
      >
        <template #empty>
          Kein Produkt gefunden
        </template>
        <template slot-scope="props">
          <div class="media">
            <div class="media-left">
              <img
                width="32"
                :src="getLicenseImageForClassAndType(props.option.properties.class, props.option.properties.type)"
              >
            </div>
            <div class="media-content">
              {{ props.option.name }}
              <br>
              <code>{{ props.option.sku }}</code>
            </div>
          </div>
        </template>
      </b-autocomplete>
    </b-field>

    <reseller-cart-quantity-editor
      v-if="purchasedLicenseData"
      class="mt-6"
      @remove="removeProduct"
    />

    <div class="content mt-6">
      <b-field
        v-if="purchasedLicenseData"
        label="Zusammenfassung des Kaufs:"
      >
        <cart-summary />
      </b-field>
    </div>

    <b-button
      type="is-primary"
      :disabled="!purchasedLicenseData"
      @click="$emit('submit')"
    >
      Zur Überprüfung
    </b-button>
  </div>
</template>

<script>
import licenseMixin from '@/mixins/license'
import CountrySelect from '@/components/general/CountrySelect'
import CartSummary from '@/components/customer/assistant/CartSummary.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'
import dvdImage from '@/assets/images/license-images/m9_dvd.svg'
import ResellerCartQuantityEditor from '@/components/customer/assistant/ResellerCartQuantityEditor.vue'

export default {
  components: {
    CountrySelect,
    CartSummary,
    ResellerCartQuantityEditor
  },
  mixins: [licenseMixin],
  data () {
    return {
      dvdImage,
      query: '',
      selectedProduct: null,
      products: [

        // {
        //   sku: 'M9-PROU',
        //   properties: { class: 'pro', type: 'update' },
        //   name: null
        // },
        {
          sku: 'M9-PER',
          properties: { class: 'personal', type: 'full' },
          name: null
        },
        {
          sku: 'M9-PRO',
          properties: { class: 'pro', type: 'full' },
          name: null
        }
        // {
        //   sku: 'M9-PERU',
        //   properties: { class: 'personal', type: 'update' },
        //   name: null
        // }
      ]

    }
  },
  computed: {
    ...mapState('assistant', ['licenseType']),
    ...mapGetters('assistant', ['purchasedLicenseData', 'canPurchaseDVDs']),
    filteredProducts () {
      if (!this.query) { return this.products }
      const q = this.query.toLowerCase()
      return this.products.filter(p => {
        return p.sku.toLowerCase().includes(q) || p.name.toLowerCase().includes(q)
      })
    },
    seatCount: {
      get () {
        return this.$store.state.assistant.seatCount
      },
      set (value) {
        this.updateState({ key: 'seatCount', value })
      }
    },
    dvdCount: {
      get () {
        return this.$store.state.assistant.dvdCount
      },
      set (value) {
        this.updateState({ key: 'dvdCount', value })
      }
    },
    selectedCountry: {
      get () {
        return this.$store.state.assistant.billing.countryCode
      },
      set (value) {
        this.$store.state.assistant.billing.countryCode = value

        if (!this.canPurchaseDVDs) {
          this.$store.state.assistant.dvdCount = 0
        }
      }
    }
  },
  mounted () {
    this.products.forEach(p => { p.name = this.getNameOfShopProduct(p.properties) })
  },
  methods: {
    ...mapMutations('assistant', ['updateState']),
    onProductSelected (product) {
      this.$store.state.assistant.licenseType = product.properties.class
      this.$store.state.assistant.purchaseType = 'new'
      this.$store.state.assistant.fromVersion = null
      this.$store.state.assistant.toVersion = '9'
    },
    removeProduct () {
      this.$store.state.assistant.licenseType = null
      this.$store.state.assistant.purchaseType = null
    }
  }
}
</script>
