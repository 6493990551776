<template lang="pug">
  span.tag
    span.icon: i.far.fa-calendar-alt
    span {{ date | moment("DD.MM.YY") }}
</template>

<script>
export default {
  props: {
    date: {
      type: String,
      required: true
    }
  }
}
</script>
