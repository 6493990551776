<template lang="pug">
  .order-generation-details
    b-tabs
      b-tab-item(label="Eigenschaften")
        .columns
          .column
            widget-panel(
              title="Eigenschaften"
              icon="fa-tasks"
            )
              template(slot="content")
                table.table
                  tbody
                    tr
                      th(data-label="Bestellung") Bestellung
                      td: order-tag(:orderNumber="String(orderGeneration.order)")
                    tr
                      th Status
                      td: progress-tag(:progress="orderGeneration.progress")
                    tr
                      th Versand angefragt
                      td {{ orderGeneration.deliver ? 'Nein' : 'Ja' }}

          .column
            widget-panel(
              title="Generierte Lizenzen"
              icon="fa-file"
              :has-content="orderGeneration.licenses && orderGeneration.licenses.length !== 0"
              empty-state-message="Keine Lizenzen"
            )
              template(slot="content")
                table.table: tbody: tr
                  .license-list
                    .license(
                      v-for="license in orderGeneration.licenses"
                      :class="{'is-active': currentLicenseId === license._id }"
                    )
                      license-identifier(:license="license")
                      router-link.is-pulled-right(
                        :to="'/backend/license/' + license._id"
                        target="_blank"
                        title="In neuem Tab öffnen (Shift-Klick für neues Fenster)"
                      ): span.icon: i.fas.fa-external-link
        widget-panel(
          title="Notiz"
          icon="fa-comment"
          :has-content="orderGeneration.note.length > 0"
          empty-state-message="Keine Notiz"
        )
          template(slot="content")
            table.table: tbody: tr: td {{ orderGeneration.note}}
        widget-panel(title="Fehler", icon="fa-exclamation-triangle", v-if="orderGeneration.error")
          template(slot="content")
            table.table: tbody: tr: td {{ orderGeneration.error }}

        widget-panel(
          title="Produkte"
          icon="fa-shopping-bag"
          :has-content="orderGeneration.products && orderGeneration.products.length !== 0"
          empty-state-message="Keine Produkte"
        )
          template(slot="content")
            table.table
              thead
                tr
                  th ID
                  th Name
                  th SKU
                  th PID
                  th VID
                  th Anzahl
              tbody
                tr(v-for="product in orderGeneration.products")
                  td {{ product.id }}
                  td {{ product.name }}
                  td {{ product.sku }}
                  td {{ product.productId }}
                  td {{ product.variationId }}
                  td {{ product.quantity }}
        widget-panel(
          title="Versand"
          icon="fa-envelope"
          :has-content="deliveries && deliveries.length !== 0"
          empty-state-message="Noch nicht versand"
        )
          template(slot="content")
            b-loading(:active.sync="isLoadingDeliveries")
            table.table(v-if="!isLoadingDeliveries")
              thead
                tr
                  th Datum
                  th Empfänger
                  th Status
                  th Anlass
                  th
                    //- Aktionen
              tbody
                tr(v-for="delivery in deliveries")
                  td: .tags(v-if="delivery.createdAt")
                    date-tag(:date="delivery.createdAt")
                    time-tag(:date="delivery.createdAt")
                  td {{ delivery.receiver }}
                  td: progress-tag(:progress="delivery.progress")
                  td {{ getFormattedOccasionOfDelivery(delivery) }}
                  td
                    router-link.is-pulled-right(
                      :to="'/backend/delivery/' + delivery._id"
                      target="_blank"
                      title="In neuem Tab öffnen (Shift-Klick für neues Fenster)"
                    ): span.icon: i.fas.fa-external-link
      b-tab-item(label="JSON")
        vue-json-pretty(:data="orderGeneration")
</template>

<script>

import WidgetPanel from '@/components/general/WidgetPanel'
import LicenseIdentifier from '@/components/admin/license/LicenseIdentifier'
import OrderTag from '@/components/general/OrderTag'
import ProgressTag from '@/components/general/ProgressTag'
import DeliveryMixin from '@/mixins/delivery'
import DateTag from '@/components/general/DateTag'
import TimeTag from '@/components/general/TimeTag'

import OrderGenerationAPI from '@/services/api/OrderGeneration'
import VueJsonPretty from 'vue-json-pretty'

export default {
  components: {
    WidgetPanel,
    LicenseIdentifier,
    OrderTag,
    ProgressTag,
    VueJsonPretty,
    DateTag,
    TimeTag
  },
  mixins: [DeliveryMixin],
  props: {
    orderGeneration: {
      type: Object,
      default: null
    },
    currentLicenseId: {
      type: String,
      default: null
    }
  },
  data: function () {
    return {
      isLoadingDeliveries: false,
      deliveries: []
    }
  },
  mounted: function () {
    this.fetchDeliveries()
  },
  methods: {
    /**
     * Fetch deliveries for this generation
     */
    fetchDeliveries: async function () {
      this.isLoadingDeliveries = true
      try {
        this.deliveries = (await OrderGenerationAPI.getDeliveries(this.orderGeneration._id)).data.data.deliveries

        this.isLoadingDeliveries = false
      } catch (err) {
        console.error(err)
        this.isLoadingDeliveries = false
      }
    }
  }

}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/bulma-variables.scss';
  .license-list {
    padding: 1rem;

    .license {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5em;
      padding: 0.2em;
      border-radius: 3px;

      .identifier {
        display: inline-flex;
      }
      &.is-active {
        background-color: $medium;
      }

    }
  }

</style>
