<template lang="pug">
  .level
    a(href="https://metacom.shop/datenschutzerklaerung", target="_blank").level-item Datenschutzerklärung
    a(href="/imprint.html", target="_blank").level-item Impressum
    a(:href="shopURL", target="_blank").level-item METACOM Shop
</template>

<script>
export default {
  computed: {
    shopURL () {
      return process.env.VUE_APP_SHOP_URL
    }
  }
}
</script>
