<template lang="pug">
  .special-permissions
    p(v-if="!editing && permissions.length == 0") Keine
    b-field(
      :key="permission.id"
      v-for="permission in allPermissions"
      v-if="editing || isPermissionSelected(permission.id)"
      :message="editing ? permission.note : ''"
    )
      b-checkbox(
        :disabled="!editing"
        :value="permissions"
        :native-value="permission.id"
        @input="selectedPermissionsChanged($event)"
      ) {{permission.description}}
</template>

<script>
export default {
  props: {
    permissions: {
      type: Array,
      default: () => { return [] }
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      allPermissions: [
        {
          id: 'download',
          description: 'METACOM Download',
          note: 'Download-Lizenzen haben diese Berechtigung automatisch.'
        },
        {
          id: 'metasearch-server-info',
          description: 'Infos zur MetaSearch Serverinstallation',
          note: 'Versionshinweise und Dokumentation. Serverlizenzen haben diese Berechtigung automatisch.'
        },
        {
          id: 'metasearch-multistep-installer',
          description: 'Download MetaSearch Multistep Installer',
          note: 'Download-Button neueste Windows-Version. Serverlizenzen haben diese Berechtigung automatisch.'
        },
        {
          id: 'metasearch-oneclick-installer',
          description: 'Download MetaSearch Oneclick Installer',
          note: 'Download-Button neueste Windows-, Mac- und Linux-Version. Serverlizenzen haben diese Berechtigung automatisch.'
        }
      ]
    }
  },
  methods: {
    /**
     * Check if a permission is selected
     * Moved into own function to allow automatic polyfill detection
     */
    isPermissionSelected (permissionID) {
      return this.permissions.includes(permissionID)
    },

    /**
     * Notify, when the selected permissiosn changed
     */
    selectedPermissionsChanged (event) {
      this.$emit('update:permissions', event)
    }
  }

}
</script>
