import axios from 'axios'

export default {
  /**
   * Get the MetaSearch version
   */
  getLatestVersion () {
    return axios.get('/api/v1/product/metasearch/latest-version')
  },

  /**
   * Get the MetaSearch download link for a certain platform
    * @param {'windows'|'mac'|'linux'|'linux-ia32'} platform - platform
   */
  getDownloadLinkForPlatform (platform) {
    return axios.get(`/api/v1/product/metasearch/platform/${platform}/download-link`)
  },

  /**
   * Get the multistep installer download link
   */
  getMultistepDownloadLink () {
    return axios.get('/api/v1/product/metasearch/extra/multistep/download-link')
  }

}
