<template lang="pug">
.metacom-download
    section.section(
      v-if="!didAcceptTerms"
    )
      .container
        h1.title METACOM herunterladen
        b-notification(
          type="is-info"
        ) Bevor Sie METACOM herunterladen können, müssen Sie den Nutzungsbedingungen zustimmen.

        .terms-wrapper.has-background-white.content
          metacom-terms-content
          .actions
            .field
              b-checkbox.is-medium(v-model="isContinueButtonEnabled") Ich habe die Nutzungsbedingungen gelesen und verstanden und akzeptiere sie
            button.button.is-primary.is-medium.is-fullwidth(
              :disabled="!isContinueButtonEnabled"
              @click="didAcceptTerms = true"
              ) Weiter zum Download

    div(
      v-if="didAcceptTerms"
    )
      section.section
        .container
          .content
            h1 Ihre Downloads
          .columns(v-if="canDownloadMetacom(license)")
            .column.is-6
              // METACOM DOWNLOAD
              .box.download-item
                article.media
                  .media-left
                    figure.image.is-64x64
                      img(:src="getLicenseProductImageForVersion(license.version)")
                  .media-content
                    .content
                      h3 {{ getLicenseVersionName(license) }}
                      p
                        ul(v-if="license.version === '8'")
                          li Mehr als 10.000 Symbole im JPG-Format und PNG-Format
                          li MetaSearch mit Stichwortsuche und Bearbeitungsfunktionen für Windows und Mac
                          li Layoutvorlagen für Office-Programme zum Ausdrucken von Bildkarten, Raumschildern, Talkerdeckblättern
                        ul(v-else-if="license.version === '9'")
                          li ca 17.000 Symbole im JPG-Format und PNG-Format
                          li MetaSearch mit Stichwortsuche, Dokumenteneditor und Bearbeitungsfunktionen für Windows, Mac und Linux
                .box-footer
                  button.button.is-fullwidth.is-primary(@click="openMetacomDownloadModal()") Zum Download

          label Ergänzende Tools und Infos:
          .tile.is-ancestor.is-vertical
            .tile
              .tile.is-6.is-parent(v-if="canDownloadMetasearchOneClickInstaller(license)")
                // DEFAULT INSTALLER
                .tile.is-child.box.download-item
                  article.media
                    .media-left
                      figure.image.is-48x48
                        img(src="@/assets/images/metasearch/metasearch_icon_notext.svg")
                      br
                      .level
                        .level-item
                          i.fab.fa-windows
                        .level-item
                          i.fab.fa-apple
                        .level-item
                          i.fab.fa-linux

                    .media-content
                      .content
                        h3 MetaSearch
                        ul
                          li Aktuellste Version von MetaSearch als separater Download
                          li Einfache Installation mit Unterstützung von automatischen Updates
                          li Für Windows, Mac und Linux
                  .box-footer
                    button.button.is-fullwidth.is-primary.is-outlined(@click="openMetaSearchOneClickDownloadModal()") Zum Download
              .tile.is-6.is-parent(v-if="canDownloadMetasearchMultistepInstaller(license)")
                // MULTISTEP INSTALLER
                .tile.is-child.box.download-item
                  article.media
                    .media-left
                      figure.image.is-48x48
                        img(src="@/assets/images/metasearch/metasearch_icon_notext.svg")
                      br
                      .level
                        .level-item
                          i.fab.fa-windows
                    .media-content
                      .content
                        h3 MetaSearch Multistep Installer
                        ul
                          li Spezielle Version des MetaSearch Installers für Windows
                          li Konfiguration der Installation über Installer-Parameter
                          li Auswahl des Installationsortes
                  .box-footer
                    button.button.is-fullwidth.is-primary.is-outlined(@click="openMetaSearchMultistepDownloadModal()") Zum Download
            .tile(v-if="canShowMetasearchServerDocumentation(license)")
              .tile.is-6.is-parent

                // SERVER INSTALLATION NOTES
                .tile.is-child.box.download-item
                  article
                    .content
                      h5 Hinweise für Serverinstallationen
                      p Sammlung von Hinweisen und Beispielen für die Softwareverteilung in IT-Systemen
                  .box-footer
                    a.button.is-primary.is-outlined.is-fullwidth.is-small(href="https://www.metacom-symbole.de/webextra/Serverinstallationen.pdf", target="_blank") PDF anzeigen
              .tile.is-6.is-parent
                // VERSION NOTES
                .tile.is-child.box.download-item
                  article
                    .content
                      h5 MetaSearch Versionshinweise
                      p Auflistung der veröffentlichten MetaSearch Versionen mit Aufführung der jeweiligen neuen Features und Änderungen
                  .box-footer
                    a.button.is-primary.is-outlined.is-fullwidth.is-small(href="https://metacom.shop/versionshinweise-metasearch/", target="_blank") Versionshinweise anzeigen
</template>

<script>
import MetacomTermsContent from '@/components/customer/MetacomTermsContent'
import MetacomDownloadModal from '@/components/customer/downloads/MetacomDownloadModal'
import MetaSearchOneClickDownloadModal from '@/components/customer/downloads/MetaSearchOneClickDownloadModal'
import MetaSearchMultistepDownloadModal from '@/components/customer/downloads/MetaSearchMultistepDownloadModal'
import licenseMixin from '@/mixins/license'

export default {
  components: {
    MetacomTermsContent
  },
  mixins: [licenseMixin],
  data () {
    return {
      isContinueButtonEnabled: false,
      didAcceptTerms: this.$route.query.didAcceptTerms || false,
      license: this.$store.getters['auth/userData']
    }
  },

  methods: {
    openMetacomDownloadModal () {
      this.$buefy.modal.open({
        parent: this,
        component: MetacomDownloadModal
      })
    },

    openMetaSearchOneClickDownloadModal () {
      this.$buefy.modal.open({
        parent: this,
        component: MetaSearchOneClickDownloadModal
      })
    },
    openMetaSearchMultistepDownloadModal () {
      this.$buefy.modal.open({
        parent: this,
        component: MetaSearchMultistepDownloadModal
      })
    }

  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/bulma-variables.scss';
  .terms-wrapper {
    padding: 2em;
    margin-bottom: 2em;
    .actions {
      margin-top: 2em;
    }
  }
  article.article.license-terms {
    padding: 2em;
    margin-bottom: 2em;
    .actions {
      margin-top: 2em;
    }
  }

  .download-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    .box-footer {
      padding-top: 0.5em;
    }
    .item-title {
      strong {
        font-size: 1.2em;
      }
      small {
        padding-left: 1em;
      }
    }
    .content ul {
      margin-left: 1.2em;
    }
  }
</style>
