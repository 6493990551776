import axios from 'axios'

export default {
  /**
   * Authenticate a user
   * @param {'license'|'admin'} userType - user type to authenticate
   * @param {*} data - login data
   */
  async createUpgradeSession (data) {
    return axios.post('/api/v1/upgrade-session', data)
  },

  async patchUpgradeSession (id, data, sessionToken) {
    return axios.patch('/api/v1/upgrade-session/' + id, data, {
      headers: {
        Authorization: `SessionToken ${sessionToken}`
      }
    })
  },

  /**
   * Retrieve all licenses
   */
  list: async function (query = null) {
    return axios.get('/api/v1/upgrade-session/' + (query ? '?' + query : ''))
  },

  /**
     * Get an order generation by its id
     * @param {String} upgradeSessionId - the order generation id
     */
  getByID: async function (upgradeSessionId) {
    return axios.get(`api/v1/upgrade-session/${upgradeSessionId}`)
  },

  /**
 * Get an order generation by its id
 * @param {String} upgradeSessionId - the order generation id
 */
  getByOfferToken: async function (offerToken) {
    return axios.get('api/v1/upgrade-session/offered', {
      headers: {
        Authorization: `OfferToken ${offerToken}`
      }
    })
  },

  createOffer: async function (upgradeSessionId) {
    return axios.post(`api/v1/upgrade-session/${upgradeSessionId}/offer`)
  }
}
