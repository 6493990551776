<template lang="pug">
  .license-create-dialog
    b-loading(:active.sync="isLoading")
    .modal-card
      .modal-card-head
        p.modal-card-title  Neue Lizenz(en) erstellen
        //- Open in new window
        router-link(
          to="/backend/license/create"
          target="_blank"
          v-if="isModal()",
          title="Diesen Dialog in neuem Tab öffnen (Shift-Klick für neues Fenster)"
        ).card-header-icon: span.icon: i.fas.fa-external-link
      .modal-card-body

        //- Show current request error
        article(class="message is-danger", v-if="hasRequestError")
          .message-header: p Fehler
          .message-body {{ requestError }}

        //-
          NEW LICENSE GENERATION FORM
          Set properties of new generated licenses
        form(
          @submit.prevent="validateAndSubmitForm()"
          v-if="!hasResult"
        )
          .content: h3 Eigenschaften
          .columns
            .column
              //- Version select
              b-field(
                label="METACOM Version"
                :type="errors.has('version') ? 'is-danger' : ''"
                :message="errors.first('version')"
              )
                b-select(
                  v-model="license.version"
                  name="version"
                  data-vv-as="Version"
                  v-validate="'required'"
                  expanded
                )
                  option(value="9", selected) METACOM 9 Desktop
                  option(value="8") METACOM 8

              //- Class select
              b-field(
                label="Klasse"
                :type="errors.has('class') ? 'is-danger' : ''"
                :message="errors.first('class')"
              )
                b-select(
                  placeholder="Klasse auswählen"
                  v-model="license.properties.class"
                  name="class"
                  data-vv-as="Klasse"
                  v-validate="'required'"
                  expanded
                )
                  option(value="personal") Persönliche Lizenz
                  option(value="pro") Pro-Lizenz
                  option(value="single") Einzellizenz
                  option(value="multiple") Mehrfachlizenz
                  option(value="server") Serverlizenz

              //- Type select
              b-field(
                label="Typ"
                :type="errors.has('type') ? 'is-danger' : ''"
                :message="errors.first('type')"
              )
                b-select(
                  placeholder="Typ auswählen"
                  v-model="license.properties.type"
                  name="type"
                  data-vv-as="Typ"
                  v-validate="'required'"
                  expanded
                )
                  option(value="full") Vollversion
                  option(value="update") Update

            .column
              //- Size input
              b-field(
                label="Größe"
                :type="errors.has('size') ? 'is-danger' : ''"
                :message="errors.first('size')"
              )
                b-input(
                  v-model="license.properties.size"
                  type="number"
                  name="size"
                  data-vv-as="Größe"
                  v-validate="'required|min_value:1'"
                  expanded
                )

              //- DVD count input
              b-field(
                label="Anzahl DVDs"
                :type="errors.has('dvdCount') ? 'is-danger' : ''"
                :message="errors.first('dvdCount')"
              )
                b-input(
                  v-model="license.properties.dvdCount"
                  type="number"
                  name="dvdCount"
                  data-vv-as="DVDs"
                  v-validate="'required'"
                  expanded
                )
              //- Delivery select
              b-field(
                label="Auslieferung"
                :type="errors.has('delivery') ? 'is-danger' : ''"
                :message="errors.first('delivery')"
              )
                b-select(
                  placeholder="Auslieferungsform auswählen"
                  v-model="license.properties.delivery"
                  name="delivery"
                  data-vv-as="Auslieferung"
                  v-validate="'required'"
                  expanded
                )
                  option(value="dvd") DVD
                  option(value="download") Download

          .content: h3 Zusätzliche Informationen
          //- Notes textarea
          b-field(label="Notizen")
            b-input(
              type="textarea"
              placeholder="Notizen"
              v-model="license.notes"
            )
          //- Type of entry select (disabled)
          b-field(grouped)
            b-field(label="Erzeugung der Lizenz" expanded)
              b-field
                b-select(name="typeOfEntry", v-model="typeOfEntry", disabled)
                  option(value="manual") Manuelle Eingabe durch
                b-input(v-model="username", disabled)

          //- Special permissions select
          b-field(label="Besondere Berechtigungen")
            .block
              special-permissions-list(:permissions.sync="license.specialPermissions")

          .content: h3 Weitere Optionen
          .field.is-grouped
            //- Multiple copies input
            b-field(
              label="Anzahl erzeugen"
              :type="errors.has('generationCount') ? 'is-danger' : ''"
              :message="errors.first('generationCount')"
            )
              b-input(
                type="number"
                v-model="generationCount"
                name="generationCount"
                data-vv-as="Lizenzanzahl"
                v-validate="'min_value:1|max_value:50'"
              )

            //- Generate pdfs checkbox
            b-field(label="Generierung")
              b-checkbox(v-model="generateStarterDocuments") Startdokument-PDF(s) generieren

        //-
          LICENSE GENERATION RESULTS
          Display the generated licenses and offer actions to continue with
        .results(v-if="hasResult")
          //- Message: Successful generation
          article(class="message is-success")
            .message-header: p Erzeugung erfolgreich
            .message-body {{ resultLicenses.length }} {{ resultLicenses.length > 1 ? 'Lizenzen' : 'Lizenz' }} erzeugt
          //- Details of the generated licenses
          .content
            h3 Erzeugte Lizenzen
            //- List of licenses
            ul
              li(v-for="license in resultLicenses")
                license-identifier(:license="license")
            //- Actions to download pdfs
            .pdf-actions(v-if="generateStarterDocuments")
              p: h6 {{ resultLicenses.length > 1 ? 'Startdokumente' : 'Startdokument' }} herunterladen
              p.buttons
                button.button.is-small.is-primary(v-if="resultLicenses.length > 1", @click="downloadEach()") Als ZIP
                button.button.is-small(v-if="resultLicenses.length > 1", @click="downloadBundled()") Als mehrseitiges PDF
                button.button.is-small.is-primary(v-if="resultLicenses.length == 1", @click="downloadSingle()") Herunterladen
            //- Further actions
            p: h6 Weitere Aktionen
            p.buttons
              button.button.is-small(@click="closeAndMark()", v-if="isModal()") Schließen und in Übersicht markieren
              //- button.button.is-small(@click="assignGroup()") Gruppe zuordnen
      .modal-card-foot
        //- Submit form
        button.submit.button.is-primary(v-if="!hasResult", @click="validateAndSubmitForm()") Erstellen
        //- Close modal
        button.button(type="button", @click="$parent.close()", v-if="!hasResult && isModal()") Abbrechen
        button.button(type="button", @click="$parent.close()", v-if="hasResult && isModal()") Schließen
        router-link.button(type="button", to="/backend/", v-if="!isModal()") Zurück zur Übersicht

</template>

<script>
import SpecialPermissionsList from '@/components/admin/SpecialPermissionsList'
import licenseMixin from '@/mixins/license'
import LicenseIdentifier from '@/components/admin/license/LicenseIdentifier'

import BatchDownloadAPI from '@/services/api/BatchDownload'
import LicenseAPI from '@/services/api/License'

import BlobHelper from '@/services/utility/BlobHelper'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    SpecialPermissionsList,
    LicenseIdentifier
  },
  mixins: [licenseMixin],
  data () {
    return {
      isLoading: false,
      hasRequestError: false,
      requestError: undefined,
      hasResult: false,
      resultLicenses: undefined,
      typeOfEntry: 'manual',
      username: this.$store.getters['auth/userData'].username,
      generationCount: 1,
      generateStarterDocuments: true,
      license: {
        version: '9',
        properties: {
          class: 'pro',
          size: 1,
          type: 'full',
          delivery: 'download',
          dvdCount: 0
        },
        notes: null,
        specialPermissions: []
      }
    }
  },

  methods: {
    /**
     * Check wether the form is displayed in a modal
     */
    isModal () {
      return this.$parent &&
        this.$parent &&
        this.$parent.$options &&
        this.$parent.$options.name === 'BModal'
    },

    /**
     * Validate create form
     */
    validateAndSubmitForm () {
      this.$validator.validateAll().then((isFormValid) => {
        if (isFormValid) {
          const postData = {
            version: this.license.version,
            properties: {
              class: this.license.properties.class,
              size: this.license.properties.size,
              type: this.license.properties.type,
              delivery: this.license.properties.delivery,
              dvdCount: this.license.properties.dvdCount
            },
            specialPermissions: this.license.specialPermissions,
            notes: this.license.notes,

            generateStarterDocument: this.generateStarterDocuments,
            count: this.generationCount
          }
          this.isLoading = true
          this.hasRequestError = false
          this.requestError = undefined
          LicenseAPI.postLicense(postData)
            .then((response) => {
              this.isLoading = false
              this.hasRequestError = false
              this.requestError = undefined

              this.resultLicenses = response.data.data.licenses
              this.hasResult = true

              this.$emit('generated-licenses', this.resultLicenses)
            })
            .catch((error) => {
              console.error(error)
              this.isLoading = false
              this.hasRequestError = true
              this.requestError = error.toString()
            })
        }
      })
    },

    /**
     * Download each pdf either as separate files or as zip
     */
    downloadEach () {
      const licenseIDs = this.resultLicenses.map(l => l._id)
      this.isLoading = true
      // Request one big pdf
      BatchDownloadAPI.getStarterDocumentsZIP(licenseIDs)
        .then((response) => {
          this.isLoading = false
          this.hasRequestError = false
          this.requestError = undefined

          BlobHelper.downloadResponseBlob(response, 'application/zip', 'METACOM_Startdokumente.zip')
        })
        .catch((error) => {
          console.error(error)
          this.isLoading = false
          this.hasRequestError = true
          this.requestError = error.toString()
          if (error.response.data && error.response.data.error && error.response.data.error.message) {
            this.requestError += ': ' + error.response.data.error.message
          }
        })
    },

    /**
     * Download all pdfs as one multi page document
     */
    downloadBundled () {
      const licenseIDs = this.resultLicenses.map(l => l._id)
      this.isLoading = true
      // Request one big pdf

      BatchDownloadAPI.getStarterDocumentsPDF(licenseIDs)
        .then((response) => {
          this.isLoading = false
          this.hasRequestError = false
          this.requestError = undefined

          BlobHelper.downloadResponseBlob(response, 'application/pdf', 'METACOM_Startdokumente.pdf')
        })
        .catch((error) => {
          this.isLoading = false
          this.hasRequestError = true
          this.requestError = error.toString()
          if (error.response.data && error.response.data.error && error.response.data.error.message) {
            this.requestError += ': ' + error.response.data.error.message
          }
        })
    },

    /**
     * Download a single license pdf
     */
    async downloadSingle () {
      const licenseID = this.resultLicenses[0]._id
      this.isLoading = true
      try {
        const { url } = (await LicenseAPI.getStarterDocumentURLForLicense(licenseID)).data.data
        this.isLoading = false
        window.open(url)
      } catch (error) {
        this.isLoading = false
        this.hasRequestError = true
        this.requestError = error.toString()
        if (error.response.data && error.response.data.error && error.response.data.error.message) {
          this.requestError += ': ' + error.response.data.error.message
        }
      }
    },

    /**
     * Close modal and mark in overview
     */
    closeAndMark () {
      this.$emit('finished-with-licenses', this.resultLicenses)
      this.$parent.close()
    },

    /**
     * Assign licenses to group
     */
    assignGroup () {

    }
  }
}
</script>

<style lang="scss" scoped>
  h3 {
    margin-top: 30px;
  }
</style>
