<template lang="pug">
  .identifier
    p.image.is-24x24: img(:src="getLicenseImageOfLicense(license)")
    .tags.has-addons(:title="getFormattedNameOfLicense(license)")
      //- License version
      span.tag.is-dark M{{license.version}}
      //- License type
      span.tag.is-full(v-if="license.properties.type == 'full'") V
      span.tag.is-update(v-if="license.properties.type == 'update'") U
      //- License class
      span.tag.is-single(v-if="license.properties.class == 'single'") Einz {{ license.properties.size }}
      span.tag.is-multiple(v-if="license.properties.class == 'multiple'") Mehr {{ license.properties.size }}
      span.tag.is-server(v-if="license.properties.class == 'server'") Serv {{ license.properties.size }}
      span.tag.is-server(v-if="license.properties.class == 'personal'") Pers {{ license.properties.size }}
      span.tag.is-server(v-if="license.properties.class == 'pro'") Pro {{ license.properties.size }}
      //- License delivery type
      span.tag: span.icon
        i.far.fa-compact-disc(v-if="license.properties.delivery == 'dvd'")
        i.far.fa-cloud-download(v-if="license.properties.delivery == 'download'")
</template>

<script>

import licenseMixin from '@/mixins/license'
export default {
  mixins: [licenseMixin],
  props: {
    license: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
  .identifier {
    display: flex;
    flex-wrap: nowrap;
    .image {
      margin-right: 1em;
      margin-bottom: 0;
    }
    .tags, .tags .tag {
      margin-bottom: 0;
      white-space: nowrap;
      flex-wrap: nowrap;
    }
  }
</style>
