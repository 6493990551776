<template>
  <b-field>
    <p
      class="control"
      :class="{'is-expanded': expanded }"
    >
      <input
        v-if="loginType === 'startcode'"
        ref="startcodeInput"
        class="input"
        type="text"
        icon="key"
        placeholder="Startcode"
        style="min-width: 150px"
        :disabled="disabled"
        @keydown="onKeydown"
      >
      <input
        v-if="loginType === 'holder'"
        ref="holderInput"
        :value="value"
        class="input"
        type="text"
        icon="key"
        placeholder="Name des Lizenznehmers"
        style="min-width: 150px"
        :disabled="disabled"
        @input="$emit('input', $event.target.value)"
        @keydown="onKeydown"
      >
    </p>
    <p class="control">
      <b-dropdown
        hoverable
        position="is-bottom-left"
        :disabled="disabled"
      >
        <button
          slot="trigger"
          class="button"
        >
          ?
        </button>
        <b-dropdown-item custom>
          <div
            v-if="loginType ==='holder'"
            class="help-item"
          >
            <img src="@/assets/images/login-help/license-full-image.png">
            <p>
              Den <strong> Namen des Lizenznehmers</strong> haben Sie bei der Aktivierung Ihrer Lizenz eingegeben. Sie finden ihn auch auf dem Lizenz-Dokument, das Sie per E-Mail erhalten haben.
            </p>
          </div>
          <div
            v-else
            class="help-item"
          >
            <img src="@/assets/images/login-help/license-startcode-image.png">
            <p>
              Den <strong>6-stelligen Startcode</strong> finden Sie auf Ihrem Lizenz-Start-Dokument, das Sie nach dem Erwerb von METACOM z.B. per E-Mail erhalten haben.
            </p>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </p>
  </b-field>
</template>

<script>

import Cleave from 'cleave.js'
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loginType: {
      type: String,
      default: null
    },
    focus: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      formattedValue: null,
      cleaveOptions: {
        delimiter: ' ',
        blocks: [3, 3],
        numericOnly: true
      },
      cleave: null,
      onValueChangedFn: null

    }
  },
  computed: {
  },
  watch: {
    /**
     * Watch for changes from parent component and notify cleave instance
     *
     * @param newValue
     */
    value (newValue) {
      /* istanbul ignore if */
      if (!this.cleave) return

      // when v-model is not masked (raw)
      if (this.raw && newValue === this.cleave.getRawValue()) return
      //  when v-model is masked (NOT raw)
      const input = this.$refs.startcodeInput
      if (!this.raw && newValue === input.value) return
      // Lastly set newValue
      this.cleave.setRawValue(newValue)
    },
    cleaveOptions: {
      deep: true,
      handler (newOptions) {
        this.cleave.destroy()
        this.cleave = new Cleave(this.$refs.startcodeInput, this.getOptions(newOptions))
        this.cleave.setRawValue(this.value)
      }
    },
    loginType (newValue) {
      if (newValue === 'startcode') {
        this.setupStartcodeFormatter()
      } else {
        this.destroyStartcodeFormatter()
      }
    }
  },
  mounted () {
    if (this.loginType === 'startcode') {
      this.setupStartcodeFormatter()
    }
    const input = this.loginType === 'startcode' ? this.$refs.startcodeInput : this.$refs.holderInput
    if (this.focus) {
      input.focus()
    }
  },
  /**
   * Free up memory
   */
  beforeDestroy () {
    this.destroyStartcodeFormatter()
  },
  methods: {
    setupStartcodeFormatter () {
      if (this.cleave) {
        return
      }
      if (this.value != null) {
        this.$refs.startcodeInput.setAttribute('value', this.value)
      }
      this.cleave = new Cleave(this.$refs.startcodeInput, this.getOptions(this.cleaveOptions))
    },
    destroyStartcodeFormatter () {
    /* istanbul ignore if */
      if (!this.cleave) return

      this.cleave.destroy()
      this.cleave = null
      this.onValueChangedFn = null
    },
    getOptions (options) {
      // Preserve original callback
      this.onValueChangedFn = options.onValueChanged

      return Object.assign({}, options, {
        onValueChanged: this.onValueChanged
      })
    },
    onValueChanged (event) {
      const value = this.raw ? event.target.rawValue : event.target.value
      this.$emit('input', value)

      // Call original callback method
      if (typeof this.onValueChangedFn === 'function') {
        this.onValueChangedFn(event)
      }
    },
    onKeydown (event) {
      if (event.keyCode === 13) {
        this.$emit('enter', event)
      }
    }
  }
}
</script>
