<template>
  <div class="field input-slider is-grouped">
    <div
      class="control"
      style="flex-grow: 1"
    >
      <b-slider
        :value="internalValue"
        :min="sliderMin"
        :max="sliderMax"
        size="is-medium"
        @change="onInternalValueChanged"
        @dragging="onInternalValueChanged"
      >
        <template v-for="(val, i) in steps">
          <b-slider-tick
            :key="val"
            :value="val"
          >
            {{ val }}{{ openEnded && i === steps.length - 1 ? '+' : '' }}
          </b-slider-tick>
        </template>
      </b-slider>
    </div>
    <div class="control numeric">
      <b-input
        ref="input"
        :value="internalStringValue"
        type="number"
        placeholder="0"
        style="max-width: 100px"
        @input.native="onInternalStringValueChanged"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Number,
      default: null
    },
    sliderMin: {
      type: Number,
      default: 1
    },
    sliderMax: {
      type: Number,
      default: 50
    },
    min: {
      type: Number,
      default: 1
    },
    max: {
      type: Number,
      default: 200
    },
    openEnded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      internalValue: Math.min(Math.max(this.value, this.sliderMin), this.sliderMax),
      internalStringValue: Math.min(Math.max(this.value, this.sliderMin), this.sliderMax).toString()
    }
  },
  computed: {
    steps () {
      const stepSize = this.sliderMax < 10 ? 1 : this.sliderMax < 50 ? 5 : this.sliderMax < 500 ? 10 : this.sliderMax < 1000 ? 50 : this.sliderMax < 2500 ? 100 : 500
      const steps = [...new Array(Math.floor(this.sliderMax / stepSize)).fill(0).map((v, i) => i * stepSize)]
      if (steps[0] < this.sliderMin) {
        steps[0] = this.sliderMin
      }
      if (steps[steps.length - 1] < this.sliderMax) {
        steps.push(this.sliderMax)
      }
      return steps
    }
  },
  watch: {
    value (v) {
      this.updateValue(v, false)
    }
  },
  methods: {
    onInternalValueChanged (v) {
      this.updateValue(v)
    },

    onInternalStringValueChanged (event) {
      const v = event.target.value
      const n = Number.parseInt(v)
      this.updateValue(n)
    },

    updateValue (v, emit = true) {
      if (isNaN(v)) {
        return
      }
      v = Math.max(this.min, Math.min(this.max, v))
      this.internalValue = v
      this.internalStringValue = v.toString()
      this.$refs.input.getElement().value = this.internalStringValue

      if (emit) {
        this.$emit('input', v)
      }
    }
  }

}
</script>

<style lang="scss" scoped>
  ::v-deep .b-slider.is-medium .b-slider-tick-label {
    top: 16px;
    font-size: 0.85rem;
  }

  .control.numeric {
    padding-left: 24px;
  }
</style>
