<template lang="pug">
  #license-single
    section.section
      .container.page
        nav.panel
          p.panel-heading Details der Lizenz {{ $route.params.id }}
          .panel-block
            b-loading(:active.sync="isLoading")

            backend-license-preview(
              v-if="license != null"

              :license="license"
              @update:license="licenseUpdated($event)"
            )
</template>

<script>
import LicenseAPI from '@/services/api/License'

import BackendLicensePreview from '@/components/admin/BackendLicensePreview'
export default {
  components: {
    BackendLicensePreview
  },
  data () {
    return {
      isLoading: false,
      license: null
    }
  },
  mounted () {
    this.isLoading = true
    LicenseAPI.getOneLicense(this.$route.params.id)
      .then((response) => {
        this.isLoading = false
        this.license = response.data.data.license
      })
  },
  methods: {
    licenseUpdated (newLicense) {
      this.license = newLicense
    }
  }
}
</script>

<style lang="scss" scoped>
  .panel-block {
    background: #fafafa;

    .license-preview {
      width: 100%;
    }
  }
</style>
