<template lang="pug">
  form(
    @submit.prevent="handleSubmit()"
  )
    p(v-if="!restrictedHolderTypes || restrictedHolderTypes.length > 1") Bitte wählen Sie zuerst aus, ob die Lizenz an eine Person oder ein Unternehmen gebunden werden soll.

    b-field(
      label="Art des Lizenznehmers"
      :type="errors.has('holderType') ? 'is-danger' : ''",
      :message="errors.first('holderType')"
      v-if="!restrictedHolderTypes || restrictedHolderTypes.length > 1"
    )
      b-radio-button(
        v-model="internalData.holderType"
        native-value="person"
        data-vv-as="Art des Lizenznehmers"
        name="holderType"
        v-validate="'required'"
        v-if="!restrictedHolderTypes || restrictedHolderTypes.includes('person')"
      )
        b-icon(icon="user")
        span Person

      b-radio-button(
        v-model="internalData.holderType"
        native-value="organization"
        data-vv-as="Art des Lizenznehmers"
        name="holderType"
        v-if="!restrictedHolderTypes || restrictedHolderTypes.includes('organization')"
      )
        b-icon(icon="building")
        span Unternehmen, Organisation, Einrichtung

    h4.subtitle.has-text-weight-bold.mt-4(v-else) Bindung an {{ restrictedHolderTypes[0] === 'person' ? 'eine natürliche Person' : 'ein Unternehmen' }}:

    .details(v-if="internalData.holderType != null")
      //- NAME
      .field(
      )
        label.label Name des Lizenznehmers
        p
            span(v-if="isPerson") Bitte geben Sie Ihren vollständigen Namen an.
            span(v-else) Die Lizenz wird an dieses Unternehmen, diese Organisation oder diese Einrichtung gebunden. Geben Sie nur den Namen der Einrichtung ohne Adresse ein und beachten Sie die Obergrenze von 54 Zeichen.
        p.has-text-info.mt-2.mb-2
          b-icon(icon="info-circle")
          span Sie müssen den Namen des Lizenznehmers in Zukunft exakt eingeben, wenn Sie sich mit Ihrer Lizenz anmelden möchten. Bitte achten Sie auf die Schreibweise.
        .control
          b-field(
            :type="errors.has('name') ? 'is-danger' : ''",
            :message="errors.first('name')"
          )
            b-input(
                :icon="isPerson ? 'user' : 'building'",
                :placeholder="isPerson ? 'Vor- und Nachname' : 'Vollständiger Name des Unternehmens bzw. der Einrichtung' "
                expanded, v-model="internalData.name"
                v-validate="'required|min:8|max:54'"
                name="name"
                maxlength="54"
                minlength="8"
                data-vv-as="Name des Lizenznehmers"
                has-counter
              )

      //- Contact
      .field(v-if="!isPerson")
        label.label Ansprechpartner
        p Bitte geben Sie einen Ansprechpartner an, der zur Verwaltung der Lizenz berechtigt ist (z.B. ein Administrator oder Verwaltungsmitarbeiter).
        b-field(
            :type="errors.has('contact') ? 'is-danger' : ''",
            :message="errors.first('contact')"
          )
          b-input(
              icon="user"
              placeholder="Vor- und Nachname Ansprechpartner"
              expanded,
              name="contact"

              data-vv-as="den Kontakt"
              v-model="internalData.contact"
              :type="errors.has('contact') ? 'is-danger' : ''"
            )

      //- Mail
      .field.mt-5
        label.label E-Mail Adresse
        p
            span(v-if="isPerson") An diese E-Mail Adresse wird Ihre gebundene Lizenz versandt.
            span(v-else) An diese E-Mail Adresse wird Ihre gebundene Lizenz versandt. Bitte geben Sie eine langfristig gültige E-Mail-Adresse, (z.B. ein Administrator oder Verwaltungsmitarbeiter).
        b-field(
            :type="errors.has('email') ? 'is-danger' : ''",
            :message="errors.first('email')"
          )
            b-input(
              icon="envelope",
              placeholder="Ihre E-Mail Adresse" ,
              expanded,
              v-model="internalData.email",
              v-validate="'required|email'",
              name="email",
              ref="email"
              data-vv-as="E-Mail Adresse",
            )
        b-field(
          v-if="holderData == null || internalData.email != holderData.email"
          :type="errors.has('email_confirmation') ? 'is-danger' : ''"
          :message="errors.first('email_confirmation')"
        )
          b-input(
            icon="envelope",
            placeholder="E-Mail Adresse wiederholen" ,
            expanded,
            v-model="emailConfirmation",
            v-validate="'required|confirmed:email'",
            name="email_confirmation",
            data-vv-as="Wiederholung der E-Mail Adresse",
            @paste.native="$event => $event.preventDefault()"
          )

      //- CONFIRM AUTHORIZATION
      b-field(
        label="Weitere erforderliche Angaben"
        :message="errors.first('authorized')",
        :type="errors.has('authorized') ? 'is-danger' : ''"
      )
        b-checkbox(
          v-validate="'required:true'",
          name="authorized",
          data-vv-as="Bestätigung der Autorisierung",
          v-model="authorized",
          :type="errors.has('authorized') ? 'is-danger' : ''"
        )  Ich bin Käufer dieser Lizenz oder anderweitig autorisiert diese Lizenz an mich zu binden

      //- PRIVACY POLICY
      b-field(
        :message="errors.first('privacy')",
        :type="errors.has('privacy') ? 'is-danger' : ''"
      )
        b-checkbox(
          v-validate="'required:true'",
          name="privacy",
          data-vv-as="Bestätigung der Datenschutzerklärung",
          v-model="privacy",
          :type="errors.has('privacy') ? 'is-danger' : ''"
        ) Ich habe die #[a(href="https://metacom.shop/datenschutzerklaerung", target="_blank") Datenschutzerklärung] gelesen und akzeptiere, wie meine angegebenen Daten auf dieser Seite verarbeitet werden.

      .field: p.control
        b-button(type="is-primary" native-type="submit" expanded :disabled="errors.any()" :loading="loading")
          span(v-if="mode === 'create'") Diese Lizenz beim Kauf an diese Angaben binden
          span(v-else-if="mode === 'edit'") Diese Daten zur Lizenzbindung verwenden
          span(v-else-if="mode === 'bind'") Diese Lizenz jetzt an diese Angaben binden

  </template>

<script>
export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  props: {
    holderData: {
      type: Object,
      default: null
    },
    mode: {
      type: String,
      default: 'create'
    },
    restrictedHolderTypes: {
      type: Array,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: () => {
    return {
      emailConfirmation: '',
      authorized: '',
      privacy: '',

      internalData: {
        holderType: null,
        name: null,
        email: null,
        contact: null
      }
    }
  },
  computed: {
    isPerson () {
      return this.internalData?.holderType === 'person'
    }
  },
  watch: {
    holderData () {
      this.updateInternalData()
    },
    async 'internalData.holderType' () {
      if (this.mode === 'edit') {
        await this.$nextTick()
        await this.$validator.validateAll()
      }
    }
  },
  mounted () {
    this.updateInternalData()
  },
  methods: {
    updateInternalData () {
      if (this.holderData?.holderType) {
        this.internalData.holderType = this.holderData.holderType
      }

      if (this.restrictedHolderTypes && this.restrictedHolderTypes.length === 1) {
        this.internalData.holderType = this.restrictedHolderTypes[0]
      } else if (this.internalData.holderType == null) {
        this.internalData.holderType = this.holderData?.contact != null
          ? 'organization'
          : null
      }

      this.internalData.name = this.holderData?.name
      this.internalData.email = this.holderData?.email
      this.internalData.contact = this.holderData?.contact
    },
    handleSubmit () {
      if (this.loading) {
        return
      }
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('submit', this.internalData)
        }
      })
    }
  }
}
</script>
