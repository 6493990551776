<template lang="pug">
  .small-license-card(:class="{ 'is-crossed': crossed }")

    figure.image.is-64x64.has-text-centered
      img(:src="getLicenseImageOfLicense(license)")

    .content
      .level
        .level-left
          h6
            span {{ getShortFormattedNameOfLicense(license) + ' ' }}
            span(style="white-space: nowrap;" v-if="license.key") (#[code {{ getFormattedKeyOfLicense(license) }}])
        .level-right
          b-button(size="is-small" icon-left="times" v-if="removable" @click="$emit('remove', license)")

      p(v-if="multiple === false") {{ getShortPropertiesText(license)}}
      p(v-else) {{ getShortPropertiesTextForMultipleLicenses(license) }}

      p(v-if="bindingState && license.status === 'unbound'") Diese Lizenz ist noch ungebunden. Sie wird beim Update an einen Lizenznehmer gebunden.
      p(v-else-if="bindingState && license.status === 'bound'") Diese Lizenz ist gebunden an „#[em {{ license.holder.name }}]”
    svg.cross(v-if="crossed" xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100')
      path(d='M100 0 L0 100 ' stroke='black' opacity="0.2" stroke-width='2')
      path(d='M0 0 L100 100 ' stroke='black' opacity="0.2" stroke-width='2')

    //-  TODO: Gebunden / Ungebunden

  </template>

<script>
import license from '@/mixins/license'

export default {
  name: 'SmallLicenseCard',
  mixins: [license],
  props: {
    license: {
      type: Object,
      required: true
    },
    removable: {
      type: Boolean,
      default: false
    },
    bindingState: {
      type: Boolean,
      default: true
    },
    crossed: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    multiple () {
      return this.license?.properties.class === 'personal' && this.license?.properties.size > 1
    }
  }
}
</script>

  <style lang="scss" scoped>
    @import '@/assets/scss/bulma-variables.scss';
    .small-license-card {
      display: flex;
      padding: 4px;
      position: relative;

      &.is-crossed {
        opacity: 0.7;
      }

      svg.cross {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
      }

      .level-left {
        max-width: 100%;
      }
      .content {
        flex-grow: 1;
        padding-left: 16px;
        margin-bottom: 0;
        h6, p, .level {
          margin-bottom: 0;
        }
      }
    }
  </style>
