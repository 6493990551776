var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"offer-load-form"},[_c('error-message',{attrs:{"error":_vm.loadOfferError,"title":"Fehler beim Laden des Angebots","message-by-status":{
      403: 'Es konnte kein Angebot zu diesem Angebotscode gefunden werden. Bitte überprüfen Sie Ihre Eingabe und achten Sie auf die Groß- und Kleinschreibung.',
      410: 'Das zu diesem Angebotscode gefundene Angebot ist bereits abgelaufen. Sie könenn den Lizenz-Assistenten erneut durchlaufen, um ein neues Angebot zu erhalten oder die Bestellung direkt aufzugeben.'
    },"allow-retry":false}}),_c('b-field',{staticClass:"mt-5",attrs:{"grouped":""}},[_c('b-input',{directives:[{name:"cleave",rawName:"v-cleave",value:({
        delimiter: ' ',
        blocks: [3, 3, 3],
        uppercase: false
      }),expression:"{\n        delimiter: ' ',\n        blocks: [3, 3, 3],\n        uppercase: false\n      }"}],attrs:{"placeholder":"Angebotscode"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.load.apply(null, arguments)}},model:{value:(_vm.token),callback:function ($$v) {_vm.token=$$v},expression:"token"}}),_c('b-button',{attrs:{"type":"is-primary","loading":_vm.isLoadingOffer},on:{"click":_vm.load}},[_vm._v(" Angebot laden ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }