<template lang="pug">
  widget-panel(title="Notizen", icon="fa-comment")
    template(slot="actions")
    template(slot="content")
      table.table
        tbody
          tr
            td(v-if="!editing") {{ notes ? notes : 'Keine Notiz'}}
            td(v-if="editing")
              .field
                .control
                  textarea.textarea(
                    v-bind:value="notes"
                    v-on:input="notesChanged($event)"
                    rows="5"
                  )

</template>

<script>
import licenseMixin from '@/mixins/license'
import WidgetPanel from '@/components/general/WidgetPanel'

export default {
  components: { WidgetPanel },

  mixins: [licenseMixin],
  props: {
    notes: {
      type: String,
      default: undefined
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    notesChanged (event) {
      this.$emit('update:notes', event.target.value)
    }
  }
}
</script>
