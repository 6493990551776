<template lang="pug">
  span.tag
    span.icon:  i.far.fa-clock
    span {{ date | moment("HH:mm:ss") }}
</template>

<script>
export default {
  props: {
    date: {
      type: String,
      required: true
    }
  }
}
</script>
