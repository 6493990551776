<template lang="pug">
  .modal-card
    .modal-card-head
      .modal-card-title MetaSearch herunterladen
      button.delete(aria-label="close", @click="$parent.close()")
    section.modal-card-body
      .content
        :markdown-it(breaks="true")
          Hier können Sie die neueste Version von MetaSearch für alle unterstützten Plattformen einzeln herunterladen.
          MetaSearch beinhaltet eine Schlagwortsuche und eine Bearbeitungsfunktion für Symbole.

          **Für die Benutzung von MetaSearch benötigen Sie den *METACOM_Symbole* Ordner von der METACOM-DVD oder aus dem METACOM-Download.**
      .columns
        .column.is-one-quarter-widescreen.is-half-desktop(v-for="product of availableProducts" :key="product.key")
          //- Windows Download
          .card.download-item
            .card-content.has-text-centered
              .content
                figure.image.is-48x48
                  img(src="@/assets/images/metasearch/metasearch_icon_notext.svg")
                  i.fab(:class="product.icon")
                h4 {{ product.title }}
                div: strong {{ product.subtitle }}
                div {{ product.meta }}
              button.button.is-primary(
                @click="getDownloadURLForPlatform(product.key)"
                v-if="urls[product.key] == null"
                :disabled="!latestVersion"
                :class="{'is-loading': isLoading[product.key]}"
              )
                span.icon.is-small: i.far.fa-link
                span Link erzeugen
              //- Once the download link is requested
              transition(
                enter-active-class="animated bounce"
              )
                a.button.is-primary(
                  :href="urls[product.key]"
                  target="_blank"
                  v-if="urls[product.key]"
                )
                  span.icon.is-small: i.far.fa-cloud-download
                  span.text Herunterladen

      p.has-text-grey.has-text Neueste Version: {{latestVersion}}
      //- Loading indicator for latest version fetch
      b-loading(
        :active="isLoadingLatestVersion",
        :is-full-page="false"
      )
    footer.modal-card-foot
</template>

<script>
import MetaSearchAPI from '@/services/api/MetaSearch'
import ErrorMessage from '@/components/general/ErrorMessage'
import { mapState } from 'vuex'
import Vue from 'vue'
export default {
  components: { ErrorMessage },
  data () {
    return {
      isLoadingLatestVersion: false,
      requestError: null,
      latestVersion: null,

      isLoading: {},
      urls: {},
      products: [
        {
          icon: 'fa-windows',
          key: 'windows',
          title: 'Windows',
          subtitle: 'Installer für Windows',
          meta: '32-Bit / 64-Bit',
          restrictedToVersion: ['8']
        },
        {
          icon: 'fa-windows',
          key: 'windows',
          title: 'Windows',
          subtitle: 'Installer für Windows',
          meta: '32-Bit / 64-Bit',
          restrictedToVersion: ['9'],
          restrictedToClass: ['personal']
        },
        {
          icon: 'fa-windows',
          key: 'windows-pro',
          title: 'Windows',
          subtitle: 'Pro-Installer',
          meta: '32-Bit / 64-Bit',
          restrictedToVersion: ['9'],
          restrictedToClass: ['pro']
        },
        {
          icon: 'fa-apple',
          key: 'mac',
          title: 'macOS',
          subtitle: 'App',
          meta: '64-Bit'
        },
        {
          icon: 'fa-linux',
          key: 'linux-ia32',
          title: 'Linux',
          subtitle: 'AppImage',
          meta: '32-Bit',
          restrictedToVersion: ['8']
        },
        {
          icon: 'fa-linux',
          key: 'linux',
          title: 'Linux',
          subtitle: 'AppImage',
          meta: '64-Bit',
          restrictedToVersion: ['8']
        },
        {
          icon: 'fa-linux',
          key: 'linux-deb',
          title: 'Linux',
          subtitle: 'DEB-Paket',
          meta: '64-Bit',
          restrictedToVersion: ['9']
        },
        {
          icon: 'fa-linux',
          key: 'linux-rpm',
          title: 'Linux',
          subtitle: 'RPM-Paket',
          meta: '64-Bit',
          restrictedToVersion: ['9']
        }
      ]
    }
  },
  computed: {
    ...mapState('auth', { license: 'userData' }),
    availableProducts () {
      return this.products.filter(p => {
        if (p.restrictedToVersion && !p.restrictedToVersion.includes(this.license.version)) {
          return false
        }
        if (p.restrictedToClass && !p.restrictedToClass.includes(this.license.properties.class)) {
          return false
        }
        return true
      })
    }
  },
  mounted () {
    this.getLatestVersion()
  },
  methods: {
    /**
     * Retrieve the latest metasearch version
     */
    async getLatestVersion () {
      this.isLoadingLatestVersion = true
      try {
        const { version } = (await MetaSearchAPI.getLatestVersion()).data.data
        this.isLoadingLatestVersion = false
        this.requestError = null
        this.latestVersion = version
      } catch (error) {
        this.isLoadingLatestVersion = false
        this.requestError = error
      }
    },

    /**
     * Set the isLoading state for a certain platform
      * @param {'windows'|'mac'|'linux'|'linux-ia32'} platform - platform
     * @param {Boolean} isLoading - isLoading state
     */
    setIsLoading (platform, isLoading) {
      Vue.set(this.isLoading, platform, isLoading)
    },

    /**
     * Set the download url for the platform
     */
    setDownloadURLForPlatform (platform, url) {
      Vue.set(this.urls, platform, url)
    },
    /**
     * Fetch the download link and download the installer
      * @param {'windows'|'mac'|'linux'|'linux-ia32'} platform - platform
     */
    async getDownloadURLForPlatform (platform) {
      this.setIsLoading(platform, true)

      try {
        const { url } = (await MetaSearchAPI.getDownloadLinkForPlatform(platform)).data.data
        this.setIsLoading(platform, false)
        this.setDownloadURLForPlatform(platform, url)
      } catch (error) {
        console.error(error)
        this.setIsLoading(platform, false)
        this.$buefy.toast.open({
          message: `Download Link konnte nicht abgerufen werden: ${error}`,
          duration: 5000,
          type: 'is-danger'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-card {
    width: 100%;
  }
  .card .content figure.image {
    margin: 0 auto;
    margin-bottom: 1em;
    position: relative;
    i {
      position: absolute;
      right: -40%;
      top: 60%;
      font-size: 1.5em;
    }
  }
</style>
