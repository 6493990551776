<template lang="pug">
  section.section.section-dark
      .hero-body
        .container
          .columns.is-centered
            .column.is-8
              .content
                div(v-if="license.properties.delivery === 'download'")
                  h1.title Und jetzt?
                  p Vielen Dank für Ihre Registrierung, mit der Sie die Lizenz an einen Lizenznehmer gebunden haben.
                  p(v-if="isFreshlyBound") Wir haben Ihnen gerade eine Mail mit dem aktualisierten Lizenz-Dokument (PDF) gesendet. Bitte bewahren Sie dieses Dokument sicher auf! Wenn Sie die Mail nicht erhalten haben, sehen Sie in Ihrem Spam-Ordner nach.
                  p Sie können jetzt {{ getMetacomVersionName(license.version) }} herunterladen.
                  router-link(to="/download").button.is-primary Zum Download-Bereich
                div(v-if="license.properties.delivery === 'dvd'")
                  h1.title Vielen Dank für die Registrierung Ihrer Lizenz!
                  p Durch die Registrierung haben Sie die Lizenz an einen Lizenznehmer gebunden.
                  p(v-if="isFreshlyBound") Wir haben Ihnen gerade eine Mail mit dem aktualisierten Lizenz-Dokument (PDF) gesendet. Bitte bewahren Sie dieses Dokument sicher auf! Wenn Sie die Mail nicht erhalten haben, sehen Sie in Ihrem Spam-Ordner nach.
                  p Die DVD-Lizenz beinhaltet keine Downloadberechtigung, ermöglicht Ihnen aber einfachere Updates auf zukünftige METACOM Versionen.

                div(v-if="license.properties.delivery !== 'download' && showDownloadAreaHint()")
                  br
                  p Mit Ihrer Lizenz haben Sie Zugriff auf einige Downloads, die Sie im Bereich „METACOM herunterladen” finden:
                  ul
                    li(v-for="permission in downloadAreaPermissions()") {{permission}}
                  router-link(to="/download").button.is-primary Zum Download-Bereich

</template>

<script>
import license from '@/mixins/license'

export default {
  mixins: [
    license
  ],
  props: {
    license: {
      type: Object,
      required: true
    },
    isFreshlyBound: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    showDownloadAreaHint () {
      return (
        (
          this.license.properties.delivery &&
          this.license.properties.delivery !== 'download' &&
          license.methods.canDownloadMetacom(this.license) // Not a normal download license
        )
      ) ||
        license.methods.canDownloadMetasearchOneClickInstaller(this.license) ||
        license.methods.canDownloadMetasearchMultistepInstaller(this.license) ||
        license.methods.canShowMetasearchServerDocumentation(this.license)
    },
    downloadAreaPermissions () {
      const permissions = []
      if (license.methods.canDownloadMetacom(this.license)) {
        permissions.push('METACOM ')
      }
      if (license.methods.canDownloadMetasearchOneClickInstaller(this.license)) {
        permissions.push('MetaSearch')
      }
      if (license.methods.canDownloadMetasearchMultistepInstaller(this.license)) {
        if (this.license.version === '8') {
          permissions.push('MetaSearch Multistep Installer')
        }
        permissions.push('MetaSearch Pro-Installer')
      }
      if (license.methods.canShowMetasearchServerDocumentation(this.license)) {
        permissions.push('Hinweise für Serverinstallationen')
      }
      return permissions
    }
  }
}
</script>
