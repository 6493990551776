import { render, staticRenderFns } from "./CartSummary.vue?vue&type=template&id=1d15c6a1&scoped=true"
import script from "./CartSummary.vue?vue&type=script&lang=js"
export * from "./CartSummary.vue?vue&type=script&lang=js"
import style0 from "./CartSummary.vue?vue&type=style&index=0&id=1d15c6a1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d15c6a1",
  null
  
)

export default component.exports