<template lang="pug">
  div.delivery-details
    .buttons
      button.button(
        :class="{'is-loading': isActionLoading}"
        @click="repeatDelivery()"
      ) Erneut versenden

    error-message(
      :error="requestError"
      :allow-retry="false"
    )
    b-tabs
      //- PROPERTIES TAB
      b-tab-item(label="Eigenschaften")
        .columns
          .column
            //- Properties
            widget-panel(
              title="Eigenschaften"
              icon="fa-tasks"
            )
              template(slot="content")
                table.table
                  tbody
                    tr
                      th(data-label="Bestellung") ID
                      td {{delivery._id}}
                    tr
                      th Status
                      td: progress-tag(:progress="delivery.progress")
                    tr
                      th Dokumententyp
                      td {{ getFormattedDeliveryDocumentType(delivery.documentType) }}
                    tr
                      th Anlass
                      td {{ getFormattedDeliveryOccasion(delivery.occasion) }}
                    tr
                      th Empfänger
                      td {{ delivery.recipient }}
                    tr
                      th Empfänger Name
                      td {{ delivery.recipientName }}
          .column
            //- Licenses
            widget-panel(
              title="Versendete Lizenzen"
              icon="fa-file"
              :has-content="delivery.licenses && delivery.licenses.length !== 0"
              empty-state-message="Keine Lizenzen"
            )
              template(slot="content")
                .license-list
                  .license(
                    v-for="license in delivery.licenses"
                    :class="{'is-active': currentLicenseId === license._id }"
                  )
                    license-identifier(:license="license")
                    router-link.is-pulled-right(
                      :to="'/backend/license/' + license._id"
                      target="_blank"
                      title="In neuem Tab öffnen (Shift-Klick für neues Fenster)"
                    ): span.icon: i.fas.fa-external-link
        //- Note
        widget-panel(
          title="Notiz"
          icon="fa-comment"
          :has-content="delivery.note != null && delivery.note.length > 0"
          empty-state-message="Keine Notiz"
        )
          template(slot="content")
            table.table: tbody: tr: td {{ delivery.note}}

        //- E-Mail content
        widget-panel(title="Nachricht", icon="fa-envelope", v-if="delivery.content || delivery.subject")
          template(slot="content")
            .content
              h4 Betreff: {{ delivery.subject }}
              p(v-html="delivery.content")

        //- Error message
        widget-panel(title="Fehler", icon="fa-exclamation-triangle", v-if="delivery.error")
          template(slot="content")
            table.table: tbody: tr: td {{ delivery.error }}

        //- Documents
        widget-panel(
          title="Versendete Dokumente"
          icon="fa-file"
          :has-content="delivery.documents && delivery.documents.length !== 0"
          empty-state-message="Keine Dokumente"
        )
          template(slot="content")
            .document-list
              .document(
                v-for="documentKey in delivery.documents"
              )
                span {{ documentKey }}
                button.button.is-primary.is-small(
                  :class="{'is-loading': isDownloadLinkLoading }"
                  @click="downloadDocument(documentKey)"
                ) Herunterladen
      //- JSON TAB
      b-tab-item(label="JSON")
        vue-json-pretty(:data="delivery")
</template>

<script>
import DeliveryMixin from '@/mixins/delivery'
import DocumentAPI from '@/services/api/Document'
import DeliveryAPI from '@/services/api/Delivery'

import ErrorMessage from '@/components/general/ErrorMessage'
import WidgetPanel from '@/components/general/WidgetPanel'
import LicenseIdentifier from '@/components/admin/license/LicenseIdentifier'
import OrderTag from '@/components/general/OrderTag'
import ProgressTag from '@/components/general/ProgressTag'

import VueJsonPretty from 'vue-json-pretty'

export default {
  components: { ErrorMessage, WidgetPanel, LicenseIdentifier, OrderTag, ProgressTag, VueJsonPretty },
  mixins: [DeliveryMixin],
  props: {
    delivery: {
      type: Object,
      default: null
    },
    currentLicenseId: {
      type: String,
      default: null
    }
  },
  data: function () {
    return {
      isActionLoading: false,
      isDownloadLinkLoading: false,
      requestError: null
    }
  },
  methods: {
    /**
     * Download a delivery document
     */
    downloadDocument: async function (documentKey) {
      this.isDownloadLinkLoading = true
      try {
        const url = (await DocumentAPI.postDocumentURL(documentKey)).data.data.url
        window.open(url)
        this.requestError = null
        this.isDownloadLinkLoading = false
      } catch (error) {
        console.error(error)
        this.requestError = error
        this.isDownloadLinkLoading = false
      }
    },
    /**
     * Repeat the delivery
     */
    repeatDelivery: async function () {
      this.isActionLoading = true
      try {
        const { delivery } = (await DeliveryAPI.repeatByID(this.delivery._id)).data.data
        this.$emit('delivery-added', delivery)
        this.isActionLoading = false
        this.requestError = null
        this.$buefy.toast.open({
          message: 'Erneut gesendet',
          type: 'is-success'
        })
      } catch (error) {
        console.error(error)
        this.requestError = error
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/bulma-variables.scss';
  .license-list, .document-list {
    padding: 1rem;

    .license, .document {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.5em;
      padding: 0.2em;
      border-radius: 3px;

      .identifier {
        display: inline-flex;
      }
      &.is-active {
        background-color: $medium;
      }

    }
  }
</style>
