<template>
  <div class="simple-login">
    <error-message
      :error="authError"
      title="Fehler beim Anmelden"
      :allow-retry="false"
      :message-by-status="{
        401: loginTypeForStatus === 'startcode' ? 'Der angegebene Startcode ist ungültig. Bitte überprüfen Sie Ihre Angabe!' : 'Der angegebene Lizenznehmer-Name ist nicht korrekt. Vergleichen Sie Ihre Eingabe mit dem Lizenzdokument. Die exakte Schreibweise ist wichtig. Über den Button „Ich habe mein Lizenzdokument verloren” können Sie sich das Lizenzdokument noch einmal zusenden lassen.',
        423: 'Diese Lizenz ist bereits gebunden. Bitte verwenden Sie anstatt des Startcodes den Namen des Lizenznehmers.',
      }"
    />
    <error-message
      :error="loadStatusError"
      title="Fehler beim Anmelden mit Lizenzschlüssel"
      :allow-retry="false"
      :message-by-status="{
        404: 'Bitte überprüfen Sie Ihren Lizenzschlüssel',
        403: 'Bei dieser Lizenz kann sich nicht angemeldet werden, da sie deaktiviert wurde. Wahrscheinlich wurde bereits ein Update auf eine neuere METACOM Version für diese Lizenz durchgeführt. Sie haben in diesem Fall Zugangsdaten zu einer neuen Lizenz mit einem abweichenden Lizenzschlüssel erhalten.',
      }"
    />
    <b-field
      label="Ihr Lizenzschlüssel"
    />
    <b-field>
      <license-key-input
        v-model="key"
        :disabled="isLoadingStatus"
        expanded
        @enter="retrieveLicenseStatus"
      />
    </b-field>
    <b-field>
      <license-authentication-code-input
        v-if="hasValidKey && hasValidStatus"
        v-model="authenticationCode"
        focus
        expanded
        :login-type="loginTypeForStatus"
        :disabled="isLoadingAuth"
        @enter="onSubmitButtonPressed"
      />
    </b-field>
    <b-field>
      <b-button
        type="is-primary"
        icon-left="arrow-right"
        :loading="isLoadingStatus || isLoadingAuth"
        :disabled="!hasValidKey || hasValidStatus && !hasAuthenticationCode"
        expanded

        @click="onSubmitButtonPressed"
      >
        {{ currentStep === 'key' ? 'Weiter' : 'Anmelden' }}
      </b-button>
    </b-field>
  </div>
</template>
<script>
import LicenseKeyInput from '@/components/customer/LicenseKeyInput.vue'
import LicenseAuthenticationCodeInput from '@/components/customer/LicenseAuthenticationCodeInput.vue'
import AuthService from '@/services/api/Auth.js'
import ErrorMessage from '@/components/general/ErrorMessage.vue'

export default {
  components: {
    LicenseKeyInput,
    LicenseAuthenticationCodeInput,
    ErrorMessage
  },
  data () {
    return {
      key: null,
      authenticationCode: null,
      status: null,

      isLoadingStatus: false,
      loadStatusError: null,
      isLoadingAuth: false,
      authError: null
    }
  },
  computed: {
    strippedKey () {
      const strippedKey = this.key?.replace(/[^A-Za-z0-9]/g, '')
      return strippedKey
    },
    strippedAuthenticationCode () {
      if (this.loginTypeForStatus === 'holder') {
        return this.authenticationCode
      } else {
        return this.authenticationCode.replace(' ', '')
      }
    },
    hasValidKey () {
      return this.strippedKey != null && this.strippedKey.length === 12
    },
    hasValidStatus () {
      return this.status === 'unbound' || this.status === 'bound'
    },
    loginTypeForStatus () {
      switch (this.status) {
        case 'unbound': return 'startcode'
        case 'bound': return 'holder'
        default: return null
      }
    },
    hasAuthenticationCode () {
      return this.authenticationCode?.length > 0 ?? false
    },
    currentStep () {
      if (this.hasValidStatus) {
        return 'authenticationCode'
      }

      return 'key'
    }
  },
  watch: {
    key () {
      this.reset()
    }
  },
  /**
   * Add direct login url support
   */
  created () {
  },
  methods: {
    reset () {
      this.status = null
      this.authenticationCode = null
    },
    async onSubmitButtonPressed () {
      if (this.status == null && this.hasValidKey) {
        return this.retrieveLicenseStatus()
      } else if (this.hasValidStatus && this.hasAuthenticationCode) {
        return this.login()
      }
    },
    async retrieveLicenseStatus () {
      if (!this.hasValidKey) {
        return
      }

      this.isLoadingStatus = true
      this.loadStatusError = null
      try {
        const response = await AuthService.getLicenseStatusByKey(this.strippedKey)
        this.status = response.data.data.status

        this.isLoadingStatus = false
      } catch (error) {
        this.isLoadingStatus = false
        this.loadStatusError = error
        console.error(error)
      }
    },
    async login () {
      if (!this.hasValidKey || !this.hasValidStatus || !this.hasAuthenticationCode) {
        return
      }

      this.isLoadingAuth = true
      this.authError = null
      try {
        await this.$store.dispatch('auth/login', {
          type: 'license',
          data: {
            key: this.strippedKey,
            authenticationCode: this.strippedAuthenticationCode
          }
        })

        this.$emit('authenticated')

        this.isLoadingAuth = false
      } catch (error) {
        this.isLoadingAuth = false
        this.authError = error
        console.error(error)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
form {
  padding-top: 40px;
  padding-bottom: 40px;

  .field {
    margin-bottom: 0;
  }

  button.submit {
    margin-top: 1em;
  }
  p.info {
    padding-top: 40px;
    padding-bottom: 20px;
  }
}
.hero {
  background: $primary;
}
.help-item {
  width: 350px;
  clear:both;
  overflow: hidden;
  p {
    padding-top: 10px;
  }

  img {
    width: 180px;
    float: left;
  }
}
.license-help-links {
  margin-top: 1em;
}
</style>
