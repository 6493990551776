<template lang="pug">
#license-box
  .box.is-white
    .hero
      .level
        .level-left
          .level-item
            figure.image.is-128x128.has-text-centered
              img(:src="getLicenseImageOfLicense(license)")

          .level-item
            .content
              h4 {{ getShortFormattedNameOfLicense(license) }}
              p {{ getFormattedTypeOfLicense(license)}}, {{ getFormattedDeliveryOfLicense(license) }}
              license-help-links(:license="license")

    .content

      table.table.is-bordered
        tbody
          tr.row-title: th(colspan="2") Eigenschaften
          tr
            th Typ
            td {{ getFormattedTypeOfLicense(license) }}
          tr
            th Art
            td {{ getFormattedClassOfLicense(license) }}
          tr
            th Bezugsart
            td {{ getFormattedDeliveryOfLicense(license) }}
          tr
            th Größe
            td: code {{ license.properties.size }}
          tr(v-if="license.version !== '8'")
            th DVDs
            td: code {{ license.properties.dvdCount }}

          tr
            th Lizenzschlüssel
            td: code {{ getFormattedKeyOfLicense(license) }}

          tr(v-if="license.status === 'unbound'")
            th Startcode
            td: code {{ getFormattedAuthenticationCodeOfLicense(license) }}

          tr.row-title: th(colspan="2") Lizenznehmer
          tr(v-if="license.status === 'unbound'")
            td(colspan="2") Lizenz wurde noch nicht gebunden

          tr(v-if="license.version !== '8'")
            th Lizenznehmer-Art
            td: code {{ getFormattedHolderType(license) }}
          tr(v-if="license.status === 'bound'")
            th Name
            td: code {{ license.holder.name }}
          tr(v-if="license.status === 'bound'")
            th E-Mail Adresse
            td: code {{ license.holder.email }}
          tr(v-if="license.status === 'bound' && license.holder.contact")
            th Kontakt
            td: code {{ license.holder.contact }}

</template>

<script>
import license from '@/mixins/license'
import LicenseHelpLinks from '@/components/customer/LicenseHelpLinks'

export default {
  name: 'LicenseBox',
  components: { LicenseHelpLinks },
  mixins: [license],
  props: {
    license: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/bulma-variables.scss';
  .box {
    padding: 0;
    overflow: hidden;

    .hero {
      padding: 20px;
    }
    .content {
      padding: 20px;
    }
  }
  table tr.row-title {
    background-color: $light;
    th {
      font-size: 0.8em;
      color: $dark;
      line-height: 1.2em;
      font-weight: bold;
    }
  }
  .license-header {
    figure {
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 1em;
    }
    .title {
      //color: $white;
      margin-bottom: 0;
    }
  }
</style>
