<template lang="pug">
  div
    div.content
      p Wenn diese Lizenz auf Anfrage des METACOM-Shops erzeugt wurde, tauchen die Infos der Generierungs-Anfrage hier auf.
    //- Error message
    article.message.is-warning(v-if="fetchDataError")
      .message-header: p Fehler beim Abrufen
      .message-body
        strong Status {{ fetchDataError.status }}
        p Fehler beim Abrufen der Generierungen: {{fetchDataError.response.message}}
        pre {{ fetchDataError.response }}
        button.button(v-bind:class="{'is-loading': isLoading}", @click="fetchData()") Erneut versuchen

    //- Empty state
    article.message(v-if="orderGenerations === undefined || orderGenerations.length === 0")
      .message-header: p Keine Generierung
      .message-body: p Keine Einträge gefunden. Diese Lizenz wurde nicht durch eine Shop-Bestellung generiert.

    //- Default state
    section.section(v-for="orderGeneration in orderGenerations")
      h4 Generierung {{ orderGeneration._id }}
      order-generation-details(:order-generation="orderGeneration", :current-license-id="license._id")
</template>

<script>
import OrderGenerationDetails from '@/components/admin/order-generation/OrderGenerationDetails'

import LicenseAPI from '@/services/api/License'

export default {
  components: {
    OrderGenerationDetails
  },
  props: {
    license: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      fetchDataError: null,
      orderGenerations: []
    }
  },
  created () {
    this.fetchData()
  },

  methods: {
    fetchData: async function () {
      this.isLoading = true
      try {
        this.isLoading = false
        this.orderGenerations = (await LicenseAPI.getOrderGenerationsForLicense(this.license._id)).data.data.orderGenerations
      } catch (error) {
        this.isLoading = false
        this.fetchDataError = {
          status: error.response.status,
          response: error.response.data
        }
      }
    }
  }
}
</script>
