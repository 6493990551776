import axios from 'axios'

export default {
  /**
   * Get the signed download url
   * @param {*} documentKey - key of the document to downnload
   */
  postDocumentURL: async function (documentKey) {
    return axios.post('/api/v1/document/url', { documentKey })
  }
}
