<template>
  <div class="field has-addons">
    <p
      class="control"
      :class="{'is-expanded': expanded }"
    >
      <input
        ref="input"
        class="input"
        type="text"
        icon="key"
        placeholder="Lizenzschlüssel"
        name="licenseKey"
        style="min-width: 200px"
        :disabled="disabled"
        @keydown="onKeydown"
      >
    </p>
    <p class="control">
      <b-dropdown
        hoverable
        position="is-bottom-left"
        :disabled="disabled"
      >
        <button
          slot="trigger"
          class="button"
        >
          ?
        </button>
        <b-dropdown-item custom>
          <div class="help-item">
            <img src="@/assets/images/login-help/license-startcode-image.png">
            <p>
              Den <strong> 12-stelligen Lizenzschlüssel</strong> finden Sie auf Ihrem Lizenz-Dokument, das Sie nach dem Erwerb von METACOM oder nach der Bindung der Lizenz per E-Mail erhalten haben.
            </p>
          </div>
        </b-dropdown-item>
      </b-dropdown>
    </p>
  </div>
</template>

<script>

import Cleave from 'cleave.js'
export default {
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    expanded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      formattedValue: null,
      cleaveOptions: {
        delimiter: '-',
        blocks: [4, 4, 4],
        uppercase: true
      },
      cleave: null,
      onValueChangedFn: null

    }
  },

  watch: {
    /**
     * Watch for changes from parent component and notify cleave instance
     *
     * @param newValue
     */
    value (newValue) {
      /* istanbul ignore if */
      if (!this.cleave) return

      // when v-model is not masked (raw)
      if (this.raw && newValue === this.cleave.getRawValue()) return
      //  when v-model is masked (NOT raw)
      const input = this.$refs.input // .getElement()
      if (!this.raw && newValue === input.value) return
      // Lastly set newValue
      this.cleave.setRawValue(newValue)
    }
  },
  mounted () {
    if (this.cleave) {
      return
    }
    const input = this.$refs.input // .getElement()
    if (this.value != null) {
      input.setAttribute('value', this.value)
    }
    this.cleave = new Cleave(input, this.getOptions(this.cleaveOptions))
  },
  /**
   * Free up memory
   */
  beforeDestroy () {
    /* istanbul ignore if */
    if (!this.cleave) return

    this.cleave.destroy()
    this.cleave = null
    this.onValueChangedFn = null
  },
  methods: {
    getOptions (options) {
      // Preserve original callback
      this.onValueChangedFn = options.onValueChanged

      return Object.assign({}, options, {
        onValueChanged: this.onValueChanged
      })
    },
    onValueChanged (event) {
      const value = this.raw ? event.target.rawValue : event.target.value
      this.$emit('input', value)

      // Call original callback method
      if (typeof this.onValueChangedFn === 'function') {
        // this.onValueChangedFn.call(this, event)
        this.onValueChangedFn(event)
      }
    },
    onKeydown (event) {
      if (event.keyCode === 13) {
        this.$emit('enter', event)
      }
    }
  }
}
</script>
