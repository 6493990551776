<template lang="pug">
  a.tag.is-link(:href="shopURL", target="_blank")
    span.icon:  i.far.fa-shopping-cart
    span \#{{ orderNumber }}

</template>

<script>
export default {
  props: {
    orderNumber: {
      type: String,
      required: true
    }
  },
  computed: {
    shopURL () {
      return `${process.env.VUE_APP_SHOP_URL}/wp-admin/post.php?post=${this.orderNumber}&action=edit`
    }
  }

}
</script>
