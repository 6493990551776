<template>
  <div class="update-license-binding">
    <error-message :error="patchError" />

    <!-- Single already bound licenses -->
    <p v-if="authenticatedLicenses.length === 1 && authenticatedLicenses[0].license.status === 'bound'">
      Sie haben nun die Möglichkeit, die Angaben des Lizenznehmers zu korrigieren.
    </p>

    <!-- Single or multiple not yet bound licenses -->
    <p v-else-if="authenticatedLicenses.length === 1 && authenticatedLicenses[0].license.status === 'unbound'">
      Während des Update-Prozesses wird diese Lizenz an einen Lizenznehmer gebunden.
    </p>

    <!-- Multiple bound or unbound licenses -->
    <p v-else-if="authenticatedLicenses.length > 1">
      <span>Sie führen mit diesem Update mehrere Lizenzen zusammen. </span>
      <span v-if="boundLicenses.length === 0">Bitte geben Sie Ihre Daten an, um die zusammengeführte Lizenz an einen einheitlichen Lizenznehmer zu binden. </span>
      <span v-else>Bitte wählen Sie den Lizenznehmer für die zusammengeführte Lizenz aus. Im Anschluss können Sie die Angaben bei Bedarf korrigieren. </span>
    </p>

    <b-field
      v-if="boundLicenses.length > 1"
      label="Daten übernehmen von Lizenz"
    >
      <b-dropdown
        v-model="selectedLicense"
      >
        <template
          #trigger
        >
          <b-button
            style="height: auto; text-align: left;"
            icon-right="chevron-down"
          >
            <license-dropdown-item :license="selectedLicense" />
          </b-button>
        </template>

        <b-dropdown-item
          v-for="license of boundLicenses"
          :key="license._id"
          :value="license"
        >
          <license-dropdown-item
            :license="license"
          />
        </b-dropdown-item>
      </b-dropdown>
    </b-field>
    <license-bind-form
      :holder-data="holderData"
      :mode="holderData ? 'edit' : 'create'"
      :restricted-holder-types="licenseType === 'personal' ? ['person'] : ['organization']"
      :loading="isLoadingPatch"
      @submit="onBindingFormSubmitted"
    />
  </div>
</template>

<script>
import LicenseBindForm from '@/components/customer/LicenseBindForm'
import LicenseDropdownItem from '@/components/general/LicenseDropdownItem.vue'
import { mapState } from 'vuex'
import license from '@/mixins/license'
import ErrorMessage from '@/components/general/ErrorMessage.vue'

export default {
  components: {
    LicenseBindForm,
    LicenseDropdownItem,
    ErrorMessage
  },
  mixins: [license],
  data () {
    return {
      selectedLicense: null
    }
  },
  computed: {
    ...mapState('assistant', ['authenticatedLicenses', 'licenseType', 'isLoadingPatch', 'patchError']),
    boundLicenses () {
      return this.authenticatedLicenses
        .filter(d => d.license.status === 'bound')
        .map(d => d.license)
    },
    holderData () {
      return this.selectedLicense?.holder ?? null
    }
  },
  mounted () {
    if (this.boundLicenses.length >= 1) {
      this.selectedLicense = this.boundLicenses[0]
    }
  },
  methods: {
    async onBindingFormSubmitted (holderData) {
      if (this.isLoadingPatch) {
        return
      }
      const success = await this.$store.dispatch('assistant/patchSession', {
        holder: holderData
      })
      if (success) {
        this.$emit('completed')
      }
    }
  }
}
</script>
