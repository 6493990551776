<template lang="pug">
  #order-generation-single
    section.section
      .container.page
        error-message(
          :error="fetchDataError"
          :isLoading="isLoading"
          show-raw, @retry="fetchData()"
          prefix="Fehler beim Abrufen der Shop-Generierung."
        )
        .nav.panel
          p.panel-heading Details der Shop-Generierung {{ $route.params.id }}
          .panel-block
            b-loading(:active.sync="isLoading")
            order-generation-details(:order-generation="orderGeneration", v-if="orderGeneration != null")
</template>

<script>
import OrderGenerationAPI from '@/services/api/OrderGeneration'
import ErrorMessage from '@/components/general/ErrorMessage'
import OrderGenerationDetails from '@/components/admin/order-generation/OrderGenerationDetails'

export default {
  components: {
    ErrorMessage, OrderGenerationDetails
  },
  data: function () {
    return {
      isLoading: false,
      fetchDataError: null,
      orderGeneration: null
    }
  },
  mounted: function () {
    this.fetchData()
  },
  methods: {
    /**
     * Fetch order-generation from api
     */
    fetchData: async function () {
      this.isLoading = true
      this.fetchDataError = null
      try {
        this.isLoading = false
        this.orderGeneration = (await OrderGenerationAPI.getByID(this.$route.params.id)).data.data.orderGeneration
      } catch (error) {
        this.isLoading = false
        this.fetchDataError = error
        console.error(error)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .panel-block .order-generation-details {
    width: 100%;
  }
</style>
