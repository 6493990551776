<template lang="pug">
.widget
  .widget-top-bar
    .level
      .level-left
        .icon.is-medium(v-if="icon"): span.far(:class="icon").is-large
        .title {{ title }}
      .level-right
        .widget-actions
          slot(name="actions")
  .widget-content
      div(v-if="hasContent")
        slot(name="content")
      div(v-else-if="emptyStateMessage !== ''")
        p.empty-state-message {{ emptyStateMessage }}
      div(v-else)
        p.empty-state-message Kein Inhalt

</template>
<script>
export default {
  inject: ['$validator'],
  props: {
    title: {
      type: String,
      default: ''
    },
    hasContent: {
      type: Boolean,
      default: true
    },
    emptyStateMessage: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/bulma-variables.scss';
  .widget {
    background-color: $white;
    margin-bottom: 1em;
    box-shadow: 4px $dark;
  }

  .widget-top-bar {
  padding: 5px 10px;
  //background: $dark;
  //color: $white;
  .level {
    padding-bottom: 0 !important;
  }
  .title {
    //color: $white;
    font-size: 18px;
  }
  border-bottom: 2px solid $medium;
  }

  .widget.is-warning .widget-top-bar {
    background: #ffdd57;
  }
  .widget-content {
    padding: 5px 10px;
    p.empty-state-message {
      padding: 0.5em 0.75em;
    }
  }

</style>
