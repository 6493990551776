<template lang="pug">
  #backend-layout
      navigation-bar
      router-view
      footer-bar
</template>

<script>
import NavigationBar from '@/components/general/NavigationBar'
import FooterBar from '@/components/general/FooterBar'
export default {
  components: {
    NavigationBar,
    FooterBar
  }
}
</script>

<style lang="scss">
  .container.page {
    padding-top: 40px;
  }
</style>
