<template lang="pug">
  section.section
    .container.content.has-background-white
      metacom-terms-content
</template>

<script>
import MetacomTermsContent from '@/components/customer/MetacomTermsContent'
export default {
  components: {
    MetacomTermsContent
  }

}
</script>

<style lang="scss" scoped>
  .container.content {
    padding: 2em;
  }
</style>
