<template lang="pug">
  widget-panel(title="Dokumente", icon="fa-copy")
    template(slot="actions")
    template(slot="content")
      table.table
        tbody
          tr
            th Startdokument

            // Available, can generate again
            td(v-if="starterDocument")
              .buttons
                button.button.is-small(@click="downloadStarter()", v-bind:class="{'is-loading': isDownloadingStarter}") Herunterladen
                button.button.is-small(v-if="status !== 'bound'", @click="generateStarter()", v-bind:class="{'is-loading': isGeneratingStarter}") Erneut generieren

            // Not available, can generate
            td(v-if="!starterDocument && status !== 'bound'")
              button.button.is-small(@click="generateStarter()", v-bind:class="{'is-loading': isGeneratingStarter}") Jetzt generieren

            // Not available, can't generate
            td(v-if="!starterDocument && status === 'bound'") -
          tr
            th Lizenzdokument
            // Available, can generate again
            td(v-if="licenseDocument")
              .buttons
                button.button.is-small(@click="downloadLicense()", v-bind:class="{'is-loading': isDownloadingLicense}") Herunterladen
                button.button.is-small(@click="generateLicense()", v-bind:class="{'is-loading': isGeneratingLicense}") Erneut generieren

            // Not available, can generate
            td(v-if="!licenseDocument && status === 'bound'")
              button.button.is-small(@click="generateLicense()", v-bind:class="{'is-loading': isGeneratingLicense}") Jetzt generieren

            // Not available, can't generate
            td(v-if="!licenseDocument && status !== 'bound'") -

</template>

<script>
import LicenseAPI from '@/services/api/License'

import licenseMixin from '@/mixins/license'
import WidgetPanel from '@/components/general/WidgetPanel'
export default {
  components: { WidgetPanel },
  mixins: [licenseMixin],
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: true
    },
    starterDocument: {
      type: String,
      required: false,
      default: undefined
    },
    licenseDocument: {
      type: String,
      required: false,
      default: undefined
    },
    status: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'Dokumente'
    }
  },
  data: () => {
    return {
      isGeneratingLicense: false,
      isDownloadingLicense: false,
      isGeneratingStarter: false,
      isDownloadingStarter: false
    }
  },
  methods: {
    /**
     * Get starter document download url and open it in a new window
     */
    downloadStarter: function () {
      this.isDownloadingStarter = true
      LicenseAPI.getStarterDocumentURLForLicense(this.id)
        .then((response) => {
          this.isDownloadingStarter = false
          window.open(response.data.data.url)
        })
        .catch((err) => {
          this.isDownloadingStarter = false
          this.$buefy.toast.open({
            message: 'Fehler: ' + err,
            type: 'is-danger'
          })
        })
    },
    /**
     * Get license document download url and open it in a new window
     */
    downloadLicense: function () {
      this.isDownloadingLicense = true
      LicenseAPI.getLicenseDocumentURLForLicense(this.id)
        .then((response) => {
          this.isDownloadingLicense = false
          window.open(response.data.data.url)
        })
        .catch((err) => {
          this.isDownloadingLicense = false
          this.$buefy.toast.open({
            message: 'Fehler: ' + err,
            type: 'is-danger'
          })
        })
    },
    /**
     * Generate the starter document
     */
    generateStarter: function () {
      this.isGeneratingStarter = true
      LicenseAPI.postStarterDocumentForLicense(this.id)
        .then((response) => {
          this.isGeneratingStarter = false
          this.$emit('update:starterDocument', response.data.data.license.starterDocument)
          this.$buefy.toast.open({
            message: 'Startdokument erzeugt',
            type: 'is-success'
          })
        })
        .catch((err) => {
          this.isGeneratingStarter = false
          this.$buefy.toast.open({
            message: 'Fehler: ' + err,
            type: 'is-danger'
          })
        })
    },
    /**
     * Generate license document
     */
    generateLicense: function () {
      this.isGeneratingLicense = true
      LicenseAPI.postLicenseDocumentForLicense(this.id)
        .then((response) => {
          this.isGeneratingLicense = false
          this.$emit('update:licenseDocument', response.data.data.license.licenseDocument)
          this.$buefy.toast.open({
            message: 'Lizenzdokument erzeugt',
            type: 'is-success'
          })
        })
        .catch((err) => {
          this.isGeneratingLicense = false
          this.$buefy.toast.open({
            message: 'Fehler: ' + err,
            type: 'is-danger'
          })
        })
    }
  }
}
</script>
