<template lang="pug">
  .query-builder
    b-field(label="Filtern")
      b-field.has-addons
        //- Pick query field
        .control
          b-select.select(
            v-model="selectedFieldKey"
            @input="selectedFieldChanged"
          )
            option(
              v-for="field in fields"
              :value="field.key"
            ) {{ field.name }}
        //- Pick query operator
        .control
          b-select.select(
            v-model="selectedOperatorKey"
            @input="selectedOperatorChanged"
          )
            option(
              v-for="operator in operators"
              :value="operator.key"
            ) {{ operator.name }}
        //- Specify query value via free text
        .control(v-if="!selectedOperator.single && !isOptionValueMode")
          b-input(
            v-if="!selectedOperator.multiple"
            v-model="filterValue"
          )
          b-taginput(
            placeholder="Wert hinzufügen"
            icon="caret-right"
            v-if="selectedOperator.multiple"
            v-model="filterValues"
          )

        //- Specify query value via select
        .control(v-if="!selectedOperator.single && isOptionValueMode")
          b-select.select(
            v-model="filterValue"
            v-if="!selectedOperator.multiple"
          )
            option(
              v-for="option in selectedField.options"
              :key="option.key"
              :value="option"
            ) {{ option.name || option.key }}

          b-field.multiple-options(
            v-if="selectedOperator.multiple"
          )
            b-checkbox-button(
              v-for="option in selectedField.options"
              :key="option.key"
              :native-value="option"
              v-model="filterValues"
            ) {{ option.name || option.key }}

        .control
          button.button.is-primary(@click="addFilter()") OK
    .active-filters
      b-field(label="Aktive Filter")
        .field.is-grouped.is-grouped-multiline
          span(v-if="filters.length === 0") Keine aktiven Filter
          .control(v-for="filter in filters")
            .tags.has-addons
              .tag.is-black {{ fieldForKey(filter.field).name }}
              .tag.is-dark {{ operatorForKey(filter.operator).name }}
              .tag.is-black(v-if="filter.value") {{ displayValueForFilter(filter) }}
              a.tag.is-button.is-primary(@click="editFilter(filter)")
                i.far.fa-pen
              a.tag.is-delete.is-primary(@click="removeFilter(filter)")
</template>

<script>
import FilterHelper from '@/lib/filter-helper'
export default {

  props: {
    fields: {
      type: Array,
      required: true,
      default: () => []
    },
    filters: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      editedFilter: null,
      selectedFieldKey: null,
      selectedField: {},

      selectedOperatorKey: null,
      selectedOperator: {},

      filterValue: '',
      filterValues: [],

      operators: FilterHelper.operators
    }
  },
  computed: {
    query () {
      return this.filters.map(f => this.getQueryForFilter(f)).join('&')
    },
    isOptionValueMode () {
      return this.selectedField.options && !this.selectedOperator.onlyFreeText
    }
  },

  mounted () {
    this.resetFields()
  },

  methods: {

    /**
     * Handle changes to the operator field
     */
    selectedOperatorChanged (operatorKey) {
      const previousOperator = this.selectedOperator
      const newOperator = this.operatorForKey(operatorKey)
      this.selectedOperator = newOperator

      if (
        previousOperator.single !== newOperator.single ||
        previousOperator.multiple !== newOperator.multiple ||
        previousOperator.onlyFreeText !== newOperator.onlyFreeText
      ) {
        // Only clear the input when the values are incompatible
        this.filterValue = ''
        this.filterValues = []
      }

      if (this.isOptionValueMode && !this.selectedOperator.multiple) {
        // Select initial option
        this.filterValue = this.selectedField.options[0]
      }
    },

    /**
     * Handle changes to the selected field
     */
    selectedFieldChanged (fieldKey) {
      const previousField = this.selectedField
      const newField = this.fieldForKey(fieldKey)
      this.selectedField = newField

      if (previousField.options !== newField.options) {
        // Only clear input when the values are incompatible
        this.filterValue = ''
        this.filterValues = []
      }

      if (this.isOptionValueMode && !this.selectedOperator.multiple) {
        // Select initial option
        this.filterValue = this.selectedField.options[0]
      }
    },

    /**
     * Display the value of a filter
     * uses option names for option fields, displays array values
     */
    displayValueForFilter (filter) {
      const field = this.fieldForKey(filter.field)
      if (Array.isArray(filter.value)) {
        if (field.options) {
          // Display option array
          return `[${filter.value.map(v => field.options.find(o => o.key === v).name).join(', ')}]`
        } else {
          // Display free text array
          return `[${filter.value.join(', ')}]`
        }
      } else {
        // Display option or free text value
        return field.options ? field.options.find(o => o.key === filter.value).name : filter.value
      }
    },

    /**
     * Reset the selection and values of the filter fields
     */
    resetFields () {
      this.filterValues = []
      this.filterValue = ''

      this.selectedField = this.fields[0]
      this.selectedFieldKey = this.selectedField.key

      this.selectedOperator = this.operators[0]
      this.selectedOperatorKey = this.selectedOperator.key
    },

    /**
     * Add a filter with the selected properties
     */
    addFilter () {
      const filter = {
        field: this.selectedFieldKey,
        operator: this.selectedOperatorKey
      }

      if (!this.selectedOperator.single) {
        // Selected option(s) values
        if (this.selectedOperator.multiple) {
          if (this.isOptionValueMode) {
            filter.value = this.filterValues.map(v => v.key)
          } else {
            filter.value = this.filterValues
          }
        } else {
          if (this.isOptionValueMode) {
            filter.value = this.filterValue.key
          } else {
            filter.value = this.filterValue
          }
        }
      }

      if (this.editedFilter !== null) {
        // Update edited filter
        this.editedFilter.field = filter.field
        this.editedFilter.operator = filter.operator
        this.editedFilter.value = filter.value
      } else {
        // Add new
        // this.$emit('update:filters', [...this.filters, filter])
      }

      this.editedFilter = null
      this.resetFields()
      this.$emit('update:query', this.query)
      this.$emit('update:filter', [...this.filters, filter])
    },

    /**
     * Edit a filter
     * Loads its values into the filter select and marks it as being edited
     */
    editFilter (filter) {
      this.selectedFieldKey = filter.field
      this.selectedField = this.fieldForKey(filter.field)
      this.selectedOperatorKey = filter.operator
      this.selectedOperator = this.operatorForKey(filter.operator)

      if (Array.isArray(filter.value)) {
        if (this.isOptionValueMode) {
          // Inflate the value option objects
          this.filterValues = filter.value.map(v => this.selectedField.options.find(o => o.key === v))
        } else {
          // Free text values
          this.filterValues = filter.value
        }
      } else {
        if (this.isOptionValueMode) {
          // Inflate the value option object
          this.filterValue = this.selectedField.options.find(o => o.key === filter.value)
        } else {
          // Free text value
          this.filterValue = filter.value
        }
      }

      this.editedFilter = filter
    },

    /**
     * Remove a filter on click
     * @param {Object} filter - filter to delete
     */
    removeFilter (filter) {
      const f = [...this.filters]
      f.splice(this.filters.findIndex(f => {
        return f.value === filter.value && f.operator === filter.operator && f.value === filter.value
      }), 1)
      if (filter === this.editedFilter) {
        this.resetFields()
        this.editedFilter = null
      }
      // this.$emit('update:query', this.query)
      this.$emit('update:filter', f)
    },

    /**
     * Build the query for a filter
     * @param {Object} filter - the filter
     * @param {String} filter.field - field key
     * @param {String} filter.operator - operator key
     * @param {String} [filter.value] - filter value
     * @param {Array<String>} [filter.values] - filter values
     */
    getQueryForFilter (filter) {
      FilterHelper.getQueryForFilter(filter)
    },

    /**
     * Get an operator for a certain key
     */
    operatorForKey (operatorKey) {
      return this.operators.find(o => o.key === operatorKey)
    },

    /**
     * Get a field for a certain key
     */
    fieldForKey (fieldKey) {
      return this.fields.find(f => f.key === fieldKey)
    }

  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';

.active-filters {
  margin-bottom: 2em;
}
.multiple-options {
    flex-wrap: wrap;
}

.tag.is-button {
    padding: 0;
    position: relative;
  width: 2em;
  text-decoration: none;
  &:hover,
  &:focus {
    background-color: darken($background, 5%);
    text-decoration: none;
  }
  &:active {
    background-color: darken($background, 10%);
    text-decoration: none;
  }
}
</style>
