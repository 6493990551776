<template lang="pug">
  .modal-card
    .modal-card-head
      .modal-card-title MetaSearch Multistep herunterladen
      button.delete(aria-label="close", @click="$parent.close()")
    section.modal-card-body
      .content
        :markdown-it(breaks="true")
          Der Multistep Installer ist eine spezielle Version des MetaSearch Installers für Windows (32-Bit / 64-Bit), der die Softwareverteilung in Organisationen erleichtern soll.

          Er erlaubt im Gegensatz zum normalen Installer die Auswahl eines anderen Installationsortes und die Installation für alle Benutzer des PCs. Durch die Verwendung von Kommandozeilenparametern kann die Startkonfiguration von MetaSearch bereits bei der Installation festgelegt werden.

          Grundsätzlich sind alle Konfigurationen aber auch mit dem normalen Installer realisierbar und es wird die selbe Software installiert.

          **Beachten Sie zur Verwendung dieses Installers insbesondere die Abschnitte 1.3., 3. und 4.3. der Hinweise für Serverinstallationen.**
          Bei geändertem Installationsort oder der Installation für alle Benutzer deaktiviert MetaSearch die automatischen Updates.

      //- Windows Download
      .card.download-item(style="display:inline-block;")
        .card-content.has-text-centered
          .content
            figure.image.is-48x48
              img(src="@/assets/images/metasearch/metasearch_icon_notext.svg")
              i.fab.fa-windows
            h4 MetaSearch
            div: strong Multistep Installer für Windows
            div 32-Bit / 64-Bit
          button.button.is-primary(
            @click="getDownloadURL()"
            v-if="!downloadURL"
            :disabled="!latestVersion"
            :class="{'is-loading': isLoading}"
          )
            span.icon.is-small: i.far.fa-link
            span Link erzeugen
          //- Once the download link is requested
          transition(
            enter-active-class="animated bounce"
          )
            a.button.is-primary(
              :href="downloadURL"
              target="_blank"
              v-if="downloadURL"
            )
              span.icon.is-small: i.far.fa-cloud-download
              span.text Herunterladen
      br
      br
      p.has-text-grey.has-text Neueste Version: {{latestVersion}}
    footer.modal-card-foot
</template>

<script>
import MetaSearchAPI from '@/services/api/MetaSearch'
import ErrorMessage from '@/components/general/ErrorMessage'
export default {
  components: { ErrorMessage },
  data () {
    return {
      isLoadingLatestVersion: false,
      latestVersion: null,

      requestError: null,

      downloadURL: null,
      isLoadingDownloadURL: false

    }
  },
  mounted () {
    this.getLatestVersion()
  },
  methods: {
    /**
     * Retrieve the latest metasearch version
     */
    async getLatestVersion () {
      this.isLoadingLatestVersion = true
      try {
        const { version } = (await MetaSearchAPI.getLatestVersion()).data.data
        this.isLoadingLatestVersion = false
        this.requestError = null
        this.latestVersion = version
      } catch (error) {
        this.isLoadingLatestVersion = false
        this.requestError = error
      }
    },

    /**
     * Fetch the download link and download the installer
      * @param {'windows'|'mac'|'linux'|'linux-ia32'} platform - platform
     */
    async getDownloadURL () {
      this.isLoadingDownloadURL = true
      try {
        const { url } = (await MetaSearchAPI.getMultistepDownloadLink()).data.data
        this.isLoadingDownloadURL = false
        this.downloadURL = url
        this.requestError = null
      } catch (error) {
        console.error(error)
        this.isLoadingDownloadURL = false
        this.$buefy.toast.open({
          message: `Download Link konnte nicht abgerufen werden: ${error}`,
          duration: 5000,
          type: 'is-danger'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-card {
    width: 100%;
  }
  .card .content figure.image {
    margin: 0 auto;
    margin-bottom: 1em;
    position: relative;
    i {
      position: absolute;
      right: -40%;
      top: 60%;
      font-size: 1.5em;
    }
  }
</style>
