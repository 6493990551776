<template lang="pug">
form(
  @submit.prevent="validateForm()"
)
  //- NAME
  b-field(
    label="Name des Lizenznehmers",
    :type="errors.has('name') ? 'is-danger' : ''",
    :message="errors.first('name')"
  )
    b-field(
      :type="errors.has('name') ? 'is-danger' : ''",
    )
      b-input(
        icon="user",
        placeholder="Vor- und Nachname" ,
        expanded, v-model="name",
        v-validate="'required'",
        name="name",
        data-vv-as="Name des Lizenznehmers",
      )
      p.control
        b-dropdown(hoverable, position="is-bottom-left")
          p.button.is-dark(slot="trigger") ?
          b-dropdown-item(custom)
            p Die Lizenz wird an diese Person gebunden. Sie müssen den Namen der Person in Zukunft eingeben, wenn Sie sich mit Ihrer Lizenz anmelden möchten.

  //- EMAIL ADDRESS
  b-field(
    label="E-Mail Adresse",
    :type="errors.has('email') ? 'is-danger' : ''"
    :message="errors.first('email')",
  )
    b-field(:type="errors.has('email') ? 'is-danger' : ''")
      b-input(
        icon="envelope",
        placeholder="Ihre E-Mail Adresse" ,
        expanded,
        v-model="email",
        v-validate="'required|email'",
        name="email",
        ref="email"
        data-vv-as="E-Mail Adresse",
      )
      p.control
        b-dropdown(hoverable, position="is-bottom-left")
          p.button.is-dark(slot="trigger") ?
          b-dropdown-item(custom)
            p An diese E-Mail Adresse wird Ihre gebundene Lizenz versandt.

  //- EMAIL ADDRESS CONFIRMATION
  b-field(
    :type="errors.has('email_confirmation') ? 'is-danger' : ''"
    :message="errors.first('email_confirmation')",
  )
    b-field(
      :type="errors.has('email_confirmation') ? 'is-danger' : ''",
    )
      b-input(
        icon="envelope",
        placeholder="E-Mail Adresse wiederholen" ,
        expanded,
        v-model="emailConfirmation",
        v-validate="'required|confirmed:email'",
        name="email_confirmation",
        data-vv-as="Wiederholung der E-Mail Adresse",
      )
      p.control
        b-dropdown(hoverable, position="is-bottom-left")
          p.button.is-dark(slot="trigger") ?
          b-dropdown-item(custom)
            p An diese E-Mail Adresse wird Ihre gebundene Lizenz versandt.

  //- CONFIRM AUTHORIZATION
  b-field(
    label="Weitere erforderliche Angaben"
    :message="errors.first('authorized')",
    :type="errors.has('authorized') ? 'is-danger' : ''"
  )
    b-checkbox(
      v-validate="'required'",
      name="authorized",
      data-vv-as="Bestätigung Autorisierung",
      v-model="authorized",
      :type="errors.has('authorized') ? 'is-danger' : ''"
    )  Ich bin Käufer dieser Lizenz oder anderweitig autorisiert diese Lizenz an mich zu binden

  //- PRIVACY POLICY
  b-field(
    :message="errors.first('privacy')",
    :type="errors.has('privacy') ? 'is-danger' : ''"
  )
    b-checkbox(
      v-validate="'required'",
      name="privacy",
      data-vv-as="Bestätigung der Datenschutzerklärung",
      v-model="privacy",
      :type="errors.has('privacy') ? 'is-danger' : ''"
    ) Ich habe die #[a(href="https://metacom.shop/datenschutzerklaerung", target="_blank") Datenschutzerklärung] gelesen und akzeptiere, wie meine angegebenen Daten auf dieser Seite verarbeitet werden.

  .field: p.control
    button.submit.button.is-primary.is-fullwidth(type="submit") Diese Lizenz jetzt an diese Angaben binden

</template>

<script>
export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  props: {

  },
  data: () => {
    return {
      name: '',
      email: '',
      emailConfirmation: '',
      authorized: '',
      privacy: ''
    }
  },
  methods: {
    validateForm () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('submit', {
            name: this.name,
            email: this.email,
            emailConfirmation: this.emailConfirmation
          })
        }
      })
    }
  }
}
</script>
