<template lang="pug">
  div
    .content: p Hier tauchen alle Auslieferungen der Lizenz per E-Mail auf. Daraus kann erkannt werden, welches Dokument wann an welche E-Mail Adresse gesendet wurde.
    //- Error message
    article.message.is-warning(v-if="fetchDataError")
      .message-header: p Fehler beim Abrufen
      .message-body
        strong Status {{ fetchDataError.status }}
        p Fehler beim Abrufen der Auslieferungen: {{fetchDataError.response.message}}
        pre {{ fetchDataError.response }}
        button.button(v-bind:class="{'is-loading': isLoading}", @click="fetchData()") Erneut versuchen

    //- Empty state message
    article.message(v-if="deliveries === undefined || deliveries.length === 0")
      .message-header: p Keine Auslieferungen
      .message-body: p Keine Datensätze gefunden. Diese Lizenz wurde nicht per E-Mail ausgeliefert.

    //- Filled state
    section.section(v-for="delivery in deliveries", :key="delivery._id")
      h4 Auslieferung {{ delivery._id }}
      delivery-details(
        :delivery="delivery"
        :current-license-id="license._id"
        @delivery-added="onDeliveryAdded($event)"
      )

</template>

<script>

import DeliveryDetails from '@/components/admin/delivery/DeliveryDetails'
import LicenseAPI from '@/services/api/License'

export default {
  components: {
    DeliveryDetails
  },
  props: {
    license: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      fetchDataError: null,
      deliveries: []
    }
  },

  created () {
    this.fetchData()
  },
  methods: {
    /**
     * Get the delivery ocassion in user friendly text
     */
    getOccasionText (occasion) {
      switch (occasion) {
        case 'order':
          return 'Shop-Generierung'
        case 'manual':
          return 'Manuell ausgelöst'
        case 'bind':
          return 'Binden der Lizenz'
        case 'update':
          return 'Aktualisierung des Lizenznehmers'
        default:
          return occasion
      }
    },

    /**
     * Get the delivered documentType as user friendly text
     */
    getDocumentTypeText (documentType) {
      switch (documentType) {
        case 'starter':
          return 'Startdokument'
        case 'license':
          return 'Gebundene Lizenz'
        default:
          return documentType
      }
    },

    /**
     * Fetch deliveries of the license
     */
    fetchData () {
      this.isLoading = true
      LicenseAPI.getDeliveriesForLicense(this.license._id)
        .then((response) => {
          this.fetchDataError = null
          this.isLoading = false
          this.deliveries = response.data.data.deliveries
        })
        .catch((error) => {
          this.isLoading = false
          this.fetchDataError = {
            status: error.response.status,
            response: error.response.data
          }
        })
    },

    /**
     * Handle a new delivery for this license
     */
    onDeliveryAdded (delivery) {
      this.deliveries.unshift(delivery)
    }
  }
}
</script>
