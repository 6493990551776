<template>
  <div class="pro-license-configurator">
    <license-change-preview />

    <label class="label mt-6">
      <span v-if="purchaseType === 'extension'">Wählen Sie aus, um welche Lizenzgröße Sie ihre bestehende Lizenz erweitern möchten:</span>
      <span v-else>Wählen Sie die gewünschte Lizenzgröße</span>
    </label>
    <p>Die Lizenzgröße bestimmt die maximale Anzahl der zur Verfügung stehenden Lizenzplätze.</p>
    <b-button
      class="mt-2 mb-2"
      type="is-secondary"
      icon-left="circle-question"
      @click="(isLicenseSizeInfoModalActive = true)"
    >
      Infos zur Lizenzgröße
    </b-button>

    <b-modal
      v-model="isLicenseSizeInfoModalActive"
    >
      <div class="card">
        <div class="card-header">
          <p class="card-header-title">
            Lizenzgröße der Pro-Lizenz
          </p>
        </div>
        <div class="card-content">
          <div class="content">
            <p>
              Die Lizenzgröße bestimmt die maximale Anzahl der zur Verfügung stehenden Lizenzplätze. Der Lizenznehmer darf die Lizenzplätze Produktanwendern, Produktarbeitsplätzen oder gleichzeitigen Produktnutzungen zuweisen.
            </p>

            <p>
              <strong>Produktanwender</strong> ist eine natürliche Person, die auf einem oder mehreren Geräten mit dem Produkt für Zwecke des Lizenznehmers arbeitet und hinsichtlich des Umgangs mit dem Produkt gegenüber dem Lizenznehmer weisungsgebunden ist. Jeder vom Lizenznehmer bestimmte Produktanwender belegt jeweils einen eigenen Lizenzplatz.
            </p>

            <p>
              Ein <strong>Produktarbeitsplatz</strong> ist ein Gerät, an dem eine oder mehrere Personen für Zwecke des Lizenznehmers mit dem Produkt arbeiten können. Jeder vom Lizenznehmer bestimmte Produktarbeitsplatz belegt jeweils einen eigenen Lizenzplatz. Zur Produktbereitstellung verwendete Server bzw. Terminalserver sind keine Produktarbeitsplätze, Clients der Server zählen als Produktarbeitsplätze.
            </p>

            <p>
              <strong>Gleichzeitige Produktnutzungen</strong> sind auf Terminalservern des Lizenznehmers zur gleichen Zeit laufende Produktsitzungen (Nutzung des Produkts oder seiner Bestandteile) und sind demnach auf Terminalserverumgebungen beschränkt, die alle zur gleichen Zeit laufenden Produktnutzungen zählen und begrenzen. <strong>Als Maximum der gleichzeitigen Produktnutzungen sind 1/3 (ein Drittel) der hierfür zugewiesenen Lizenzplätze erlaubt.</strong>
            </p>

            <p>
              Kombinationen und spätere Änderungen in der Zuweisung der Lizenzplätze sind erlaubt. Der Lizenznehmer kann z. B. eine Lizenzgröße 60 so aufteilen: 30 Lizenzplätze für 10 gleichzeitige Produktnutzungen auf einem Terminalserver, 30 Lizenzplätze für Arbeitsplätze mit lokaler Installation.
            </p>
          </div>
        </div>
      </div>
    </b-modal>
    <input-slider
      v-model="seatCount"
      :min="1"
      :max="10000"
      :slider-min="1"
      :slider-max="100"
      open-ended
    />

    <b-message
      v-if="!canPurchaseDVDs"
      class="mt-6"
      type="is-warning"
    >
      Die Lieferung zusätzlicher Installationsdatenträger (DVD) ist nur innerhalb von Deutschland möglich. Für die Nutzung der Lizenz ist eine DVD jedoch nicht erforderlich.
    </b-message>
    <div
      v-else
      class="field mt-6"
    >
      <label class="label">Bitte wählen Sie, wie viele DVDs Sie erwerben möchten:</label>
      <p>
        <span>Die Installationsdateien werden als Download bereitgestellt. Zusätzlich können Installationsdatenträger (DVD) erworben werden. Die maximale Anzahl entspricht der Lizenzgröße.
          Preis je DVD: 15,00 € </span>
        <span v-if="cartVat > 0">(inkl. {{ cartVat | vat }} MwSt. und Versand)</span>
      </p>

      <input-slider
        v-model="dvdCount"
        :min="0"
        :max="seatCount"
        :slider-min="0"
        :slider-max="seatCount"
      />
    </div>

    <b-message
      v-if="purchaseType === 'upgrade' && seatCount < totalOldLicenseSeats"
      title="Warnung: Lizenzverkleinerung"
      type="is-warning"
      class="mt-6"
      :closable="false"
    >
      Sie sind dabei ein Lizenz-Update durchzuführen, haben aber eine kleinere Lizenzgröße gewählt als bei Ihre{{ authenticatedLicenses.length > 1 ? 'n' : 'r' }} alten Lizenz{{ authenticatedLicenses.length > 1 ? 'en' : '' }}. Dadurch, dass Ihre alte{{ authenticatedLicenses.length > 1 ? 'n' : '' }} Lizenz{{ authenticatedLicenses.length > 1 ? 'en' : '' }} bei diesem Update ersetzt und deaktiviert {{ authenticatedLicenses.length > 1 ? 'werden' : 'wird' }}, haben Sie später keine Möglichkeit mehr, diese zum vergünstigten Updatepreis zu vergrößern.
    </b-message>

    <div class="content mt-6">
      <b-field
        label="Zusammenfassung des Kaufs:"
      >
        <cart-summary />
      </b-field>

      <b-button
        type="is-primary"
        @click="$emit('completed')"
      >
        Mit der ausgewählten Lizenz fortfahren
      </b-button>
    </div>
  </div>
</template>
<script>
import InputSlider from '@/components/general/InputSlider.vue'
import CartSummary from '@/components/customer/assistant/CartSummary.vue'
import LicenseChangePreview from '@/components/customer/assistant/LicenseChangePreview.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'
export default {
  components: {
    InputSlider,
    CartSummary,
    LicenseChangePreview
  },
  data () {
    return {
      isLicenseSizeInfoModalActive: false
    }
  },
  computed: {
    ...mapState('assistant', ['purchaseType', 'authenticatedLicenses']),
    ...mapGetters('assistant', ['purchasedLicenseData', 'canPurchaseDVDs', 'cartVat', 'totalOldLicenseSeats']),
    seatCount: {
      get () {
        return this.$store.state.assistant.seatCount
      },
      set (value) {
        this.updateState({ key: 'seatCount', value })
      }
    },
    dvdCount: {
      get () {
        return this.$store.state.assistant.dvdCount
      },
      set (value) {
        this.updateState({ key: 'dvdCount', value })
      }
    }
  },
  methods: {
    ...mapMutations('assistant', ['updateState'])
  }
}
</script>
