<template lang="pug">
  .license-help-links
    .general.columns(v-if="specificHelp === null")
      .column.has-text-centered: a.button.is-fullwidth(target="_blank", href="https://metacom.support") METACOM Fragen & Antworten
    .specific-help(v-else)
      a.button(target="_blank", href="https://metacom.support") METACOM Fragen & Antworten
</template>
<script>
export default {
  props: {
    license: {
      type: Object,
      default: null
    }
  },
  computed: {
    specificHelp () {
      if (this.license && this.license.properties && this.license.properties.delivery) {
        return this.license.properties.delivery
      } else {
        return null
      }
    }
  }
}
</script>
