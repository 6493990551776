<template lang="pug">
  transition(
    enter-active-class="animated slideInUp"
    leave-active-class="animated slideOutDown"
  )
    .cookie-banner(v-if="isShown")
      .container
        .level
          .level-left
            .level-item
              p Diese Webseite legt in Ihrem Browser Informationen über den Login-Zustand der Anwendung „Lizenzverwaltung” ab. Die Informationen werden benötigt um sicherzustellen, dass Sie berechtigt sind auf eine Lizenz zuzugreifen. Durch die Benutzung der Webseite stimmen Sie dieser Nutzung im Rahmen unserer #[a(href="https://metacom.shop/datenschutzerklaerung", target="_blank") Datenschutzerklärung] zu.
          .level-right
            .level-item
              .column: button.button.is-primary(@click="didAccept()") Akzeptieren
</template>

<script>
export default {
  data () {
    return {
      isShown: false
    }
  },
  mounted () {
    this.isShown = localStorage.getItem('cookies-accepted') !== 'true' && this.$route?.name !== 'm9-license-assistant'
  },
  methods: {
    didAccept () {
      localStorage.setItem('cookies-accepted', 'true')
      this.isShown = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .cookie-banner {
    background: $dark;
    color: $light;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 1em 0;

    .level-left, .level-left .level-item {
      flex-shrink: 1;
    }
  }
</style>
