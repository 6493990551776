<template>
  <div class="cart-summary">
    <table>
      <thead>
        <tr>
          <th colspan="">
            Artikel
          </th>
          <th colspan="">
            Einzelpreis netto
          </th>
          <th colspan="">
            MwSt.
          </th>
          <th colspan="">
            Anzahl
          </th>
          <th
            colspan=""
            class="has-text-right"
          >
            Betrag netto
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(lineItem, i) of cart.lineItems"
          :key="i"
        >
          <td>
            <h6 class="mb-2 pl-1">
              {{ lineItem.title }}
            </h6>
            <b-field
              v-if="lineItem.itemVolumeDiscount > 0"
              grouped
              multiline
            >
              <div class="control">
                <b-taglist attached>
                  <b-tag>
                    Einzel-Grundpreis (netto)
                  </b-tag>
                  <b-tag
                    type="is-medium-grey"
                    class="has-text-weight-bold"
                  >
                    {{ lineItem.itemBaseNet | price }}
                  </b-tag>
                </b-taglist>
              </div>
              <div class="control">
                <b-taglist attached>
                  <b-tag>Mengenrabatt ({{ cartTotalProLicenseQuantity }} Plätze)</b-tag>
                  <b-tag
                    type="is-medium-grey"
                    class="has-text-weight-bold"
                  >
                    {{ lineItem.itemVolumeDiscount | percent }}
                  </b-tag>
                </b-taglist>
              </div>
            </b-field>
          </td>
          <td>{{ lineItem.itemNet | price }}</td>
          <td>{{ lineItem.itemVat | price }} ({{ cart.totals.vatRate | vat }}) </td>
          <td>{{ lineItem.quantity }}</td>
          <td class="has-text-right">
            {{ lineItem.net | price }}
          </td>
        </tr>
      </tbody>
      <tfoot style="text-align: right;">
        <tr>
          <td colspan="4">
            Netto
          </td>
          <td>
            {{ cart.totals.net | price }}
          </td>
        </tr>
        <tr>
          <td colspan="4">
            MwSt. ({{ cart.totals.vatRate | vat }})
          </td>
          <td>
            {{ cart.totals.vat | price }}
          </td>
        </tr>
        <tr>
          <th colspan="4">
            Gesamt
          </th>
          <th>
            {{ cart.totals.gross | price }}
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import licenseMixin from '@/mixins/license'
export default {
  mixins: [
    licenseMixin
  ],
  computed: {
    // ...mapState('assistant', []),
    ...mapGetters('assistant', ['shopProducts', 'cart', 'cartTotalProLicenseQuantity'])
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.content table {
  tfoot {
    tr:not(:first-child) {
      td, th {
        border-width: 0;
      }
    }

    td, th {
      padding: 0.1em 0.5em;
    }
  }
}
</style>
