<template lang="pug">
  .container.page.overview
    h1.title Alle Lizenzen
    .content
      .level
        .level-left
        .level-right
          .button.is-primary(@click="createNewLicenseClicked()")
            span.icon: i.far.fa-plus
            span Neue Lizenz(en) anlegen
      //- Filters
      query-builder(
        :fields="filterFields"
        :filters="filters"
        @update:filter="onFilterChange($event)"
      )
      .columns
        .column
          b-field(label="Mehrfachaktionen", style="position:relative")
            b-field(grouped)
              b-loading(:active="isMultipleActionLoading", :is-full-page="false")
              b-field
                b-switch(v-model="isEditingMultiple") Mehrere auswählen
              b-field
                b-dropdown(:disabled="!isEditingMultiple || !selectedLicenses || selectedLicenses.length < 2")
                  button.button.is-primary.is-small(slot="trigger")
                    span Mehrfachaktionen
                    span.icon: i.fas.fa-caret-down
                  //- b-dropdown-item(@click="assignGroupToSelected()" disabled)
                  //-   span.icon: i.fas.fa-folder
                  //-   span Gruppe zuordnen (TBD)
                  //- b-dropdown-item(@click="assignGroupToSelected()" disabled)
                  //-   span.icon: i.fas.fa-folder
                  //-   span Deaktivieren (TBD)
                  b-dropdown-item(@click="downloadStarterPDFSelected()")
                    span.icon: i.fas.fa-file-pdf
                    span Startdokumente herunterladen: Mehrseitiges PDF
                  b-dropdown-item(@click="downloadStarterZIPSelected()")
                    span.icon: i.fas.fa-file-archive
                    span Startdokumente herunterladen: ZIP
                  b-dropdown-item(@click="downloadLicensePDFSelected()")
                    span.icon: i.fas.fa-file-pdf
                    span Lizenzen herunterladen: Mehrseitiges PDF
                  b-dropdown-item(@click="downloadLicenseZIPSelected()")
                    span.icon: i.fas.fa-file-archive
                    span Lizenzen herunterladen: ZIP
        .column
          b-field(label="Lizenzen pro Seite")
            b-select(
              v-model="licensesPerPage"
              @input="onLicensesPerPageChange($event)"
            )
              option(:value="20") 20
              option(:value="50") 50
              option(:value="100") 100
              option(:value="Infinity") Alle

    //- Error message
    error-message(
      :error="fetchError"
      :is-loading="isLoading"
      @retry="fetchLicenses()"
      show-raw
    )

    //- License table
    b-table(
      :data="licenses",
      :loading="isLoading",

      paginated
      backend-pagination
      :total="totalLicenses"
      :per-page="licensesPerPage"
      :current-page="currentPage"
      @page-change="onPageChange"

      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"

      detailed,
      detail-key="_id",

      :checked-rows.sync="selectedLicenses",
      :checkable="isEditingMultiple"
    )
      //- License table row template
      b-table-column(label="TYP" v-slot="props").identifier
        license-identifier(:license="props.row")
      b-table-column(field="createdAt" label="ERZEUGT" sortable v-slot="props")
        .tags
          date-tag(:date="props.row.createdAt")
          time-tag(:date="props.row.createdAt")

      b-table-column(label="INFO" v-slot="props")
        .tags
          order-tag(
            v-if="props.row.typeOfEntry === 'order'"
            :order-number="props.row.entryReference"
          )
          user-tag(
            v-if="props.row.typeOfEntry === 'manual'"
            :username="props.row.entryReference"
          )
          license-holder-tag(
            v-if="props.row.status === 'bound'"
            :name="props.row.holder.name"
            :email="props.row.holder.email"
            :contact="props.row.holder.contact"
          )
          span.tag.is-warning(v-if="hasLicenseAuthenticationCodeWarning(props.row)", title="Login möglicherweise nicht möglich")
            span.icon: i.far.fa-exclamation-triangle

      b-table-column(field="status", label="STATUS" v-slot="props")
        .tags
          span.tag(
            v-bind:class="{ 'is-primary': props.row.status === 'bound', 'is-secondary': props.row.status === 'unbound', 'is-danger': props.row.status === 'disabled'  }"
          )
            span {{ getFormattedStatusOfLicense(props.row) }}
          router-link(v-if="props.row.updatedToLicense" :to="{ name: 'license-details', params: { id: props.row.updatedToLicense }}" target="_blank")
            b-tag(type="is-update")
              b-icon(icon="angle-double-up")

      b-table-column(label="AKTIONEN" v-slot="props")
        b-dropdown(
          :disabled="licenseActionsLoading.indexOf(props.row._id) !== -1"
          position="is-bottom-left"
        )
          button.button.is-primary.is-small(slot="trigger")
            span Aktionen
            span.icon: i.fas.fa-caret-down
          b-dropdown-item(
            @click="downloadStarterPDF(props.row)"
            :disabled="!props.row.starterDocument"
          ) Startdokument herunterladen
          b-dropdown-item(
            @click="downloadLicensePDF(props.row)"
            :disabled="!props.row.licenseDocument"
          ) Lizenzdokument herunterladen

      //- License table detail template
      template(v-slot:detail="props")
        backend-license-preview(
          :license="props.row",
          @update:license="licenseUpdated(props.row, $event)"
        )

      template(v-slot:bottom-left)
        b {{selectedLicenses.length}} von {{ totalLicenses }} Lizenzen ausgewählt

</template>

<script>
import BackendLicensePreview from '@/components/admin/BackendLicensePreview'
import TimeTag from '@/components/general/TimeTag'
import DateTag from '@/components/general/DateTag'
import OrderTag from '@/components/general/OrderTag'
import UserTag from '@/components/general/UserTag'
import LicenseHolderTag from '@/components/general/LicenseHolderTag'
import LicenseIdentifier from '@/components/admin/license/LicenseIdentifier'
import QueryBuilder from '@/components/admin/query-builder/QueryBuilder'
import ErrorMessage from '@/components/general/ErrorMessage'

import licenseMixin from '@/mixins/license'
import LicenseCreateForm from '@/components/admin/license/LicenseCreateForm'

import BatchDownloadAPI from '@/services/api/BatchDownload'
import LicenseAPI from '@/services/api/License'

import BlobHelper from '@/services/utility/BlobHelper'
import FilterHelper from '@/lib/filter-helper'

export default {
  components: {
    BackendLicensePreview,
    DateTag,
    TimeTag,
    OrderTag,
    UserTag,
    LicenseHolderTag,
    LicenseIdentifier,
    ErrorMessage,
    QueryBuilder
  },
  mixins: [licenseMixin],

  /**
   * When the current route is updated
   */
  beforeRouteUpdate (to, from, next) {
    this.updateQueryFromRoute(to) // Load updated query from route
    this.fetchLicenses() // Fetch licenses
    next()
  },
  data: () => {
    return {
      isLoading: false,
      fetchError: null,

      licenseActionsLoading: [],

      licenses: [],
      totalLicenses: 0,

      licensesPerPage: 20,
      currentPage: 1,
      sortField: 'createdAt',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      filters: [],

      filterFields: FilterHelper.licenseFields,

      isEditingMultiple: false,
      selectedLicenses: [],

      isMultipleActionLoading: false
    }
  },

  /**
   * When component is mounted
   */
  mounted () {
    this.updateQueryFromRoute(this.$route) // Load initial query from route
    this.fetchLicenses() // Fetch the licenses
  },
  methods: {
    /**
    * Fetch the filtered licenses
    */
    async fetchLicenses () {
      this.isLoading = true

      // Perform fetch using the current query
      try {
        const query = this.getCurrentQueryParams()
        const { licenses, total } = (await LicenseAPI.list(query)).data.data

        this.isLoading = false
        this.fetchError = null

        this.licenses = licenses
        this.totalLicenses = total

        if (this.totalLicenses > 0 && ((this.currentPage - 1) * this.licensesPerPage) > this.totalLicenses) {
          // Current page is out of view (e.g. after filter change)
          this.currentPage = Math.ceil(this.totalLicenses / this.licensesPerPage) // Change current route
          this.updateRouteForQuery()
        }
      } catch (error) {
        console.error(error)
        this.isLoading = false
        this.fetchError = error
      }
    },

    /**
     * Get the query params to fetch current data
     */
    getCurrentQueryParams () {
      const queryComponents = [
        `limit=${this.licensesPerPage}`,
        `skip=${(this.currentPage - 1) * this.licensesPerPage}`,
        `sort=${(this.sortOrder === 'desc' ? '-' : '') + this.sortField}`
      ]
      if (this.filters.length > 0) {
        // Translate filters into query and concatenate them
        this.filters.forEach(filter => {
          queryComponents.push(FilterHelper.getQueryForFilter(filter))
        })
      }
      return queryComponents.join('&')
    },

    /**
     * Update the data query from the current route
     * Will read sort order, per page count and filters from url
     */
    updateQueryFromRoute (route) {
      if (route.query.filter) {
        this.filters = FilterHelper.decodeFromURL(route.query.filter)
      } else {
        this.filters = []
      }

      if (route.query.limit) {
        this.licensesPerPage = Number.parseInt(route.query.limit)
      }
      if (route.query.skip) {
        this.currentPage = Math.floor(Number.parseInt(route.query.skip) / this.licensesPerPage) + 1
      }
      if (route.query.sort) {
        this.sortOrder = route.query.sort.startsWith('-') ? 'desc' : 'asc'
        this.sortField = route.query.sort.split('-').pop()
      }
    },

    /**
     * Update the route to represent the current data query
     * Will put sort order, per page count and filters into the url
     */
    updateRouteForQuery () {
      const query = {
        limit: this.licensesPerPage,
        skip: (this.currentPage - 1) * this.licensesPerPage,
        sort: (this.sortOrder === 'desc' ? '-' : '') + this.sortField
      }
      if (this.filters.length > 0) {
        query.filter = FilterHelper.encodeForURL(this.filters)
      }
      this.$router.push({
        name: 'licenses',
        query
      })
    },

    /**
     * Handle changes to the current filter
     * Will update the route and fetch with the changed query
     */
    onFilterChange (filters) {
      this.filters = filters
      this.updateRouteForQuery()
    },

    /**
     * Handle page changes
     * Will update the route and fetch with the changed query
     */
    onPageChange (page) {
      this.currentPage = page
      this.updateRouteForQuery() // Update route, will fetch automatically
    },

    /**
     * Handle sorting changes
     * Will update the route and fetch with the changed query
     */
    onSort (field, order) {
      this.sortField = field
      this.sortOrder = order
      this.updateRouteForQuery() // Update route, will fetch automatically
    },

    /**
     * Handle change of displayed licenses per page
     * Will update the route and fetch with the changed query
     */
    onLicensesPerPageChange (licensesPerPage) {
      this.licensesPerPage = licensesPerPage
      this.updateRouteForQuery() // Update route, will fetch automatically
    },

    /**
     * Select multiple licenses in selection mode
     */
    selectLicenses (licenses) {
      this.selectedLicenses = licenses
      this.isEditingMultiple = true
    },

    /**
     * Launch create new license modal
     **/
    createNewLicenseClicked () {
      this.$buefy.modal.open({
        parent: this,
        component: LicenseCreateForm,
        canCancel: false,
        events: {
          'generated-licenses': (licenses) => {
            this.licenses.push(...licenses)
          },
          'finished-with-licenses': (licenses) => {
            this.selectLicenses(licenses)
          }
        }
      })
    },

    /**
     * Handle an updated license by the preview
     */
    licenseUpdated (original, license) {
      const index = this.licenses.findIndex(l => l._id === license._id)
      if (index === -1) {
        console.error('Error updating license: Not found')
      } else {
        this.licenses.splice(index, 1, license)
      }
    },

    assignGroupSelected () {

    },

    /**
     * Download the selected starter pdfs as a multi-paged pdf file
     */
    async downloadStarterPDFSelected () {
      const licenseIDs = this.selectedLicenses.map(l => l._id)
      this.isMultipleActionLoading = true
      try {
        const response = await BatchDownloadAPI.getStarterDocumentsPDF(licenseIDs)
        this.isMultipleActionLoading = false
        BlobHelper.downloadResponseBlob(response, 'application/pdf', 'METACOM_Startdokumente.pdf')
      } catch (error) {
        console.error(error)
        this.isMultipleActionLoading = false
        this.$buefy.toast.open({
          message: 'Fehler: ' + error,
          type: 'is-danger'
        })
      }
    },

    /**
     * Download the selected starter pdfs as a zip file
     */
    async downloadStarterZIPSelected () {
      const licenseIDs = this.selectedLicenses.map(l => l._id)
      this.isMultipleActionLoading = true
      try {
        const response = await BatchDownloadAPI.getStarterDocumentsZIP(licenseIDs)
        this.isMultipleActionLoading = false
        BlobHelper.downloadResponseBlob(response, 'application/zip', 'METACOM_Startdokumente.zip')
      } catch (error) {
        console.error(error)
        this.isMultipleActionLoading = false
        this.$buefy.toast.open({
          message: 'Fehler: ' + error,
          type: 'is-danger'
        })
      }
    },

    /**
     * Download the selected license pdfs as a multi-paged pdf
     */
    async downloadLicensePDFSelected () {
      const licenseIDs = this.selectedLicenses.map(l => l._id)
      this.isMultipleActionLoading = true
      try {
        const response = await BatchDownloadAPI.getLicenseDocumentsPDF(licenseIDs)
        this.isMultipleActionLoading = false
        BlobHelper.downloadResponseBlob(response, 'application/pdf', 'METACOM_Startdokumente.pdf')
      } catch (error) {
        console.error(error)
        this.isMultipleActionLoading = false
        this.$buefy.toast.open({
          message: 'Fehler: ' + error,
          type: 'is-danger'
        })
      }
    },

    /**
     * Download the selected license pdfs as a zip file
     */
    async downloadLicenseZIPSelected () {
      const licenseIDs = this.selectedLicenses.map(l => l._id)
      this.isMultipleActionLoading = true
      try {
        const response = await BatchDownloadAPI.getLicenseDocumentsZIP(licenseIDs)
        this.isMultipleActionLoading = false
        BlobHelper.downloadResponseBlob(response, 'application/zip', 'METACOM_Startdokumente.zip')
      } catch (error) {
        console.error(error)
        this.isMultipleActionLoading = false
        this.$buefy.toast.open({
          message: 'Fehler: ' + error,
          type: 'is-danger'
        })
      }
    },

    /**
     * Download the starter document of a license
     */
    async downloadStarterPDF (license) {
      this.licenseActionsLoading.push(license._id)
      try {
        const { url } = (await LicenseAPI.getStarterDocumentURLForLicense(license._id)).data.data
        this.licenseActionsLoading = this.licenseActionsLoading.filter(id => id !== license._id)
        window.open(url)
      } catch (error) {
        console.error(error)
        this.licenseActionsLoading = this.licenseActionsLoading.filter(id => id !== license._id)
        this.$buefy.toast.open({
          message: 'Fehler: ' + error,
          type: 'is-danger'
        })
      }
    },

    /**
     * Download the starter document of a license
     */
    async downloadLicensePDF (license) {
      this.licenseActionsLoading.push(license._id)
      try {
        const { url } = (await LicenseAPI.getLicenseDocumentURLForLicense(license._id)).data.data
        this.licenseActionsLoading = this.licenseActionsLoading.filter(id => id !== license._id)
        window.open(url)
      } catch (error) {
        console.error(error)
        this.licenseActionsLoading = this.licenseActionsLoading.filter(id => id !== license._id)
        this.$buefy.toast.open({
          message: 'Fehler: ' + error,
          type: 'is-danger'
        })
      }
    }

  }
}
</script>

<style lang="scss" scoped>
  @import "@/assets/scss/bulma-variables.scss";
  .identifier {
    font-family: $fira_mono;
    font-weight: 500;
  }

  .json-tab {
    background: $dark;
    color: $white;
    padding: 10px;
  }

  .tab-item.properties {
    table.table {
      margin-top: 1em;
    }
  }

  .field.is-grouped .field.taginput-field {
    flex-shrink: 1;
  }
</style>
