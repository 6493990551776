<template lang="pug">
  span.tag
    span.icon: i.far.fa-file-certificate
    span {{ getFormattedIdentifierOfLicense(license) }}
</template>

<script>

import licenseMixin from '@/mixins/license'
export default {
  mixins: [licenseMixin],
  props: {
    license: {
      type: Object,
      required: true
    }
  }
}
</script>
