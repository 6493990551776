<template lang="pug">
  .hero.is-fullheight.is-primary
    .hero-body
      .container
        .column.is-6.is-offset-3
          .title.has-text-centered
            img(src="@/assets/images/logo/metacom-license-logo.svg" alt="METACOM Lizenzverwaltung" width="316" height="30")
            h3.title Anmeldung

          //-
            DIRECT LOGIN
            Enables direct login via url params
          .box(v-if="directLogin")
            b-loading(:active="isLoading", :is-fullscreen="false")
            .content: h4 Direkt-Login
            b-field(label="Lizenzschlüssel")
              b-input(disabled, v-model="directLogin.key")
            b-field(label="Authentifizierungscode")
              b-input(disabled, v-model="directLogin.authenticationCode")
            error-message(title="Anmeldung nicht möglich: ", :error="loginError", :allowRetry="loginError && loginError.allowRetry === true", @retry="loginDirectly")
            button.button.is-primary.is-fullwidth(@click="switchToManualLogin()") Login-Daten manuell eingeben
            //- License help links
            license-help-links

          .box(v-else)
            p.mb-4 Bitte geben Sie Ihren Lizenz-Schlüssel ein, um sich anzumelden und METACOM herunterzuladen.
            simple-login(@authenticated="onLicenseAuthenticated")

            .columns.mt-5
              .column
                  p.is-size-7.has-text-weight-bold.py-1 Lizenzdokument verlegt?
                  b-button(size="is-small"  icon-left="envelope" expanded tag="router-link" :to="{ name: 'license-recovery' }" target="_blank") Lizenzdokument erneut zusenden
              .column
                div
                  p.is-size-7.has-text-weight-bold.py-1 Hilfe benötigt?
                  b-button(size="is-small" icon-left="book" expanded tag="a" href="https://metacom.support" target="_blank") METACOM Fragen & Antworten

        //- FOOTER
        .column.is-6.is-offset-3
          footer-links

</template>
<script>
import FooterLinks from '@/components/general/FooterLinks'
import LicenseHelpLinks from '@/components/customer/LicenseHelpLinks'
import Cleave from 'cleave.js'
import ErrorMessage from '@/components/general/ErrorMessage'
import SimpleLogin from '@/components/customer/SimpleLogin'
/**
 * We add a new instance of Cleave when the element
 * is bound or updated, and destroy it when it's unbound.
 */
const cleave = {
  name: 'cleave',
  bind (el, binding) {
    const input = el.querySelector('input')
    input._vCleave = new Cleave(input, binding.value)
  },
  update (el, binding) {
    const input = el.querySelector('input')
    input._vCleave.destroy()
    input._vCleave = new Cleave(input, binding.value)
  },
  unbind (el) {
    const input = el.querySelector('input')
    input._vCleave.destroy()
  }
}

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  directives: { cleave },
  components: { FooterLinks, ErrorMessage, LicenseHelpLinks, SimpleLogin },
  data () {
    return {
      input: {
        key: '',
        startCode: '',
        holder: ''
      },
      isLoading: false,
      loginError: null,
      directLogin: null,
      masks: {
        licenseKey: {
          delimiter: '-',
          blocks: [4, 4, 4],
          uppercase: true
        },
        startCode: {
          delimiter: ' ',
          blocks: [3, 3],
          numericOnly: true
        }
      }
    }
  },
  /**
   * Add direct login url support
   */
  created () {
    if (this.$route.query.key && this.$route.query.authenticationCode) {
      // Direct login requested
      this.directLogin = {
        key: this.$route.query.key,
        authenticationCode: this.$route.query.authenticationCode
      }
      this.loginDirectly()
    }
  },
  methods: {
    /**
     * Validate the form and submit it
     */
    validateForm: function (scope) {
      this.$validator.validateAll(scope).then(isFormValid => {
        if (isFormValid) {
          if (scope === 'login-bound') {
            this.loginBound()
          } else if (scope === 'login-unbound') {
            this.loginUnbound()
          }
        }
      })
    },

    /**
     * Login with the unbound license data
     */
    loginUnbound: function () {
      const key = this.input.key.replace(/-/g, '').replace(/\s/g, '')
      const startCode = this.input.startCode.replace(/-/g, '').replace(/\s/g, '')

      this.isLoading = true
      this.loginError = null

      this.$store.dispatch('auth/login', {
        type: 'license',
        data: {
          key,
          authenticationCode: startCode
        }
      })
        .then(() => {
          this.isLoading = false
          this.$router.push('/')
        })
        .catch(error => {
          console.error(error)
          this.isLoading = false
          // Replace error message
          error.originalMessage = error.message
          if (!error.response) {
            error.message = 'Es konnte keine Verbindung hergestellt werden. Bitte versuchen Sie es später erneut.'
            error.printOriginalMessage = true
            error.allowRetry = true
          } else if (error.response.status === 401) {
            error.message = 'Die eingegebenen Login-Daten waren ungültig. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.'
          } else if (error.response.status === 410) {
            error.message = 'Die eingegebene Lizenz wurde deaktiviert. Wenn Sie glauben, dass es sich dabei um einen Fehler handelt, kontaktieren Sie uns bitte unter support@metacom-symbole.de.'
          } else if (error.response.status === 423) {
            error.message = 'Die eingegebene Lizenz ist bereits gebunden. Wählen Sie „Gebundene Lizenz” und versuchen Sie es erneut.'
          } else if (error.response.status >= 500) {
            error.message = 'Ein Serverfehler ist aufgetreten. Bitte versuchen Sie es später erneut.'
          } else {
            error.message = 'Ein unbekannter Fehler ist aufgetreten. Bitte überprüfen Sie Ihre eingaben und probieren Sie es erneut.'
          }
          this.loginError = error
        })
    },

    onLicenseAuthenticated () {
      // this.$store.commit('auth/setLoggedIn', { token: authToken, userData: license })
      this.$router.push('/')
    },
    /**
     * Login with the bound license data
     */
    loginBound: function () {
      const key = this.input.key.replace(/-/g, '').replace(/\s/g, '')
      const holder = this.input.holder.trim()

      this.isLoading = true
      this.loginError = null

      this.$store.dispatch('auth/login', {
        type: 'license',
        data: {
          key,
          authenticationCode: holder
        }
      })
        .then(() => {
          this.isLoading = false
          this.$router.push('/')
        })
        .catch(error => {
          console.error(error)
          this.isLoading = false
          error.originalMessage = error.message
          if (!error.response) {
            error.message = 'Es konnte keine Verbindung hergestellt werden. Bitte versuchen Sie es später erneut.'
            error.printOriginalMessage = true
            error.allowRetry = true
          } else if (error.response.status === 401) {
            error.message = 'Die eingegebenen Login-Daten waren ungültig. Bitte überprüfen Sie Ihre Eingaben und versuchen Sie es erneut.'
          } else if (error.response.status === 410) {
            error.message = 'Die eingegebene Lizenz wurde deaktiviert. Wenn Sie glauben, dass es sich dabei um einen Fehler handelt, kontaktieren Sie uns bitte unter support@metacom-symbole.de.'
          } else if (error.response.status === 423) {
            error.message = 'Die eingegebene Lizenz ist bereits gebunden. Geben Sie den Namen des Lizenznehmers anstatt des Startcodes ein. Alternativ wählen Sie „Neue Lizenz” zur Anmeldung.'
          } else if (error.response.status >= 500) {
            error.message = 'Ein Serverfehler ist aufgetreten. Bitte versuchen Sie es später erneut.'
          } else {
            error.message = 'Ein unbekannter Fehler ist aufgetreten. Bitte überprüfen Sie Ihre eingaben und probieren Sie es erneut.'
          }
          this.loginError = error
        })
    },

    /**
     * Perform direct login
     */
    loginDirectly: async function () {
      this.isLoading = true
      try {
        await this.$store.dispatch('auth/login', {
          type: 'license',
          data: this.directLogin
        })
        this.isLoading = false
        this.$router.push('/')
      } catch (error) {
        console.error(error)
        this.isLoading = false
        error.originalMessage = error.message
        if (!error.response) {
          error.message = 'Es konnte keine Verbindung hergestellt werden. Bitte versuchen Sie es später erneut.'
          error.printOriginalMessage = true
          error.allowRetry = true
        } else if (error.response.status === 423) {
          error.message = 'Sie haben Ihre Lizenz bereits registriert und anschließend ein aktualisiertes Lizenz-PDF erhalten. Das PDF enthält statt des Startcodes den selbst gewählten Lizenznehmer-Namen. Bitte verwenden Sie nur noch den Link und die Login-Daten aus diesem neueren PDF. Sie können auch auf „Login-Daten manuell eingeben” klicken und die Daten Ihrer Lizenz im Reiter „Gebundene Lizenz” eingeben.'
        } else if (error.response.status === 410) {
          error.message = 'Diese Lizenz wurde deaktiviert. Wenn Sie glauben, dass es sich dabei um einen Fehler handelt, kontaktieren Sie uns bitte unter support@metacom-symbole.de.'
        } else if (error.response.status >= 400 && error.response.status < 500) {
          error.message = 'Dieser Link zum direkten Login ist nicht gültig. Bitte vergewissern Sie sich, dass Sie den Link Ihres aktuellsten Lizenz-PDFs geklickt, bzw. den kompletten Link korrekt kopiert haben. Sie können auch auf „Login-Daten manuell eingeben” klicken und sich mit Ihrem Lizenzschlüssel und dem Startcode bzw. dem Namen des Lizenznehmers anmelden.'
        } else if (error.response.status >= 500) {
          error.message = 'Ein Serverfehler ist aufgetreten. Bitte versuchen Sie es später erneut.'
        } else {
          error.message = 'Ein unbekannter Fehler ist aufgetreten. Bitte überprüfen Sie Ihre eingaben und probieren Sie es erneut.'
        }
        this.loginError = error
      }
    },

    switchToManualLogin: function () {
      this.$router.push('/login')
      this.directLogin = null
      this.loginError = null
    }
  }
}
</script>
<style lang="scss" scoped>
@import '@/assets/scss/bulma-variables.scss';
form {
  padding-top: 40px;
  padding-bottom: 40px;

  .field {
    margin-bottom: 0;
  }

  button.submit {
    margin-top: 1em;
  }
  p.info {
    padding-top: 40px;
    padding-bottom: 20px;
  }
}
.hero {
  background: $primary;
}
.help-item {
  width: 350px;
  clear:both;
  overflow: hidden;
  p {
    padding-top: 10px;
  }

  img {
    width: 180px;
    float: left;
  }
}
.license-help-links {
  margin-top: 1em;
}
</style>
