<template lang="pug">
#license-layout
  navigation-bar
  router-view
  footer-bar
</template>

<script>
import NavigationBar from '@/components/general/NavigationBar'
import FooterBar from '@/components/general/FooterBar'
export default {
  name: 'LicenseLayout',
  components: {
    NavigationBar,
    FooterBar
  }
}
</script>
