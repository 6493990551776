<template lang="pug">
  widget-panel(:title="title", icon="fa-user")
    template(slot="actions")
    template(slot="content")
      table.table
        tbody
          tr
            th Art des Lizenznehmers
            td(v-if="!editing") {{ holderType == null ? '-' : holderType === 'person' ? 'Person' : 'Organisation'}}
            td(v-if="editing")
              .field(
                :class="{'is-danger': errors.has('holderType')}"
              )
                .control
                  b-select(
                    :value="holderType"
                    @input="holderTypeChanged($event)"
                    name="holderType"
                    data-vv-as="Art des Lizenznehmers"
                    :v-validate="isLicenseHolderRequired ? 'required' : ''"
                    expanded
                  )
                    option(value="", selected) -
                    option(value="person", selected) Person
                    option(value="organization", selected) Organisation
                p.help.is-danger(v-show="errors.has('holderType')") {{ errors.first('holderType') }}
          tr
            th Name
            td(v-if="!editing") {{ name || '-'}}
            td(v-if="editing")
              .field(
                :class="{'is-danger': errors.has('licenseHolderName')}"
              )
                .control
                  b-input(
                    :value="name"
                    @input="nameChanged($event)"
                    v-validate="textFieldRule"
                    name="licenseHolderName"
                    data-vv-as="Lizenznehmer Name"
                    :class="{'is-danger': errors.has('licenseHolderName')}"
                  )
                p.help.is-danger(v-show="errors.has('licenseHolderName')") {{ errors.first('licenseHolderName') }}

          tr
            th E-Mail
            td(v-if="!editing") {{ email || '-'}}
            td(v-if="editing")
              .field(
                :class="{'is-danger': errors.has('licenseHolderEmail')}"
              )
              .control
                b-input(
                  :value="email"
                  @input="emailChanged($event)"
                  v-validate="emailFieldRule",
                  name="licenseHolderEmail",
                  data-vv-as="Lizenznehmer E-Mail",
                  :class="{'is-danger': errors.has('licenseHolderEmail')}"
                )
              p.help.is-danger(v-show="errors.has('licenseHolderEmail')") {{ errors.first('licenseHolderEmail') }}
          tr
            th Kontakt
            td(v-if="!editing") {{ contact || '-'}}
            td(v-if="editing")
              b-field( message="Nur bei Unternehmen-Lizenznehmern")
                b-input(
                  :value="contact",
                  @input="contactChanged($event)"
                  name="licenseHolderContact",
                )
          tr(v-if="boundAt")
            th Gebunden am
            td
              .tags
                span
                  span.icon: i.far.fa-calendar-alt
                  span {{ boundAt | moment("DD.MM.YY") }}

                span
                  span.icon:  i.far.fa-clock
                  span {{ boundAt | moment("HH:mm:ss") }}
            td(v-if="!boundAt") -
</template>

<script>
import WidgetPanel from '@/components/general/WidgetPanel'
import licenseMixin from '@/mixins/license'

export default {
  components: { WidgetPanel },
  mixins: [licenseMixin],
  inject: ['$validator'],
  props: {
    title: {
      type: String,
      default: 'Lizenznehmer'
    },
    // License properties
    holderType: {
      type: String,
      required: false,
      default: undefined
    },
    name: {
      type: String,
      required: false,
      default: undefined
    },
    email: {
      type: String,
      required: false,
      default: undefined
    },
    contact: {
      type: String,
      required: false,
      default: undefined
    },
    boundAt: {
      type: String,
      required: false,
      default: undefined
    },
    status: {
      type: String,
      required: false,
      default: null
    },
    editing: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     * Provide the validation rule for the text field
     */
    textFieldRule: function () {
      return this.isLicenseHolderRequired ? 'required' : ''
    },

    /**
     * Provide the validation rule for the email field
     */
    emailFieldRule: function () {
      return this.isLicenseHolderRequired ? 'email|required' : 'email'
    },

    /**
     * Check if a license holder is required
     */
    isLicenseHolderRequired: function () {
      return this.status === 'bound'
    }
  },
  methods: {
    /**
     * Handle holder type change
     * @param {*} event - new holder type changed
     */
    holderTypeChanged: function (event) {
      this.$emit('update:holderType', event)
    },

    /**
     * Handle name change
     * @param {String} event - new name value
     */
    nameChanged: function (event) {
      this.$emit('update:name', event)
    },

    /**
     * Handle email change
     * @param {String} event - new email value
     */
    emailChanged (event) {
      this.$emit('update:email', event)
    },

    /**
     * Handle contact change
     * @param {String} event - new contact value
     */
    contactChanged (event) {
      this.$emit('update:contact', event)
    },

    /**
     * Validate this instance
     */
    validate () {
      return this.$validator.validateAll()
    }
  }
}
</script>
