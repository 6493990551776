<template lang="pug">
  span.tag
    span.icon: i.far.fa-lock
    span {{ name | truncate(50, '..') }}

</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    },
    contact: {
      type: String,
      default: undefined
    }
  }
}
</script>
