<template lang="pug">
  span.tag
    span.icon: i.far.fa-user
    span {{ username }}

</template>

<script>
export default {
  props: {
    username: {
      type: String,
      required: true
    }
  }
}
</script>
