export default {
  // Available operators
  operators: [
    { key: 'eq', name: '=' },
    { key: 'neq', name: '!=' },
    { key: 'lt', name: '<', onlyFreeText: true },
    { key: 'gt', name: '>', onlyFreeText: true },
    { key: 'lte', name: '<=', onlyFreeText: true },
    { key: 'gte', name: '>=', onlyFreeText: true },
    { key: 'in', name: 'in', multiple: true },
    { key: 'nin', name: 'nicht in', multiple: true },
    { key: 'exists', name: 'existiert', single: true },
    { key: 'existsnot', name: '!existiert', single: true },
    { key: 'regex', name: 'regex', onlyFreeText: true },
    { key: 'regexnot', name: '!regex', onlyFreeText: true },
    { key: 'contains', name: 'enthält', onlyFreeText: true },
    { key: 'containsnot', name: 'enthält nicht', onlyFreeText: true },
    { key: 'startswith', name: 'beginnt mit', onlyFreeText: true },
    { key: 'startswithnot', name: 'beginnt nicht mit', onlyFreeText: true },
    { key: 'endswith', name: 'endet auf', onlyFreeText: true },
    { key: 'endswithnot', name: 'endet nicht auf', onlyFreeText: true }
  ],

  isOperatorMultiple (operatorKey) {
    return this.operators.find(o => o.key === operatorKey).multiple
  },

  licenseFields: [
    { key: 'entryReference', name: 'Bestellnummer' },
    {
      key: 'status',
      name: 'Status',
      options: [
        { key: 'bound', name: 'Gebunden' },
        { key: 'unbound', name: 'Ungebunden' },
        { key: 'disabled', name: 'Deaktiviert' }
      ]
    },
    { key: 'boundAt', name: 'Gebunden am' },
    { key: 'holder.name', name: 'Name des Lizenznehmers' },
    { key: 'holder.email', name: 'E-Mail des Lizenznehmers' },
    { key: 'holder.contact', name: 'Kontakt des Lizenznehmers' },
    {
      key: 'typeOfEntry',
      name: 'Erzeugungsart',
      options: [
        { key: 'manual', name: 'Manuell' },
        { key: 'order', name: 'Bestellung' }
      ]
    },
    { key: 'version', name: 'METACOM Version' },
    { key: 'properties.size', name: 'Größe' },
    {
      key: 'properties.class',
      name: 'Klasse',
      options: [
        { key: 'single', name: 'Einzellizenz' },
        { key: 'multiple', name: 'Mehrfachlizenz' },
        { key: 'server', name: 'Serverlizenz' }
      ]
    },
    {
      key: 'properties.type',
      name: 'Typ',
      options: [
        { key: 'update', name: 'Update' },
        { key: 'full', name: 'Vollversion' }
      ]
    },
    {
      key: 'properties.delivery',
      name: 'Auslieferung',
      options: [
        { key: 'dvd', name: 'DVD' },
        { key: 'download', name: 'Download' }
      ]
    },
    { key: 'key', name: 'Lizenzschlüssel' },
    { key: 'notes', name: 'Notiz' },
    { key: 'startDocument', name: 'Startdokument' },
    { key: 'licenseDocument', name: 'Lizenzdokument' },
    { key: 'downloads.metacom', name: 'METACOM-Downloads' },
    { key: 'downloads.metasearch', name: 'MetaSearch-Downloads' },
    { key: 'productSKU', name: 'Lizenzprodukt SKU' },
    { key: 'extensionSKU', name: 'Erweiterungsprodukt SKU' },
    {
      key: 'specialPermissions',
      name: 'Besondere Berechtigungen',
      options: [
        { key: 'download', name: 'METACOM herunterladen' },
        { key: 'metasearch-server-info', name: 'Serverinfos anzeigen' },
        { key: 'metasearch-multistep-installer', name: 'MS Multistep herunterladen' },
        { key: 'metasearch-oneclick-installer', name: 'MS Oneclick herunterladen' }
      ]
    },
    { key: 'entryReference', name: 'Erzeugt durch Account' },
    { key: 'createdAt', name: 'Datum' },
    { key: '_id', name: 'ID' }
  ],

  orderGenerationFields: [
    { key: 'order', name: 'Bestellnummer' },
    { key: 'licenses', name: 'Lizenzen' },
    { key: 'createdAt', name: 'Datum' },
    {
      key: 'progress',
      name: 'Status',
      options: [
        { key: 'in-progress', name: 'In Bearbeitung' },
        { key: 'completed', name: 'Erfolgreich' },
        { key: 'failed', name: 'Fehlgeschlagen' }
      ]
    },
    { key: 'products.name', name: 'Produktname' },
    { key: 'products.sku', name: 'Produkt SKU' },
    { key: 'products.productId', name: 'Produkt ID' },
    { key: 'products.variationId', name: 'Variations ID' },
    { key: 'products.quantity', name: 'Produktanzahl' },
    { key: '_id', name: 'ID' }
  ],

  upgradeSessionFields: [
    { key: 'createdAt', name: 'Datum' },
    {
      key: 'progress',
      name: 'Status',
      options: [
        { key: 'created', name: 'Neu erstellt' },
        { key: 'offered', name: 'Angebot erstellt' },
        { key: 'purchased', name: 'Gekauft' },
        { key: 'in-progress', name: 'Lizenz in Bearbeitung' },
        { key: 'completed', name: 'Abgeschlossen' },
        { key: 'failed', name: 'Fehlgeschlagen' },
        { key: 'expired', name: 'Abgelaufen' }
      ]
    },
    { key: '_id', name: 'ID' }
  ],

  deliveryFields: [
    { key: 'recipient', name: 'Empfänger' },
    { key: 'documents', name: 'Dokumente' },
    { key: 'licenses', name: 'Lizenzen' },
    { key: 'documentType', name: 'Dokumententyp' },
    {
      key: 'occasion',
      name: 'Anlass',
      options: [
        { key: 'order', name: 'Shop-Generierung' },
        { key: 'manual', name: 'Manuell' },
        { key: 'bind', name: 'Registrierung' },
        { key: 'update', name: 'Update' }
      ]
    },
    {
      key: 'progress',
      name: 'Status',
      options: [
        { key: 'in-progress', name: 'In Bearbeitung' },
        { key: 'completed', name: 'Erfolgreich' },
        { key: 'failed', name: 'Fehlgeschlagen' }
      ]
    },
    { key: 'createdAt', name: 'Datum' },
    { key: '_id', name: 'ID' },
    { key: 'content', name: 'Nachricht' },
    { key: 'subject', name: 'Betreff' },
    { key: 'recipientName', name: 'Name des Empfängers' }
  ],

  downloadFields: [
    { key: 'object', name: 'Objekt' },
    { key: 'version', name: 'Version' },
    { key: 'createdAt', name: 'Datum' },
    { key: 'license', name: 'Lizenz' },
    { key: '_id', name: 'ID' }
  ],

  /**
   * Encode a filter to a query string
   * @param {Object} filter
   */
  getFilterString (filter) {
    if (filter.value) {
      let value = filter.value
      if (Array.isArray(value)) {
        value = value.join(',')
      }
      return `${filter.field}~${filter.operator}~${filter.value}`
    } else {
      return `${filter.field}~${filter.operator}`
    }
  },

  /**
   * Encode a set of filters to a query string
   * @param {Array<Object>} filters
   */
  encodeForURL (filters) {
    return filters.map(f => this.getFilterString(f)).join('__')
  },

  /**
   * Decode a single filter from a query string
   * @param {String} filterString
   */
  decodeFilterString (filterString) {
    const split = filterString.split('~')
    const filter = {
      field: split[0],
      operator: split[1]
    }
    if (split.length > 2) {
      if (this.isOperatorMultiple(filter.operator)) {
        filter.value = split[2].split(',')
      } else {
        filter.value = split[2]
      }
    }
    return filter
  },

  /**
   * Decode filters from query string
   * @param {String} encoded
   */
  decodeFromURL (encoded) {
    return encoded.split('__').map(f => this.decodeFilterString(f))
  },

  /**
   * Escapes unsafe characters for regex text search
   * @param {String} s string to escape
   */
  escapeRegExpContent (s) {
    return s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
  },

  /**
   * Get the query responding to a filter
   * @param {Object} filter
   */
  getQueryForFilter (filter) {
    switch (filter.operator) {
      case 'eq':
        return `${filter.field}=${filter.value}`
      case 'neq':
        return `${filter.field}!=${filter.value}`
      case 'lt':
        return `${filter.field}<${filter.value}`
      case 'gt':
        return `${filter.field}>${filter.value}`
      case 'lte':
        return `${filter.field}<=${filter.value}`
      case 'gte':
        return `${filter.field}>=${filter.value}`
      case 'in':
        return `${filter.field}=${filter.value.join(',')}`
      case 'nin':
        return `${filter.field}!=${filter.value.join(',')}`
      case 'exists':
        return `${filter.field}`
      case 'existsnot':
        return `!${filter.field}`
      case 'regex':
        return `${filter.field}=${filter.value}`
      case 'regexnot':
        return `${filter.field}!=${filter.value}`
      case 'contains':
        return `${filter.field}=/${this.escapeRegExpContent(filter.value)}/i`
      case 'containsnot':
        return `${filter.field}!=/${this.escapeRegExpContent(filter.value)}/i`
      case 'startswith':
        return `${filter.field}=/^${this.escapeRegExpContent(filter.value)}/i`
      case 'startswithnot':
        return `${filter.field}!=/^${this.escapeRegExpContent(filter.value)}/i`
      case 'endswith':
        return `${filter.field}=/${this.escapeRegExpContent(filter.value)}$/i`
      case 'endswithnot':
        return `${filter.field}!=/${this.escapeRegExpContent(filter.value)}$/i`
    }
  }
}
