<template>
  <div class="media">
    <figure class="media-left image is-32x32">
      <img
        v-if="license"
        :src="getLicenseImageOfLicense(license)"
      >
    </figure>
    <div class="media-content">
      <h3 v-if="license">
        {{ getShortFormattedNameOfLicense(license) }}
      </h3>
      <small v-if="license"><code> {{ getFormattedKeyOfLicense(license) }}</code></small>
    </div>
  </div>
</template>
<script>

import license from '@/mixins/license'
export default {
  mixins: [license],
  props: {
    license: {
      type: Object,
      default: null
    }
  }
}
</script>
