<template lang="pug">
  .modal-card
    .modal-card-head
      .modal-card-title {{ getLicenseVersionName(license) }} herunterladen
      button.delete(aria-label="close", @click="$parent.close()")
    section.modal-card-body
      b-tabs(type="is-boxed", v-model="activeTab")
        b-tab-item(label="Herunterladen")
          error-message(
            title="Fehler beim Abrufen des Download-Links:"
            :error="requestError"
            :allowRetry="false"
          )
          .content
            :markdown-it
              Klicken Sie auf den Button **Download-Link erzeugen** und anschließend auf **Jetzt herunterladen**, um den Download von METACOM zu starten.

            div(v-if="canCopyDownloadLink(license)")
              ul
                li: a(href="https://www.metacom-symbole.de/webdok/Handlungsempfehlung-Link.pdf" target="_blank") Handlungsempfehlung zur Verteilung mehrerer Lizenzplätze in Einrichtungen, Schulen u. ä. (PDF)
          .buttons
            button.button.is-primary(
              @click="getDownloadURL()"
              :disabled="isLoading"
              :class="{'is-loading': isLoading}"
              v-if="!metacomDownloadURL"
            )
              span.icon.is-small: i.far.fa-link
              span Download-Link erzeugen

            //- Once the download link is requested
            transition(
              enter-active-class="animated bounce"
            )
              div(v-if="metacomDownloadURL")
                a.button.is-primary(

                  :href="metacomDownloadURL"
                  target="_blank"
                  @click="downloadButtonClicked()"
                )
                  span.icon.is-small: i.far.fa-cloud-download
                  span Jetzt herunterladen

                a.button(
                  v-if="canCopyDownloadLink(license)"
                  v-clipboard:copy="metacomDownloadURL"
                  v-clipboard:success="handleCopySuccess"
                  v-clipboard:error="handleCopyError"
                )
                  span.icon.is-small: i.far.fa-copy
                  span Download-Link kopieren

          b-field.mt-4(label="Download-Link" v-if="hasCopyError")
            pre {{  metacomDownloadURL  }}

          br
          br
          .content(v-if="license.version === '8'")
            :markdown-it(breaks="true" )
              Der Download enthält:
              - Alle METACOM Symbole
              - MetaSearch für **Windows und Mac**
              - METACOM Layoutvorlagen
              - Handbuch zur Verwendung der Symbole und Benutzung von MetaSearch
          .content(v-else-if="license.version === '9'")
            :markdown-it(breaks="true")
              Der Download enthält:
              - Alle METACOM Symbole
              - MetaSearch für **Windows und Mac und Linux**
              - Handbuch zur Verwendung der Symbole und Benutzung von MetaSearch
              - Installationsanleitung
            p Sie laden einen komprimierten Ordner mit der Dateiendung .zip (ZIP-Datei) herunter. Die ZIP-Datei muss zunächst entpackt werden. In dem entpackten Ordner finden Sie die Installationsanleitung.
          br
          b-tabs(v-if="license.version === '8'")
            b-tab-item(label="Hinweis für Windows")
              p Möglicherweise müssen Sie im erscheinenden Dialog-Fenster noch auf #[strong Speichern] klicken, um den Download zu starten
              figure
                img(src="@/assets/images/metacom-download/metacom-download-save.png")
          b-button(v-if="license.version === '9'" type="is-secondary" icon-left="book" tag="a" href="https://metacom.support" target="_blank") METACOM Fragen & Antworten
          br
          p: small Der Download der METACOM Symbolsammlung ist {{ license.version === '8' ? '1,6 GB' : '1,97 GB' }} groß. Der Downloadvorgang kann daher bei langsamen Internetgeschwindigkeiten eine Stunde oder länger dauern.
          br
          br
          button.button(@click="activeTab = 1" v-if="license.version === '8'")
            span Nach dem Herunterladen müssen Sie METACOM_8 noch entpacken
            span.icon: i.far.fa-chevron-right
        b-tab-item(label="Entpacken" v-if="license.version === '8'")
          .content
            :markdown-it
              Ist der Download abgeschlossen, sollten Sie eine 1,6 GB große Datei mit dem Namen *METACOM_8.zip* in Ihrem Download-Ordner finden. Dabei handelt es sich um ein sogenanntes ZIP-Archiv, das nun entpackt werden muss.
            b-tabs
              b-tab-item(label="Hinweis für Windows")
                //- Open ZIP
                :markdown-it
                  Öffnen sie die heruntergeladene Datei *METACOM_8.zip*. Klicken Sie dazu im Dialog-Fenster auf **Öffnen** oder öffnen Sie den Download-Ordner und führen einen Doppelklick auf die Datei *METACOM_8.zip* aus.
                figure
                  img(src="@/assets/images/metacom-download/metacom-download-open.png")

                //- Extract ZIP via drag & drop
                :markdown-it
                  Ziehen Sie dann den Ordner *METACOM_8* auf den Desktop/Schreibtisch
                figure
                  img(src="@/assets/images/metacom-download/metacom-download-extract-drag-drop.png")

                //- Extraction alternative over menu
                b-collapse(
                  :open.sync="showExtractionAlternative"
                  class="card"
                )
                  template(v-slot:trigger="props")
                    .card-header()
                      p.card-header-title(style="margin-bottom: 0") Alternativ können Sie das Menü „Extrahieren” benutzen.
                      a.card-header-icon
                        b-icon(back="far" :icon="props.open ? 'caret-down' : 'caret-up'")
                  .card-content
                    //- Extract via menu
                    :markdown-it(breaks="true")
                      Wählen Sie dazu zuerst in der oberen Menüleiste den Tab **Extrahieren**.
                      Klicken Sie im Anschluss auf **Alle extrahieren**.
                    figure
                      img(src="@/assets/images/metacom-download/metacom-download-extract-archive-1.png")

                    //- Select extraction location
                    :markdown-it(breaks="true")
                      Nun können Sie über **Durchsuchen** den Ort ändern, an dem die Symbolsammlung entpackt wird und den Vorgang mit **Extrahieren** starten.
                    figure
                      img(src="@/assets/images/metacom-download/metacom-download-extract-archive-2.png")
                br
                br
                :markdown-it
                  Warten Sie, bis der Vorgang abgeschlossen ist. Im Anschluss finden Sie den entpackten Ordner **METACOM_8** am gewählten Ort.
                figure
                  img(src="@/assets/images/metacom-download/metacom-download-wait-extract.png")
              b-tab-item(label="Hinweis für Mac")
                :markdown-it(breaks="true")
                  - Öffnen Sie den **Downloads** Ordner.
                  - Führen Sie einen Doppelklick auf die Datei *METACOM_8.zip* aus.
                  - Ziehen Sie den entpackten Ordner **METACOM_8** an einen beliebigen Ort auf Ihrem Computer (z.B. den Schreibtisch).
            button.button(@click="activeTab = 2")
              span Fahren Sie mit der Installation fort
              span.icon: i.far.fa-chevron-right
        b-tab-item(label="Installieren" v-if="license.version === '8'")
          .content
            :markdown-it(breaks="true")
              Der entpackte *METACOM_8* Ordner enthält alle Symboldateien im Unterordner *METACOM_Symbole*. Dieser kann an einen beliebigen Ort auf Ihrem Computer verschoben werden. Sie können ihn auch im Ordner *METACOM_8* belassen.

              Die METACOM Symbolsammlung besteht aus über 10.000 Symbolen. **MetaSearch** ist ein Programm, das eine Stichwortsuche und Bearbeitungsfunktionen für die Symbolsammlung bereitstellt.
          b-tabs
            b-tab-item(label="Hinweis für Windows")
              .content
                :markdown-it
                  Führen Sie das Installationsprogramm aus, indem Sie **MetaSearch.exe** doppelklicken. MetaSearch startet nach der Installation automatisch.
            b-tab-item(label="Hinweis für Mac")
              .content
                :markdown-it
                  Doppelklicken Sie **MetaSearch.zip** und verschieben Sie die App *MetaSearch* in den *Programme* Ordner auf Ihrem Mac. Starten Sie MetaSearch.
          .content
            :markdown-it(breaks="true")
              Folgen Sie der Aufforderung, den  Ordner *METACOM_Symbole* in das MetaSearch Fenster zu ziehen.
              Hinter dem Fragezeichen unten rechts im Fenster finden Sie eine Videoanleitung.
              Alternativ können Sie in das Fenster klicken und den Ordner zuweisen.

              Nach einer kurzen Ladezeit ist MetaSearch einsatzbereit.

              Detaillierte Infos zur Benutzung von MetaSearch finden Sie im **Handbuch**, dass sich als PDF-Datei ebenfalls im *METACOM_8* Ordner befindet.
    footer.modal-card-foot
</template>

<script>
import ErrorMessage from '@/components/general/ErrorMessage'
import LicenseAPI from '@/services/api/License'
import license from '@/mixins/license'
export default {
  components: {
    ErrorMessage
  },
  mixins: [license],
  data () {
    return {
      isLoading: false,
      requestError: null,
      license: this.$store.getters['auth/userData'],
      metacomDownloadURL: null,
      activeTab: 0,
      showExtractionAlternative: false,
      hasCopyError: false
    }
  },
  methods: {
    downloadButtonClicked () {
      this.activeTab = 1
    },
    handleCopyError () {
      this.$buefy.toast.open({ type: 'is-danger', message: 'Fehler beim Kopieren des Download-Links' })
      this.hasCopyError = true
    },
    handleCopySuccess () {
      this.$buefy.toast.open({ type: 'is-success', message: 'Download-Link in Zwischenablage kopiert' })
    },
    /**
     * Get the METACOM Download URL From the API
     */
    async getDownloadURL () {
      try {
        this.isLoading = true
        this.requestError = null
        this.metacomDownloadURL = (await LicenseAPI.getMetacomDownloadURLForLicense(this.license._id)).data.data.url
        this.isLoading = false
      } catch (error) {
        console.error(error)
        this.isLoading = false
        this.metacomDownloadURL = null

        if (error.response.status === 403) {
          error.message = 'Fehlende Berechtigung zum Download von METACOM.'
        } else if (error.response.status >= 500 && error.response.status < 600) {
          error.message = 'Bei der Bearbeitung der Anfrage ist ein internet Fehler aufgetreten. Bitte versuchen Sie es erneut.'
        } else {
          error.message = 'Ein unbekannter Fehler ist aufgetreten: ' + error.message + '. Bitte wenden Sie sich an support@metacom-symbole.de'
        }
        this.requestError = error
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .modal-card {
    width: 100%;
  }
</style>
