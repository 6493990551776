<template lang="pug">
  .container.page
    h1.title Alle Versand-Aktionen
    .content
      p Die Lizenzverwaltung versendet zu bestimmten Anlässen Lizenzen oder Startdokumente per E-Mail. Hier können alle Versand-Aktionen nachvollzogen werden.
      p Anlass für einen Versand können die Shop-Generierung einer Bestellung, die Registrierung/Bindung durch den Kunden oder die manuelle Auslösung durch die Aktion im Shop sein.

    .columns
      .column
        //- Filters
        query-builder(
          :fields="filterFields"
          :filters="filters"
          @update:filter="onFilterChange($event)"
        )
      .column
        b-field(label="Versand-Aktionen pro Seite")
          b-select(
            v-model="deliveriesPerPage"
            @input="onDeliveriesPerPageChange($event)"
          )
            option(:value="20") 20
            option(:value="50") 50
            option(:value="100") 100
            option(:value="Infinity") Alle

    //- Error message
    error-message(
      :error="fetchError"
      :is-loading="isLoading"
      @retry="fetchDeliveries()"
      show-raw
    )

    b-table(
      :data="deliveries"
      :loading="isLoading"

      paginated
      backend-pagination
      :total="totalDeliveries"
      :per-page="deliveriesPerPage"
      @page-change="onPageChange"

      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"

      detailed
      detail-key="_id"
    )
      b-table-column(
        field="licenses"
        label="Lizenzen"
        v-slot="props"
      ) {{ props.row.licenses.length }} Lizenz{{ props.row.licenses.length > 1 ? 'en' : ''}}
      b-table-column(
        field="documentType"
        label="Typ"
        v-slot="props"
      ) {{ getFormattedDocumentTypeOfDelivery(props.row) }}

      b-table-column(
        field="occasion"
        label="Anlass"
        v-slot="props"
      )
        b-tag(:type="getTagTypeForDeliveryOccasion(props.row)") {{ getFormattedOccasionOfDelivery(props.row) }}

      b-table-column(
        field="receiver"
        label="Empfänger"
        v-slot="props"
      ) {{ props.row.recipient }}
      b-table-column(
        field="progress"
        label="Status"
        v-slot="props"
      )
        progress-tag(:progress="props.row.progress")
      b-table-column(
        field="createdAt"
        label="Erzeugt am"
        sortable
        v-slot="props"
      )
        .tags(v-if="props.row.createdAt")
          date-tag(:date="props.row.createdAt")
          time-tag(:date="props.row.createdAt")
      template(v-slot:detail="props")
        router-link.is-pulled-right(
          :to="'/backend/delivery/' + props.row._id"
          target="_blank"
          title="In neuem Tab öffnen (Shift-Klick für neues Fenster)"
        ): span.icon: i.fas.fa-external-link
        .content: h3 Details des Versands {{ props.row._id }}
        delivery-details(
          :delivery="props.row"
          @delivery-added="onDeliveryAdded($event)"
        )

      template(slot="bottom-left")
        b {{ totalDeliveries }} Versand-Aktionen

</template>

<script>
import DeliveryAPI from '@/services/api/Delivery'
import DeliveryMixin from '@/mixins/delivery'

import QueryBuilder from '@/components/admin/query-builder/QueryBuilder'
import ErrorMessage from '@/components/general/ErrorMessage'
import DeliveryDetails from '@/components/admin/delivery/DeliveryDetails'

import OrderTag from '@/components/general/OrderTag'
import ProgressTag from '@/components/general/ProgressTag'
import DateTag from '@/components/general/DateTag'
import TimeTag from '@/components/general/TimeTag'
import FilterHelper from '@/lib/filter-helper'
export default {
  components: {
    QueryBuilder, ErrorMessage, ProgressTag, OrderTag, DateTag, TimeTag, DeliveryDetails
  },
  mixins: [DeliveryMixin],

  /**
   * When the current route is updated
   */
  beforeRouteUpdate (to, from, next) {
    this.updateQueryFromRoute(to) // Load updated query from route
    this.fetchDeliveries() // Fetch deliveries
    next()
  },
  data: function () {
    return {
      isLoading: false,
      fetchError: null,

      deliveries: [],
      totalDeliveries: 0,

      deliveriesPerPage: 20,
      currentPage: 1,
      sortField: 'createdAt',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      filters: [],

      filterFields: FilterHelper.deliveryFields
    }
  },

  /**
   * When component is mounted
   */
  mounted () {
    this.updateQueryFromRoute(this.$route) // Load initial query from route
    this.fetchDeliveries() // Fetch the deliveries
  },

  methods: {
    /**
     * Fetch all deliveries
     */
    fetchDeliveries: async function () {
      this.isLoading = true
      try {
        const query = this.getCurrentQueryParams()
        const { deliveries, total } = (await DeliveryAPI.list(query)).data.data

        this.isLoading = false
        this.fetchError = null

        this.deliveries = deliveries
        this.totalDeliveries = total

        if (this.totalDeliveries > 0 && ((this.currentPage - 1) * this.deliveriesPerPage) > this.totalDeliveries) {
          // Current page is out of view (e.g. after filter change)
          this.currentPage = Math.ceil(this.totalDeliveries / this.deliveriesPerPage) // Change current route
          this.updateRouteForQuery()
        }
      } catch (error) {
        this.isLoading = false
        this.fetchError = error
        console.error(error)
      }
    },

    /**
     * Get the query params to fetch current data
     */
    getCurrentQueryParams () {
      const queryComponents = [
        `limit=${this.deliveriesPerPage}`,
        `skip=${(this.currentPage - 1) * this.deliveriesPerPage}`,
        `sort=${(this.sortOrder === 'desc' ? '-' : '') + this.sortField}`
      ]
      if (this.filters.length > 0) {
        // Translate filters into query and concatenate them
        this.filters.forEach(filter => {
          queryComponents.push(FilterHelper.getQueryForFilter(filter))
        })
      }
      return queryComponents.join('&')
    },

    /**
     * Update the data query from the current route
     * Will read sort order, per page count and filters from url
     */
    updateQueryFromRoute (route) {
      if (route.query.filter) {
        this.filters = FilterHelper.decodeFromURL(route.query.filter)
      } else {
        this.filters = []
      }

      if (route.query.limit) {
        this.deliveriesPerPage = Number.parseInt(route.query.limit)
      }
      if (route.query.skip) {
        this.currentPage = Math.floor(Number.parseInt(route.query.skip) / this.deliveriesPerPage) + 1
      }
      if (route.query.sort) {
        this.sortOrder = route.query.sort.startsWith('-') ? 'desc' : 'asc'
        this.sortField = route.query.sort.split('-').pop()
      }
    },

    /**
     * Update the route to represent the current data query
     * Will put sort order, per page count and filters into the url
     */
    updateRouteForQuery () {
      const query = {
        limit: this.deliveriesPerPage,
        skip: (this.currentPage - 1) * this.deliveriesPerPage,
        sort: (this.sortOrder === 'desc' ? '-' : '') + this.sortField
      }
      if (this.filters.length > 0) {
        query.filter = FilterHelper.encodeForURL(this.filters)
      }
      this.$router.push({
        name: 'deliveries',
        query
      })
    },

    /**
     * Handle changes to the current filter
     * Will update the route and fetch with the changed query
     */
    onFilterChange (filters) {
      this.filters = filters
      this.updateRouteForQuery()
    },

    /**
     * Handle page changes
     * Will update the route and fetch with the changed query
     */
    onPageChange (page) {
      this.currentPage = page
      this.updateRouteForQuery() // Update route, will fetch automatically
    },

    /**
     * Handle sorting changes
     * Will update the route and fetch with the changed query
     */
    onSort (field, order) {
      this.sortField = field
      this.sortOrder = order
      this.updateRouteForQuery() // Update route, will fetch automatically
    },

    /**
     * Handle change of displayed deliveries per page
     * Will update the route and fetch with the changed query
     */
    onDeliveriesPerPageChange (deliveriesPerPage) {
      this.deliveriesPerPage = deliveriesPerPage
      this.updateRouteForQuery() // Update route, will fetch automatically
    },

    onDeliveryAdded: function (delivery) {
      this.deliveries.unshift(delivery)
    }
  }
}
</script>
