import axios from 'axios'

export default {
  /**
   * Retrieve all licenses
   */
  async list (query = null) {
    return axios.get('/api/v1/license' + (query ? '?' + query : ''))
  },

  /**
   * Get a single license
   * @param {String} licenseID - license object id
   */
  async getOneLicense (licenseID) {
    return axios.get(`/api/v1/license/${licenseID}`)
  },

  /**
   * Post to the license endpoint
   * @param {Object} postData post data
   */
  async postLicense (postData) {
    return axios.post('/api/v1/license', postData)
  },

  /**
   * Update a license object via put
   * @param {License} licenseData
   */
  async putLicense (licenseData) {
    return this.putLicenseWithID(licenseData._id, licenseData)
  },

  /**
   * Update a license object via put
   * @param {String} licenseID
   * @param {License} licenseData
   */
  async putLicenseWithID (licenseID, licenseData) {
    return axios.put(`/api/v1/license/${licenseID}`, licenseData)
  },

  /**
   * Post to a licenses /holder endpoint
   * @param {*} licenseID
   * @param {*} postData
   */
  async postLicenseHolder (licenseID, postData) {
    return axios.post(`/api/v1/license/${licenseID}/holder`, postData)
  },

  /**
   * Get the signed download url for a license starter document
   * @param {String} licenseID - license object id
   */
  async getStarterDocumentURLForLicense (licenseID) {
    return axios.get(`/api/v1/license/${licenseID}/starter-document-url`)
  },

  /**
   * Get the signed download url for a license document
   * @param {String} licenseID - license object id
   */
  async getLicenseDocumentURLForLicense (licenseID) {
    return axios.get(`/api/v1/license/${licenseID}/license-document-url`)
  },

  /**
   * Get the license starter document stream
   * @param {String} licenseID - license object id
   */
  async getStarterDocumentStreamForLicense (licenseID) {
    return axios.get(`/api/v1/license/${licenseID}/starter-document`)
  },

  /**
   * Get the license document stream
   * @param {String} licenseID - license object id
   */
  async getLicenseDocumentStreamForLicense (licenseID) {
    return axios.get(`/api/v1/license/${licenseID}/license-document`)
  },

  /**
   * Generate the license starter document
   * @param {String} licenseID - license object id
   */
  async postStarterDocumentForLicense (licenseID) {
    return axios.post(`/api/v1/license/${licenseID}/starter-document`)
  },

  /**
   * Generate the license starter document
   * @param {String} licenseID - license object id
   */
  async postLicenseDocumentForLicense (licenseID) {
    return axios.post(`/api/v1/license/${licenseID}/license-document`)
  },

  /**
   * Get a licenses order generations
   * @param {*} licenseID
   */
  async getOrderGenerationsForLicense (licenseID) {
    return axios.get(`/api/v1/license/${licenseID}/order-generation`)
  },

  /**
   * Get a licenses deliveries
   * @param {*} licenseID
   */
  async getDeliveriesForLicense (licenseID) {
    return axios.get(`/api/v1/license/${licenseID}/delivery`)
  },

  /**
   * Get a licenses deliveries
   * @param {*} licenseID
   */
  async getUpgradeSessionsForLicense (licenseID) {
    return axios.get(`/api/v1/license/${licenseID}/upgrade-session`)
  },

  /**
   *
   * @param {String} licenseID - the license id
   */
  async getMetacomDownloadURLForLicense (licenseID) {
    return axios.get(`/api/v1/license/${licenseID}/download/metacom/url`)
  }

}
