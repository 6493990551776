<template>
  <div class="address-form">
    <b-field
      label="Name"
      :type="errors.has('addressline1') ? 'is-danger' : ''"
      :message="errors.first('addressline1')"
    >
      <b-input
        v-model="internal.name"
        v-validate="'required'"
        name="addressline1"
        placeholder="Firmenname oder Name"
        data-vv-as="Firmenname oder Name"
        @input="notifyChange"
      />
    </b-field>
    <b-field
      label="Zusätzliche Adressangabe 1 (optional)"
    >
      <b-input
        v-model="internal.additional1"
        name="addressline2"
        placeholder="Gebäude oder Abteilung"
        @input="notifyChange"
      />
    </b-field>
    <b-field
      label="Zusätzliche Adressangabe 2 (optional)"
    >
      <b-input
        v-model="internal.additional2"
        name="addressline3"
        placeholder="Ansprechpartner oder Kostenstelle"

        @input="notifyChange"
      />
    </b-field>

    <b-field
      grouped
    >
      <b-field
        label="Straße"
        expanded
        :type="errors.has('street') ? 'is-danger' : ''"
        :message="errors.first('street')"
      >
        <b-input
          v-model="internal.street"
          v-validate="'required'"
          name="street"
          placeholder="Straße"
          data-vv-as="Straße"
          @input="notifyChange"
        />
      </b-field>
      <b-field
        label="Hausnummer"
        expanded
        :type="errors.has('housenumber') ? 'is-danger' : ''"
        :message="errors.first('housenumber')"
      >
        <b-input
          v-model="internal.houseNumber"
          v-validate="'required'"
          name="housenumber"
          placeholder="Hausnummer"
          data-vv-as="Hausnummer"
          @input="notifyChange"
        />
      </b-field>
    </b-field>

    <b-field
      grouped
    >
      <b-field
        label="Postleitzahl"
        expanded
        :type="errors.has('postcode') ? 'is-danger' : ''"
        :message="errors.first('postcode')"
      >
        <b-input
          v-model="internal.postcode"
          v-validate="'required'"
          name="postcode"
          placeholder="Postleitzahl"
          data-vv-as="Postleitzahl"
          @input="notifyChange"
        />
      </b-field>
      <b-field
        label="Ort"
        expanded
        :type="errors.has('city') ? 'is-danger' : ''"
        :message="errors.first('city')"
      >
        <b-input
          v-model="internal.city"
          v-validate="'required'"
          name="city"
          placeholder="Ort"
          data-vv-as="Ort"
          @input="notifyChange"
        />
      </b-field>
    </b-field>
    <b-field
      label="Land"
      :type="errors.has('country') ? 'is-danger' : ''"
      :message="errors.first('country')"
    >
      <country-select
        v-model="internal.countryCode"
        v-validate="'required'"
        data-vv-as="Land"
        name="country"
        @input="notifyChange"
      />
    </b-field>
  </div>
</template>

<script>
import CountrySelect from '@/components/general/CountrySelect.vue'
export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    CountrySelect
  },
  props: {
    value: {
      type: Object,
      default: null
    }
    // required: {
    //   type: Boolean
    //   default: false
    // }
  },
  data () {
    return {
      ignoreChanges: false,
      internal: {
        name: null,
        additional1: null,
        additional2: null,
        street: null,
        houseNumber: null,
        postcode: null,
        city: null,
        countryCode: null
      }
    }
  },
  watch: {
    value: {
      handler (v) {
        this.ignoreChanges = true
        Object.keys(this.internal).forEach(key => {
          if (this.internal[key] !== this.value[key]) {
            this.internal[key] = this.value[key]
          }
        })
        this.ignoreChanges = false
      },
      deep: true
    }
  },
  created () {
    this.$validator = this.$parent.$validator
  },
  mounted () {
    Object.keys(this.internal).forEach(key => {
      this.internal[key] = this.value[key]
    })
  },
  methods: {
    notifyChange () {
      this.$emit('input', this.internal)
    }
  }
}
</script>
