import axios from 'axios'

export default {
  /**
   * Get multiple license starter documents bundled as a single pdf
   * @param {Array<String>} licenseIDs the license ids
   */
  getStarterDocumentsPDF (licenseIDs) {
    return axios({
      method: 'POST',
      url: 'api/v1/batch-download/license-documents/starter/pdf',
      data: { licenses: licenseIDs },
      responseType: 'blob'
    })
  },

  /**
   * Get multiple license starter documents bundled as a zip archive
   * @param {Array<String>} licenseIDs the license ids
   */
  getStarterDocumentsZIP (licenseIDs) {
    return axios({
      method: 'POST',
      url: '/api/v1/batch-download/license-documents/starter/zip',
      data: { licenses: licenseIDs },
      responseType: 'blob'
    })
  },

  /**
  * Get multiple license documents bundled as a single pdf
  * @param {Array<String>} licenseIDs the license ids
  */
  getLicenseDocumentsPDF (licenseIDs) {
    return axios({
      method: 'POST',
      url: '/api/v1/batch-download/license-documents/license/pdf',
      data: { licenses: licenseIDs },
      responseType: 'blob'
    })
  },

  /**
   * Get multiple license documents bundled as a zip archive
   * @param {Array<String>} licenseIDs the license ids
   */
  getLicenseDocumentsZIP (licenseIDs) {
    return axios({
      method: 'POST',
      url: '/api/v1/batch-download/license-documents/license/zip',
      data: { licenses: licenseIDs },
      responseType: 'blob'
    })
  }
}
