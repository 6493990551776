<template lang="pug">
  #license-binding
    b-message(
      :title="'Fehler beim Binden der Lizenz ( Fehler ' + error.code + ')'"
      v-if="error"
      type="is-danger"
    ) {{ error.message }}
    license-bind-form(@submit="submitBindingForm" :restrictedHolderTypes="restrictedHolderTypes" mode="bind" :loading="isLoading")
</template>

<script>
import LicensePersonBinding from '@/components/customer/LicensePersonBinding'
import LicenseCompanyBinding from '@/components/customer/LicenseCompanyBinding'
import LicenseBindForm from '@/components/customer/LicenseBindForm'

import LicenseAPI from '@/services/api/License'

export default {
  name: 'LicenseBinding',
  components: {
    LicensePersonBinding,
    LicenseCompanyBinding,
    LicenseBindForm
  },
  props: {
    license: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      error: null
    }
  },
  computed: {
    restrictedHolderTypes () {
      if (this.license.version === '') {
        return null
      } else if (this.license.properties.class === 'personal') {
        return ['person']
      } else if (this.license.properties.class === 'pro') {
        return ['organization']
      }
      return null
    }
  },
  methods: {
    submitBindingForm (holderData) {
      this.isLoading = true
      this.error = null

      LicenseAPI.postLicenseHolder(this.license._id, holderData)
        .then((response) => {
          this.isLoading = false
          this.$emit('bound')
        })
        .catch((error) => {
          this.isLoading = false

          let message
          if (error.response.status === 409) {
            message = 'Die eingegebenen Lizenz ist nicht ungebunden. Wenn Sie glauben, dass es sich dabei um einen Fehler handelt, kontaktieren Sie uns bitte unter support@metacom-symbole.de.'
          } else if (error.response.status >= 500) {
            message = 'Ein Serverfehler ist aufgetreten. Bitte versuchen Sie es später erneut.'
          } else {
            message = 'Ein unbekannter Fehler ist aufgetreten. Bitte überprüfen Sie Ihre Eingaben und probieren Sie es erneut. Bei anhaltenden Problemen können Sie sich an support@metacom-symbole.de wenden.'
          }
          this.error = {
            code: error.response.status,
            message
          }
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  #license-binding {
    position: relative;
  }
</style>
