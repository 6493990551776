<template lang="pug">
  nav.navbar.is-primary(role="navigation" aria-label="main navigation")
    .navbar-brand
      a.navbar-item(href="/")
        img(src="@/assets/images/logo/metacom-license-logo.svg" alt="METACOM Lizenzverwaltung" width="316" height="30")
      .navbar-burger(@click="toggleMenu", :class="{'is-active': navIsActive}" data-target="mainNav")
        span(aria-hidden="true")
        span(aria-hidden="true")
        span(aria-hidden="true")
    .navbar-menu#mainNav(:class="{'is-active': navIsActive}")
      .navbar-start(v-if="isActiveLicense")
        router-link.navbar-item(to="/", :exact="true") Meine Lizenz
        router-link.navbar-item(to="/download", v-if="isMetacomDownloadVisible") METACOM herunterladen
        router-link.navbar-item(to="/terms") Nutzungsbedingungen
      .navbar-start(v-if="isActiveAdmin")
        router-link.navbar-item(to="/backend/license") Lizenzen
        router-link.navbar-item(to="/backend/order-generation") Shop-Generierungen
        router-link.navbar-item(to="/backend/delivery") Versand
        router-link.navbar-item(to="/backend/upgrade-session") Upgrade-Sessions
        router-link.navbar-item(to="/backend/download") Downloads
        // router-link.navbar-item(to="/backend/licenses") Lizenzen
      .navbar-end
        a.navbar-item
          .field.is-grouped
            p.control
              a.button.is-dark.is-small(@click="logout()")
                span.user-name {{ name }}
                span.icon.is-small: i.far.fa-sign-out
                span Abmelden

</template>

<script>
import LicenseMixin from '@/mixins/license'
export default {
  name: 'NavigationBar',
  data () {
    return {
      navIsActive: false
    }
  },
  computed: {
    /**
     * Check if a license is logged in and if the license has permission to download metacom
     */
    isMetacomDownloadVisible () {
      if (!this.$store.getters['auth/isAuthenticated']) {
        return false
      }
      if (this.$store.getters['auth/userType'] !== 'license') {
        return false
      }
      const data = this.$store.getters['auth/userData']
      return data !== null && (
        LicenseMixin.methods.canDownloadMetacom(data) ||
        LicenseMixin.methods.canDownloadMetasearchOneClickInstaller(data) ||
        LicenseMixin.methods.canDownloadMetasearchMultistepInstaller(data) ||
        LicenseMixin.methods.canShowMetasearchServerDocumentation(data)
      )
    },
    /**
     * Check if ta user is logged in and if it is a license
     */
    isActiveLicense () {
      return this.$store.getters['auth/isAuthenticated'] &&
        this.$store.getters['auth/userType'] === 'license' &&
        this.$store.getters['auth/userData'] !== null &&
        this.$store.getters['auth/userData'].status === 'bound'
    },
    /**
     * Check if a user is logged in and if it is an admin
     */
    isActiveAdmin () {
      return this.$store.getters['auth/isAuthenticated'] &&
      this.$store.getters['auth/userType'] === 'admin'
    },
    /**
     * Get the current users naem
     */
    name () {
      const userData = this.$store.getters['auth/userData']
      const userType = this.$store.getters['auth/userType']
      if (!userData || !userType) {
        return '?'
      }

      if (userType === 'license') {
        return userData.holder && userData.holder.name ? userData.holder.name : userData.key
      } else if (userType === 'admin') {
        return userData.name
      } else {
        return ''
      }
    }
  },
  methods: {
    /**
     * Toggle menu visibility
     */
    toggleMenu: function () {
      this.navIsActive = !this.navIsActive
    },

    /**
     * Perform user logout
     */
    logout: function () {
      this.$store.dispatch('auth/logout').then(() => {
        this.$router.push('/login')
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .navbar-brand {
    padding-right: 20px;
  }
  .user-name {
    margin-right: 1em;
  }
</style>
