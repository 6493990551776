<template lang="pug">
widget-panel(:title="title", icon="fa-tasks")
    template(slot="actions")
    template(slot="content")
      table.table
        tbody
          tr
            th METACOM Version
            td(v-if="!editing") {{ version }}
            td(v-if="editing")
              b-select(
                :value="version"
                @input="versionChanged($event)"
              )
                option(value="8") 8
                option(value="9") 9

          tr
            th Klasse
            td(v-if="!editing") {{ getFormattedLicenseClass(licenseClass) }}
            td(v-if="editing")
              b-select(
                :value="licenseClass"
                @input="classChanged($event)"
              )
                option(value="single") Einzellizenz
                option(value="multiple") Mehrfachlizenz
                option(value="server") Serverlizenz
                option(value="personal") Persönliche Lizenz
                option(value="pro") Pro-Lizenz
          tr
            th Typ
            td(v-if="!editing") {{ getFormattedLicenseType(type) }}
            td(v-if="editing")
              b-select(
                :value="type"
                @input="typeChanged($event)"
              )
                option(value="full") Vollversion
                option(value="update") Update
          tr
            th Größe
            td(v-if="!editing") {{ size }}
            td(v-if="editing")
              b-input(
                :value="size"
                @input="sizeChanged($event)"
                type="number"
              )
          tr
            th Auslieferung
            td(v-if="!editing") {{ getFormattedLicenseDelivery(delivery) }}
            td(v-if="editing")
              b-select(
                :value="delivery"
                @input="deliveryChanged($event)"
              )
                option(value="dvd") DVD
                option(value="download") Download
          tr
            th Anzahl DVDs
            td(v-if="!editing") {{ dvdCount ?? '-' }}
            td(v-if="editing")
              b-input(
                :value="dvdCount"
                @input="dvdCountChanged($event)"
                type="number"
              )
</template>
</template>

<script>
import licenseMixin from '@/mixins/license'
import WidgetPanel from '@/components/general/WidgetPanel'
export default {
  components: { WidgetPanel },
  mixins: [licenseMixin],
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    version: {
      type: String,
      required: true
    },
    licenseClass: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    size: {
      type: Number,
      required: true
    },
    delivery: {
      type: String,
      required: true
    },
    dvdCount: {
      type: Number,
      required: false,
      default: null
    },
    title: {
      type: String,
      default: 'Eigenschaften',
      required: false
    }
  },

  methods: {
    versionChanged (event) {
      this.$emit('update:version', event)
    },
    classChanged (event) {
      this.$emit('update:licenseClass', event)
    },
    typeChanged (event) {
      this.$emit('update:type', event)
    },
    sizeChanged (event) {
      this.$emit('update:size', parseInt(event))
    },
    dvdCountChanged (event) {
      this.$emit('update:dvdCount', parseInt(event))
    },
    deliveryChanged (event) {
      this.$emit('update:delivery', event)
    }

  }
}
</script>
