var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"simple-login"},[_c('error-message',{attrs:{"error":_vm.authError,"title":"Fehler beim Anmelden","allow-retry":false,"message-by-status":{
      401: _vm.loginTypeForStatus === 'startcode' ? 'Der angegebene Startcode ist ungültig. Bitte überprüfen Sie Ihre Angabe!' : 'Der angegebene Lizenznehmer-Name ist nicht korrekt. Vergleichen Sie Ihre Eingabe mit dem Lizenzdokument. Die exakte Schreibweise ist wichtig. Über den Button „Ich habe mein Lizenzdokument verloren” können Sie sich das Lizenzdokument noch einmal zusenden lassen.',
      423: 'Diese Lizenz ist bereits gebunden. Bitte verwenden Sie anstatt des Startcodes den Namen des Lizenznehmers.',
    }}}),_c('error-message',{attrs:{"error":_vm.loadStatusError,"title":"Fehler beim Anmelden mit Lizenzschlüssel","allow-retry":false,"message-by-status":{
      404: 'Bitte überprüfen Sie Ihren Lizenzschlüssel',
      403: 'Bei dieser Lizenz kann sich nicht angemeldet werden, da sie deaktiviert wurde. Wahrscheinlich wurde bereits ein Update auf eine neuere METACOM Version für diese Lizenz durchgeführt. Sie haben in diesem Fall Zugangsdaten zu einer neuen Lizenz mit einem abweichenden Lizenzschlüssel erhalten.',
    }}}),_c('b-field',{attrs:{"label":"Ihr Lizenzschlüssel"}}),_c('b-field',[_c('license-key-input',{attrs:{"disabled":_vm.isLoadingStatus,"expanded":""},on:{"enter":_vm.retrieveLicenseStatus},model:{value:(_vm.key),callback:function ($$v) {_vm.key=$$v},expression:"key"}})],1),_c('b-field',[(_vm.hasValidKey && _vm.hasValidStatus)?_c('license-authentication-code-input',{attrs:{"focus":"","expanded":"","login-type":_vm.loginTypeForStatus,"disabled":_vm.isLoadingAuth},on:{"enter":_vm.onSubmitButtonPressed},model:{value:(_vm.authenticationCode),callback:function ($$v) {_vm.authenticationCode=$$v},expression:"authenticationCode"}}):_vm._e()],1),_c('b-field',[_c('b-button',{attrs:{"type":"is-primary","icon-left":"arrow-right","loading":_vm.isLoadingStatus || _vm.isLoadingAuth,"disabled":!_vm.hasValidKey || _vm.hasValidStatus && !_vm.hasAuthenticationCode,"expanded":""},on:{"click":_vm.onSubmitButtonPressed}},[_vm._v(" "+_vm._s(_vm.currentStep === 'key' ? 'Weiter' : 'Anmelden')+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }