<template lang="pug">
  .license-assistant
    b-loading(:active="isLoadingPatch || isLoadingCreate || isLoadingOffer || isCreatingOffer")
    //- welcome
    .step(v-if="currentState === 'welcome'")
      h1.title METACOM 9 Desktop
      p Klicken Sie auf „Lizenz-Assistent starten”, um eine passende Lizenz zu wählen und den Preis anzuzeigen. Zum Schluss können Sie die Lizenz in den Warenkorb legen und kaufen oder zunächst ein Angebot erstellen. Sie können eine neue Lizenz erwerben, eine ältere Lizenz updaten oder eine vorhandene METACOM 9 Desktop Lizenz erweitern.
      .columns.options.mt-4
        .column
          .button.option(@click="send('START')" title="Hier klicken, um den Lizenz-Assistenten zu starten")
              img(src="@/assets/images/assistant/M9_pro_personal.png")
              .content
                h4.title Lizenz-Assistent für Bestellungen und Angebote
                p.description
                  | Persönliche Lizenzen/Pro-Lizenzen
                  br
                  | Vollversionen, Updates und Lizenzerweiterungen
                p.field.mt-2
                  b-button(type="is-primary" @click="send('START')") Lizenz-Assistent starten

      .content
        .buttons
          b-button(icon-left="file-invoice" @click="send('OFFER')") Bestehendes Angebot (Angebotscode eingeben)
          b-button(icon-left="boxes-stacked" @click="send('MANUAL')") Bestellung über Artikelnummern (Reseller)

    //- country_select
    .step(v-if="currentState === 'country_select'")
      b-button.back(size="" icon-left="chevron-left" @click="goBack()") Zurück
      h1.title In welches Land soll die Bestellung gehen?
      .columns.options
        .column
          .button.option.vertical(@click="send('DE')")
            img(src="@/assets/images/assistant/deutschland.png")
            .content
              h4.title Deutschland
        .column
          .button.option.vertical(@click="send('AT')")
              img(src="@/assets/images/assistant/oesterreich.png")
              .content
                h4.title Österreich
        .column
          .button.option.vertical(@click="send('CH')")
              img(src="@/assets/images/assistant/schweiz.png")
              .content
                h4.title Schweiz
        .column
          .button.option.vertical(@click="send('OTHER')")
              img(src="@/assets/images/assistant/europa.png")
              .content
                h4.title Anderes Land

    //- country_select_other
    .step(v-if="currentState === 'country_select_other'")
      b-button.back(size="" icon-left="chevron-left" @click="goBack()") Zurück
      h1.title Bitte wählen Sie Ihr Land aus
      country-select(v-model="selectedCountry")
      b-button.mt-4(type="is-primary" expanded @click="sendIf({ event: 'SELECTED', condition: billing.countryCode != null })" :disabled="!billing.countryCode") Fortfahren

    //- start
    .step(v-if="currentState === 'start'")
      b-button.back(size="" icon-left="chevron-left" @click="goBack()") Zurück
      h1.title Haben Sie bereits eine METACOM Lizenz?
      .columns.options
        .column
          .button.option(@click="send('NO')")
            img(src="@/assets/images/assistant/nein-rund.png")
            .content
              h4.title Nein
              p.description Ich möchte eine neue METACOM 9 Lizenz erwerben.
        .column
          .button.option(@click="send('YES')")
              img(src="@/assets/images/assistant/ja-rund.png")
              .content
                h4.title Ja
                p.description Ich habe schon eine METACOM Lizenz und möchte sie updaten oder erweitern.

    //- update_eligibility
    .step(v-else-if="currentState === 'update_eligibility'")
      b-button.back(size="" icon-left="chevron-left" @click="goBack()") Zurück
      h1.title Welche METACOM Version haben Sie bereits?
      .columns.options
        .column
          .button.option(@click="send('M9')")
            .content
              strong Ich habe
              h4.title METACOM 9 DESKTOP
              p.description Ich möchte eine Pro-Lizenz erweitern (Lizenzplätze hinzufügen).
              p.description Persönliche Lizenzen können nicht erweitert werden.
        .column
          .button.option(@click="send('M8')")
            .content
              strong Ich habe
              h4.title METACOM 8
              p.description Ich möchte ein Update auf METACOM 9 Desktop erwerben.
        .column
          .button.option(@click="send('M7_OR_LESS')")
            .content
              strong Ich habe
              h4.title METACOM 7 oder älter
              p.description Ich möchte ein Update auf METACOM 9 Desktop erwerben.

    //- update_not_eligible
    .step(v-else-if="currentState === 'update_not_eligible'")
      b-button.back(size="" icon-left="chevron-left" @click="goBack()") Zurück
      h1.title Update nicht möglich
      p Für Ihre METACOM Version wird kein Update auf METACOM 9 Desktop angeboten.
      .columns.options
        .column
          .button.option(@click="send('BUY')")
            .content
              h4.title METACOM 9 DESKTOP
              h6.subtitle Neue Lizenz erwerben
              p.description Ich möchte eine neue METACOM 9 Lizenz erwerbenl
        .column
        .column

    //- license_login_update
    .step(v-else-if="currentState === 'license_login'")
      b-button.back(size="" icon-left="chevron-left" @click="goBack()") Zurück
      template(v-if="purchaseType === 'upgrade'")
        h1.title Update einer METACOM 8 Lizenz
        p.description Geben Sie in das linke Feld den Lizenzschlüssel ein. Anschließend wird Ihnen angezeigt, ob Sie in das rechte Feld den Lizenznehmer-Namen oder Startcode eingeben müssen. Wenn sie mehrere Lizenzschlüssel haben, können Sie die Lizenzen zusammenführen und zusammengeführt updaten.
      template(v-else)
        h1.title Erweiterung einer METACOM 9 Pro-Lizenz
        p.description Geben Sie in das linke Feld den Lizenzschlüssel ein. Anschließend wird Ihnen angezeigt, ob Sie in das rechte Feld den Lizenznehmer-Namen oder Startcode eingeben müssen. Persönliche Lizenzen können nicht erweitert werden und auch nicht in eine Pro-Lizenz umgewandelt werden.
      //- Multi license login component
      multi-license-login(
        style="margin-top: 20px; margin-bottom: 20px;"
        :restricted-versions="purchaseType === 'upgrade' ? ['8'] : ['9']"
        :restricted-classes="purchaseType === 'extension' ? ['pro'] : null"
        :limit="purchaseType === 'upgrade' ? null : 1"
        :action-text="purchaseType === 'upgrade' ? 'updaten' : 'erweitern'"
        :license-not-allowed-message="purchaseType === 'upgrade' ? 'Das Updaten ist nur für METACOM 8 Lizenzen möglich. Bitte melden Sie sich mit einer METACOM 8 Lizenz an' : 'Das Erweitern ist nur für METACOM 9 Lizenzen möglich. Bitte melden Sie sich mit einer METACOM 9 Lizenz an'"
        @completed="send('LOGGED_IN')"
      )

      a.button.is-small(href="/lizenz-wiederherstellen", target="_blank") Ich habe mein Lizenzdokument verloren

    //- license_bind
    .step(v-else-if="currentState === 'license_bind'")
      b-button.back(size="" icon-left="chevron-left" @click="goBack()") Zurück
      h1.title Lizenzbindung
      //- p.description Ergänzen bzw. überprüfen Sie die Angaben zum Lizenznehmer.
      update-license-binding(@completed="send('COMPLETED')")

    //- license_pro_calculator
    .step(v-else-if="currentState === 'license_pro_calculator'")
      b-button.back(size="" icon-left="chevron-left" @click="goBack()") Zurück
      h1.title(v-if="purchaseType === 'extension'") Um wie viele Plätze möchten Sie Ihre METACOM 9 Desktop Pro-Lizenz erweitern?
      h1.title(v-else) Wie viele Plätze für die METACOM 9 Desktop Pro-Lizenz möchten Sie erwerben?
      pro-license-configurator(@completed="send('COMPLETED')")

    //- license_select
    .step(v-else-if="currentState === 'license_select'")
      b-button.back(size="" icon-left="chevron-left" @click="goBack()") Zurück
      h1.title(v-if="purchaseType === 'upgrade'") Auf welche METACOM 9 Desktop Lizenz möchten Sie updaten?
      h1.title(v-else) Welche METACOM 9 Desktop Lizenz möchten Sie erwerben?
      .columns.options
        .column
          .button.option(@click="send('PERSONAL')" :class="{ 'is-recommended': recommendedUpgradeLicenseClass === 'personal' }")
            img(src="@/assets/images/assistant/webshopbild_M9persvoll.png")
            .content
              h4.title Persönliche Lizenz
              p.description Wenn Sie die Lizenz als Privatperson kaufen möchten, ist die Persönliche Lizenz passend.
            .license-recommendation(v-if="recommendedUpgradeLicenseClass === 'personal'")
              b-icon(icon="arrow-up" custom-size="fa-3x")
              span Aufgrund Ihrer bisherigen Lizenz empfehlen wir das Update auf eine Persönliche Lizenz. Wenn die Lizenz an ein Unternehmen gebunden werden soll, oder Sie mehr als einen Lizenzplatz erwerben möchten, wählen Sie stattdessen die Pro-Lizenz.

        .column
          .button.option(@click="send('PRO')" :class="{ 'is-recommended': recommendedUpgradeLicenseClass === 'pro' }")
            img(src="@/assets/images/assistant/webshopbild_M9provoll.png")
            .content
              h4.title Pro-Lizenz
              p.description für Einrichtungen, Kitas, Schulen, (Einzel-) Unternehmen. Pro-Lizenzen sind ab Größe 1 (für 1 Nutzer oder 1 Arbeitsplatz) in beliebiger Größe erhältlich.

            .license-recommendation(v-if="recommendedUpgradeLicenseClass === 'pro'")
              b-icon(icon="arrow-up" custom-size="fa-3x")
              span Aufgrund Ihrer bisherigen Lizenz empfehlen wir das Update auf eine Pro-Lizenz. Persönliche Lizenzen können nur an eine einzelne Person gebunden und nur von dieser genutzt werden.

      b-field(label="Mehr über die Lizenzarten lesen in" style="margin-top: 200px;")
        b-button(icon-left="comments-question" tag="a" href="https://metacom.support/de-de/18-lizenzen" target="_blank") METACOM Fragen & Antworten
    //- offer_load
    .step(v-else-if="currentState === 'offer_load'")
      b-button.back(size="" icon-left="chevron-left" @click="goBack()") Zurück
      h1.title Bestehendes Angebot laden
      p Geben Sie den Angebotscode ein, um die Bestellung durchzuführen.
      offer-load-form(@loaded="send('LOADED')")

    //- manual_order
    .step(v-else-if="currentState === 'manual_order'")
      b-button.back(size="" icon-left="chevron-left" @click="goBack()") Zurück
      h1.title Bestellung über Artikelnummern
      p Hier können Sie über die Eingabe von Artikelnummern die gewünschten METACOM 9 Desktop Lizenzen erwerben. DVDs können im nächsten Schritt hinzugefügt werden. Updates und Erweiterungen können nur über den Lizenz-Assistenten erworben werden.
      p Wählen Sie das Zielland und anschließend das zu bestellende Produkt aus.

      manual-order(@submit="send('SUBMIT')" class="mt-5")

    //- license_select_dvd
    .step(v-else-if="currentState === 'license_select_dvd'")
      b-button.back(size="" icon-left="chevron-left" @click="goBack()") Zurück
      h1.title Die Installationsdatei wird als als Download bereitgestellt. Möchten Sie zusätzlich eine DVD zur Installation erwerben?
      .columns.options
        .column
          .button.option(@click="send('NO_DVD')")
            img(src="@/assets/images/assistant/ohne_dvd.png")
            .content
              h4.title Keine DVD
              p.description Die DVD kann nur zur Installation verwendet werden.
        .column
          .button.option(@click="sendIf({ condition: canPurchaseDVDs, event: 'DVD'})" :class="{ disabled: !canPurchaseDVDs } ")
            img(src="@/assets/images/assistant/mit_dvd.png")
            .content
              h4.title Zusätzliche DVD
              p.description Es fallen zusätzliche Kosten in Höhe von 15 € inkl. MwSt. und Versand an.

      b-message(v-if="!canPurchaseDVDs" type="is-warning") Die Lieferung zusätzlicher Installationsdatenträger (DVD) ist nur innerhalb von Deutschland möglich. Für die Nutzung der Lizenz ist eine DVD jedoch nicht erforderlich.

    //- summary
    .step(v-else-if="currentState === 'summary'")
      b-button.back(size="" icon-left="chevron-left" @click="goBack()") Zurück
      h1.title Ausgewählte Lizenz{{ seatCount > 1 ? 'en' : ''}} überprüfen
      h2.subtitle(v-if="isOffer") Angebot Nr. {{ session.offerNumber }}
      license-change-preview
      .content
        cart-summary
      .content
        p Wenn Sie aus einem EU-Land (außer Deutschland) bestellen und eine steuerfreie Rechnung wünschen, geben Sie vor dem Absenden der Bestellung Ihre Umsatzsteuer-ID und die dazu bei Ihrem Finanzamt registrierte Adresse ein. Bei korrekter Dateneingabe wird der Steuerbetrag entfernt. Eine nachträgliche Änderung der Rechnung bez. Steuerfreiheit ist nicht möglich!
      b-modal(v-model="isResellerModalActive")
        template(#default="props")
          .card
            .card-header
              p.card-header-title Mehrere Persönliche Lizenzen bestellen
            .card-content
              reseller-cart-quantity-editor(class="mt-6" :removable="false")
              b-button(type="is-primary" class="mt-6" @click="props.close") Fertig
      .field
        .buttons
          b-button(icon-left="file-pdf" @click="send('OFFER')" :disabled="isOffer") Angebot erstellen
          b-button(icon-left="badge-check" tag="a" href="https://metacom.shop/systemvoraussetzungen" target="_blank") Systemvoraussetzungen
          b-button(icon-left="boxes" v-if="purchaseType === 'new' && licenseType === 'personal'" @click="isResellerModalActive = true" :disabled="isOffer") Mehrere Persönliche Lizenzen erwerben (Händler)
      .field
        b-button(type="is-primary" icon-left="basket-shopping-simple", @click="send('CART')") In den Warenkorb

    //- offer_create
    .step(v-else-if="currentState === 'offer_create'")
      b-button.back(size="" icon-left="chevron-left" @click="goBack()") Zurück
      h1.title Angebot erstellen
      p.description Sie können ein Angebot für die ausgewählte Lizenz erstellen und sich als PDF per E-Mail zuschicken lassen. Um das Angebot zu einem späteren Zeitpunkt zu bestellen, kann der Angebotscode eingegebene werden. Die ausgewählten Lizenzen werden dann in den Warenkorb gelegt und die hier hinterlegten Adressdaten werden automatisch an der Kasse vorausgewählt. Angebote sind 30 Tage lang gültig.
      .content.pt-4
        offer-create-form(@created="send('CREATED')")

    //- offer_created
    .step(v-else-if="currentState === 'offer_created'")
      h1.title Angebot erstellt und versendet
      p.description
        | Wir haben Ihr Angebot mit der Nummer #[strong {{ session.offerNumber }}] erstellt und an #[strong {{ session.billing.email }}] gesendet.
      p.description.mt-2 Die E-Mail kommt von der E-Mail-Adresse bestellung@metacom.shop - bitte überprüfen Sie auch Ihren Spam-Ordner und stellen Sie sicher, dass Sie E-Mails von dieser Adresse empfangen können. Die Lizenzen werden nach der Bestellung ebenfalls von bestellung@metacom.shop versendet.
      p.description.mt-2
        | Auf Ihrem Angebot finden Sie Ihren persönlichen Angebotscode.
        | Wenn Sie diesen im Lizenz-Assistenten eingeben, können Sie die Bestellung abschließen, ohne erneut alle Schritte des Assistenten zu durchlaufen.

      .buttons.mt-4
        b-button(@click="send('RESET')" icon-left="redo") Lizenz-Assistenten neu starten

    .step(v-else-if="currentState === 'add_to_cart'")
      .hero.is-large
        .hero-body.is-relative.is-justify-content-center
          b-loading.mb-6(active :is-full-page="false")
          .title.has-text-centered.mt-6 Warenkorb wird übertragen
          .subtitle.has-text-centered.mt-1 Bitte haben Sie einen Moment Geduld - Sie werden gleich weitergeleitet.
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import MultiLicenseLogin from '@/components/customer/assistant/MultiLicenseLogin'
import UpdateLicenseBinding from '@/components/customer/assistant/UpdateLicenseBinding'
import ProLicenseConfigurator from '@/components/customer/assistant/ProLicenseConfigurator'
import CartSummary from '@/components/customer/assistant/CartSummary'
import ManualOrder from '@/components/customer/assistant/ManualOrder'
import OfferLoadForm from '@/components/customer/assistant/OfferLoadForm'
import CountrySelect from '@/components/general/CountrySelect'
import ResellerCartQuantityEditor from '@/components/customer/assistant/ResellerCartQuantityEditor.vue'
import OfferCreateForm from '@/components/customer/assistant/OfferCreateForm.vue'
import LicenseChangePreview from '@/components/customer/assistant/LicenseChangePreview.vue'
import { throttle } from 'lodash'

const METACOM_SHOP_HOST = process.env.VUE_APP_SHOP_URL
export default {
  components: {
    MultiLicenseLogin,
    UpdateLicenseBinding,
    ProLicenseConfigurator,
    CartSummary,
    CountrySelect,
    OfferLoadForm,
    ManualOrder,
    OfferCreateForm,
    ResellerCartQuantityEditor,
    LicenseChangePreview
  },
  data () {
    return {
      isResellerModalActive: false
    }
  },
  computed: {
    ...mapState('assistant', [
      'session',
      'currentState',
      'seatCount',
      'purchaseType',
      'licenseType',
      'billing',
      'isLoadingCreate',
      'isCreatingOffer',
      'isLoadingOffer',
      'isLoadingPatch'
    ]),
    ...mapGetters('assistant', [
      'isOffer',
      'canPurchaseDVDs',
      'recommendedUpgradeLicenseClass'
    ]),
    selectedCountry: {
      get () {
        return this.$store.state.assistant.billing.countryCode
      },
      set (value) {
        this.$store.state.assistant.billing.countryCode = value
      }
    }
  },
  created () {
    window.addEventListener('resize', this.handleWindowResize)
    addEventListener()
  },
  mounted () {
    this.$store.dispatch('assistant/adjustIFrameHeight')
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleWindowResize)
  },
  methods: {
    ...mapActions('assistant', ['send', 'sendIf', 'goBack']),
    handleWindowResize: throttle(function () { this.$store.dispatch('assistant/adjustIFrameHeight') }, 200),
    onMessage (event) {
      // if (event.origin !== METACOM_SHOP_HOST) {
      // }
    },
    onProLicenseClicked () {
      window.parent?.postMessage({ message: 'test' }, METACOM_SHOP_HOST)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/bulma-variables.scss";

.license-assistant {
  padding: 0 10px;
}
.step {
  .title {
  }

  .back {
    // float: left;
    margin-bottom: 10px;
  }
}
.button.option {
  position: relative;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  white-space: initial;
  text-align: left;
  padding: 18px 12px;
  border-width: 2px;

  &.is-recommended {
    border: 2px solid $primary;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  img {
    max-width: 150px;
    max-height: 150px;
    margin-right: 24px;
  }

  &.vertical {
    flex-direction: column;
    justify-content: center !important;
    align-items: center !important;
    text-align: center;

    img {
      margin-right: 0;
    }
  }

  .content {
    padding-top: 8px;
  }
}
.columns.options {
  align-items: stretch;
  .button.option {
    height: 100%;
    align-items: flex-start;
  }
}

.license-recommendation {
  position: absolute;
  top: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;

  .icon {
    height: 3em;
    width: 3em;
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}

.icon {
  animation: float 6s ease-in-out infinite;
}
</style>
