// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.

import Vue from 'vue'
import App from '@/App'
import Buefy from 'buefy'
import router from '@/router'
import store from '@/store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VeeValidate, { ValidationProvider, ValidationObserver } from 'vee-validate'
import validatorLocaleDE from '@/localization/validator/de'
import VueClipboard from 'vue-clipboard2'
import CleavePlugin from '@/plugins/CleavePlugin'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import countries from './lib/countries'

import '@fortawesome/fontawesome-pro/css/fontawesome.css'
import '@fortawesome/fontawesome-pro/css/regular.css'
import '@fortawesome/fontawesome-pro/css/solid.css'
import '@fortawesome/fontawesome-pro/css/brands.css'
import '@/assets/scss/bulma-customized.scss'
import 'animate.css/animate.css'

import 'vue-json-pretty/lib/styles.css'

const VueMoment = require('vue-moment')
const VueTruncate = require('vue-truncate-filter')
Vue.config.productionTip = false
Vue.use(Buefy, { defaultIconPack: 'far' })
Vue.use(VueAxios, axios)

Vue.use(VueMoment)
Vue.use(VueTruncate)

VeeValidate.Validator.localize('de', validatorLocaleDE)
Vue.use(VeeValidate, { inject: false, locale: 'de' })
// Global VeeValidateComponents
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

// extend('min', {
//   validate (value, args) {
//     return value.length >= args.length
//   },
//   message: 'Mindestlänge {length} Zeichen.',
//   params: ['length']
// })

Vue.filter('price', (value) => {
  if (value == null) { return '' }
  const formatted = Number.parseFloat(value)
    .toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  return `${formatted} €`
})

Vue.filter('vat', (value) => {
  if (value == null) { return '' }
  const formatted = Number.parseFloat(value)
  if ((value * 100) % 1 !== 0) {
    return `${Math.round(formatted * 100 * 10) / 10} %`
  } else {
    return `${Math.round(formatted * 100)} %`
  }
})

Vue.filter('percent', (value) => {
  if (value == null) { return '' }
  const formatted = Number.parseFloat(value)
    .toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    })
  return `${formatted} %`
})

Vue.filter('address', (value) => {
  if (typeof value !== 'object') {
    return '-'
  }
  const a = value ?? {}
  let address = a.name + '\n'
  if (a.additional1) {
    address += `${a.additional1}\n`
  }
  if (a.additional2) {
    address += `${a.additional2}\n`
  }
  address += `${a.street ?? ''} ${a.houseNumber ?? ''}\n`
  address += `${a.postcode ?? ''} ${a.city ?? ''}\n`
  if (a.countryCode) {
    address += countries[a.countryCode].name
  }

  return address
})

Vue.use(VueClipboard)
Vue.use(CleavePlugin)

// SETUP AUTH TOKEN
Sentry.init({
  dsn: 'https://b58bcb1183b640e0904ed28b75ff6dac@sentry.io/1441860',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', 'lizenz.metacom.shop', /^\//]
    })
  ]
})

// Set axios api base url
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
// Init authorization token
store.dispatch('auth/setAuthorizationToken')

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: {
    App
  },
  template: '<App/>'
})
