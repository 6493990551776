<template lang="pug">
   .container.page
    h1.title Alle Upgrade-Sessions
    .content
      p Eine Upgrade-Session wird jedes Mal erzeugt, wenn sich im Lizenz-Assistenten für die Erweiterung einer bestehenden Lizenz oder ein Update angemeldet wird. Außerdem werden Upgrade-Sessions auch für Neukäufe angelegt, für die ein Angebot erstellt wird.
      .columns
        .column
          //- Filters
          //- query-builder(
          //-   :fields="filterFields"
          //-   :filters="filters"
          //-   @update:filter="onFilterChange($event)"
          //- )
        .column
          b-field(label="Elemente pro Seite")
            b-select(
              v-model="itemsPerPage"
              @input="onItemsPerPageChange($event)"
            )
              option(:value="20") 20
              option(:value="50") 50
              option(:value="100") 100
              option(:value="Infinity") Alle

      //- Error message
      error-message(
        :error="fetchError"
        :is-loading="isLoading"
        @retry="fetchItems()"
        show-raw
      )

    b-table(
      :data="items"
      :loading="isLoading"

      paginated
      backend-pagination
      :total="totalItems"
      :per-page="itemsPerPage"
      :current-page="currentPage"
      @page-change="onPageChange"

      backend-sorting
      :default-sort-direction="defaultSortOrder"
      :default-sort="[sortField, sortOrder]"
      @sort="onSort"

      detailed
      detail-key="_id"
    )
      b-table-column(
        label="Art"
        v-slot="props"
      )
        b-taglist(attached)
          b-tag(:type="props.row.purchaseType === 'new' ? 'is-info' : props.row.purchaseType === 'upgrade' ? 'is-update' : 'is-full' ") {{ props.row.purchaseType === 'upgrade' ? 'Update' : props.row.purchaseType === 'new' ? 'Neukauf' : 'Erweiterung' }}
          b-tag {{ props.row.fromVersion }}
          b-tag: b-icon(icon="arrow-right")
          b-tag {{ props.row.toVersion }}
      b-table-column(
        field="order"
        label="Bestellung"
        v-slot="props"
      )
        order-tag(v-if="props.row.orderId" :orderNumber="String(props.row.orderId)")
        span(v-else) -
      b-table-column(
        field="offerNumber"
        label="Angebot-Nr."
        v-slot="props"
      ) {{ props.row.offerNumber }}
      b-table-column(
        field="progress"
        label="Status"
        v-slot="props"
      )
        upgrade-session-status-tag(v-if="props.row.status" :status="props.row.status")
        span(v-else) -
      b-table-column(
        field="createdAt"
        label="Erzeugt am"
        sortable
        v-slot="props"
      )
        .tags(v-if="props.row.createdAt")
          date-tag(:date="props.row.createdAt")
          time-tag(:date="props.row.createdAt")
      template(v-slot:detail="props")
        router-link.is-pulled-right(
          :to="'/backend/upgrade-session/' + props.row._id"
          target="_blank"
          title="In neuem Tab öffnen (Shift-Klick für neues Fenster)"
        ): span.icon: i.fas.fa-external-link
        .content: h3 Details der Upgrade-Session {{ props.row._id }}
        upgrade-session-details(:upgrade-session="props.row")
</template>

<script>
import FilterHelper from '@/lib/filter-helper'
import UpgradeSessionsAPI from '@/services/api/UpgradeSession'

import QueryBuilder from '@/components/admin/query-builder/QueryBuilder'
import ErrorMessage from '@/components/general/ErrorMessage'
import UpgradeSessionDetails from '@/components/admin/upgrade-session/UpgradeSessionDetails'
import UpgradeSessionStatusTag from '@/components/admin/upgrade-session/UpgradeSessionStatusTag.vue'

import OrderTag from '@/components/general/OrderTag'
import DateTag from '@/components/general/DateTag'
import TimeTag from '@/components/general/TimeTag'

export default {
  components: {
    UpgradeSessionDetails, ErrorMessage, QueryBuilder, UpgradeSessionStatusTag, OrderTag, DateTag, TimeTag
  },

  /**
   * When the current route is updated
   */
  beforeRouteUpdate (to, from, next) {
    this.updateQueryFromRoute(to) // Load updated query from route
    this.fetchItems() // Fetch the order generations
    next()
  },
  data: function () {
    return {
      isLoading: false,
      fetchError: null,

      items: [],
      totalItems: 0,

      itemsPerPage: 20,
      currentPage: 1,
      sortField: 'createdAt',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      filters: [],

      filterFields: FilterHelper.upgradeSessionFields

    }
  },

  /**
   * When component is mounted
   */
  mounted () {
    this.updateQueryFromRoute(this.$route) // Load initial query from route
    this.fetchItems() //
  },

  methods: {
    fetchItems: async function () {
      this.isLoading = true
      try {
        const query = this.getCurrentQueryParams()
        const { items, total } = (await UpgradeSessionsAPI.list(query)).data.data

        this.isLoading = false
        this.fetchError = null

        this.items = items
        this.totalItems = total

        if (this.totalItems > 0 && ((this.currentPage - 1) * this.itemsPerPage) > this.totalItems) {
          // Current page is out of view (e.g. after filter change)
          this.currentPage = Math.ceil(this.totalItems / this.itemsPerPage) // Change current route
          this.updateRouteForQuery()
        }
      } catch (error) {
        console.error(error)
        this.isLoading = false
        this.fetchError = error
      }
    },

    /**
     * Get the query params to fetch current data
     */
    getCurrentQueryParams () {
      const queryComponents = [
        `limit=${this.itemsPerPage}`,
        `skip=${(this.currentPage - 1) * this.itemsPerPage}`,
        `sort=${(this.sortOrder === 'desc' ? '-' : '') + this.sortField}`
      ]
      if (this.filters.length > 0) {
        // Translate filters into query and concatenate them
        this.filters.forEach(filter => {
          queryComponents.push(FilterHelper.getQueryForFilter(filter))
        })
      }
      return queryComponents.join('&')
    },

    /**
     * Update the data query from the current route
     * Will read sort order, per page count and filters from url
     */
    updateQueryFromRoute (route) {
      if (route.query.filter) {
        this.filters = FilterHelper.decodeFromURL(route.query.filter)
      } else {
        this.filters = []
      }

      if (route.query.limit) {
        this.itemsPerPage = Number.parseInt(route.query.limit)
      }
      if (route.query.skip) {
        this.currentPage = Math.floor(Number.parseInt(route.query.skip) / this.itemsPerPage) + 1
      }
      if (route.query.sort) {
        this.sortOrder = route.query.sort.startsWith('-') ? 'desc' : 'asc'
        this.sortField = route.query.sort.split('-').pop()
      }
    },

    /**
     * Update the route to represent the current data query
     * Will put sort order, per page count and filters into the url
     */
    updateRouteForQuery () {
      const query = {
        limit: this.itemsPerPage,
        skip: (this.currentPage - 1) * this.itemsPerPage,
        sort: (this.sortOrder === 'desc' ? '-' : '') + this.sortField
      }
      if (this.filters.length > 0) {
        query.filter = FilterHelper.encodeForURL(this.filters)
      }
      this.$router.push({
        name: 'upgrade-sessions',
        query
      })
    },

    /**
     * Handle changes to the current filter
     * Will update the route and fetch with the changed query
     */
    onFilterChange (filters) {
      this.filters = filters
      this.updateRouteForQuery()
    },

    /**
     * Handle page changes
     * Fetch order generations for the current page
     */
    onPageChange (page) {
      this.currentPage = page
      this.updateRouteForQuery()
    },

    /**
     * Handle sorting changes
     * Fetch order generations for the changed sorting
     */
    onSort (field, order) {
      this.sortField = field
      this.sortOrder = order
      this.updateRouteForQuery()
    },

    onItemsPerPageChange (itemsPerPage) {
      this.itemsPerPage = itemsPerPage
      this.updateRouteForQuery()
    }

  }

}
</script>
