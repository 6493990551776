<template lang="pug">
  section.section
    .container
      h1.title Downloads
      .columns
        .column
          //- Filters
          query-builder(
            :fields="filterFields"
            :filters="filters"
            @update:filter="onFilterChange($event)"
          )
        .column
          b-field(label="Downloads pro Seite")
            b-select(
              v-model="downloadsPerPage"
              @input="onDownloadsPerPageChange($event)"
            )
              option(:value="20") 20
              option(:value="50") 50
              option(:value="100") 100
              option(:value="Infinity") Alle

      //- Error message
      error-message(
        :error="fetchError"
        :is-loading="isLoading"
        @retry="fetchDownloads()"
        show-raw
      )

      b-table(
        :data="downloads"
        :loading="isLoading"

        paginated
        backend-pagination
        :total="totalDownloads"
        :per-page="downloadsPerPage"
        :current-page="currentPage"
        @page-change="onPageChange"

        backend-sorting
        :default-sort-direction="defaultSortOrder"
        :default-sort="[sortField, sortOrder]"
        @sort="onSort"
      )
        b-table-column(
          field="_id"
          label="ID"
          sortable
          v-slot="props"
        ) {{ props.row._id }}
        b-table-column(
          field="object"
          label="Objekt"
          sortable
          v-slot="props"
        ) {{ props.row.object }}

        b-table-column(
          field="version"
          label="Version"
          sortable
          v-slot="props"
        ) {{ props.row.version }}
        b-table-column(
          field="createdAt"
          label="Datum"
          sortable
          v-slot="props"
        ) {{ props.row.createdAt | moment('DD.MM.YYYY hh:mm:ss') }}
        b-table-column(
          field="license"
          label="Lizenznehmer"
          v-slot="props"
        )
          span(v-if="props.row.license.holder") {{ props.row.license.holder.name }} ({{ props.row.license.holder.email }})
          span(v-if="!props.row.license.holder") -
          router-link(
            :to="'/backend/license/' + props.row.license._id"
            target="_blank"
            title="In neuem Tab öffnen (Shift-Klick für neues Fenster)"
          ): span.icon: i.fas.fa-external-link

        b-table-column(
          field="download-count"
          label="Gesamt"
          v-slot="props"
        ) {{ props.row.license.downloads && props.row.license.downloads[props.row.object] ? props.row.license.downloads[props.row.object] : '' }}

        template(slot="bottom-left")
          b {{ totalDownloads }} Downloads
</template>

<script>
import DownloadAPI from '@/services/api/Download'
import QueryBuilder from '@/components/admin/query-builder/QueryBuilder'
import ErrorMessage from '@/components/general/ErrorMessage'
import FilterHelper from '@/lib/filter-helper'
export default {
  components: {
    QueryBuilder, ErrorMessage
  },

  /**
   * When the current route is updated
   */
  beforeRouteUpdate (to, from, next) {
    this.updateQueryFromRoute(to) // Load updated query from route
    this.fetchDownloads() // Fetch downloads
    next()
  },
  data () {
    return {
      filterFields: FilterHelper.downloadFields,

      downloads: [],
      totalDownloads: 0,

      downloadsPerPage: 20,
      currentPage: 1,
      sortField: 'createdAt',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      filters: [],

      isLoading: false,
      fetchError: null

    }
  },
  /**
   * When component is mounted
   */
  mounted () {
    this.updateQueryFromRoute(this.$route) // Load initial query from route
    this.fetchDownloads() // Fetch the downloads
  },

  methods: {
    /**
     * Fetch the downloads from the api
     */
    async fetchDownloads () {
      this.isLoading = true

      // Perform fetch using current query
      try {
        const query = this.getCurrentQueryParams()
        const { downloads, total } = (await DownloadAPI.list(query)).data.data
        this.fetchError = null
        this.isLoading = false

        this.downloads = downloads
        this.totalDownloads = total

        if (this.totalDownloads > 0 && ((this.currentPage - 1) * this.downloadsPerPage) > this.totalDownloads) {
          // Current page is out of view (e.g. after filter change)
          this.currentPage = Math.ceil(this.totalDownloads / this.downloadsPerPage) // Change current route
          this.updateRouteForQuery()
        }
      } catch (error) {
        console.error(error)
        this.fetchError = error
        this.isLoading = false
      }
    },

    /**
     * Get the query params to fetch current data
     */
    getCurrentQueryParams () {
      const queryComponents = [
        `limit=${this.downloadsPerPage}`,
        `skip=${(this.currentPage - 1) * this.downloadsPerPage}`,
        `sort=${(this.sortOrder === 'desc' ? '-' : '') + this.sortField}`
      ]
      if (this.filters.length > 0) {
        // Translate filters into query and concatenate them
        this.filters.forEach(filter => {
          queryComponents.push(FilterHelper.getQueryForFilter(filter))
        })
      }
      return queryComponents.join('&')
    },

    /**
     * Update the data query from the current route
     * Will read sort order, per page count and filters from url
     */
    updateQueryFromRoute (route) {
      if (route.query.filter) {
        this.filters = FilterHelper.decodeFromURL(route.query.filter)
      } else {
        this.filters = []
      }

      if (route.query.limit) {
        this.downloadsPerPage = Number.parseInt(route.query.limit)
      }
      if (route.query.skip) {
        this.currentPage = Math.floor(Number.parseInt(route.query.skip) / this.downloadsPerPage) + 1
      }
      if (route.query.sort) {
        this.sortOrder = route.query.sort.startsWith('-') ? 'desc' : 'asc'
        this.sortField = route.query.sort.split('-').pop()
      }
    },

    /**
     * Update the route to represent the current data query
     * Will put sort order, per page count and filters into the url
     */
    updateRouteForQuery () {
      const query = {
        limit: this.downloadsPerPage,
        skip: (this.currentPage - 1) * this.downloadsPerPage,
        sort: (this.sortOrder === 'desc' ? '-' : '') + this.sortField
      }
      if (this.filters.length > 0) {
        query.filter = FilterHelper.encodeForURL(this.filters)
      }
      this.$router.push({
        name: 'downloads',
        query
      })
    },

    /**
     * Handle changes to the current filter
     * Will update the route and fetch with the changed query
     */
    onFilterChange (filters) {
      this.filters = filters
      this.updateRouteForQuery()
    },

    /**
     * Handle page changes
     * Will update the route and fetch with the changed query
     */
    onPageChange (page) {
      this.currentPage = page
      this.updateRouteForQuery() // Update route, will fetch automatically
    },

    /**
     * Handle sorting changes
     * Will update the route and fetch with the changed query
     */
    onSort (field, order) {
      this.sortField = field
      this.sortOrder = order
      this.updateRouteForQuery() // Update route, will fetch automatically
    },

    /**
     * Handle change of displayed downloads per page
     * Will update the route and fetch with the changed query
     */
    onDownloadsPerPageChange (downloadsPerPage) {
      this.downloadsPerPage = downloadsPerPage
      this.updateRouteForQuery() // Update route, will fetch automatically
    }
  }
}
</script>
