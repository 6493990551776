import axios from 'axios'
import AuthAPI from '@/services/api/Auth'

// Fix for using the application inside an iframe
let storage = null
try {
  if (typeof localStorage !== 'undefined') { storage = localStorage }
} catch (error) {
  console.warn('LocalStorage is unavailable.', error)
}

const state = {
  token: storage?.getItem('user-token') || '',
  status: '',
  userData: null
}
const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status,
  userData: state => state.userData,
  tokenData: state => state.token ? JSON.parse(atob(state.token.split('.')[1])) : null,
  userType: (state, getters) => getters.tokenData ? getters.tokenData.userType : null
}
// basic mutations, showing loading, success, error to reflect the api call status and the token when loaded

const mutations = {
  updateAuthRequested (state) {
    state.status = 'requested'
  },
  updateAuthError (state) {
    state.status = 'error'
  },
  updateAuthSuccess (state, response) {
    state.status = 'success'
    state.token = response.data.data.token
    state.userData = response.data.data.userData
  },
  updateAuthLogout (state) {
    state.status = ''
    state.token = ''
    state.userData = undefined
  },
  updateAuthUserData (state, userData) {
    state.userData = userData
  },
  setLoggedIn (state, { token, userData }) {
    state.token = token
    state.status = 'success'
    state.userData = userData
  }
}

const actions = {
  async login ({ commit, dispatch }, loginData) {
    commit('updateAuthRequested')
    try {
      const response = await AuthAPI.authenticateUserOfType(loginData.type, loginData.data)
      const token = response.data.data.token
      storage.setItem('user-token', token)
      commit('updateAuthSuccess', response)
      await dispatch('setAuthorizationToken')
    } catch (err) {
      commit('updateAuthError')
      storage.removeItem('user-token')
      throw err
    }
  },
  setAuthorizationToken ({ state }) {
    if (state.token) {
      axios.defaults.headers.common.Authorization = 'Bearer ' + state.token
    } else {
      delete axios.defaults.headers.common.Authorization
    }
  },
  logout ({ commit, dispatch }) {
    commit('updateAuthLogout')
    storage.removeItem('user-token')
    // remove the axios default header
    delete axios.defaults.headers.common.Authorization
  },
  /**
   * Get the user
   * Will return either the cached instance or fetch the user from source
   * @param {Object} options
   */
  async ensureCurrentUser ({ dispatch, state }) {
    if (!state.userData) {
      await dispatch('fetchUser')
    }
  },
  /**
   * Reload a user from source
   * @param {Object} options
   */
  async fetchUser ({ commit, state }) {
    try {
      const response = await AuthAPI.getCurrent()
      commit('updateAuthUserData', response.data.data.user)
    } catch (err) {
      commit('updateAuthError')
      throw err
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
