<template lang="pug">
  .container
    .columns
      .column.is-6.is-offset-3
        footer.is-dark
          footer-links
</template>

<script>
import FooterLinks from '@/components/general/FooterLinks'
export default {
  components: { FooterLinks }
}
</script>

<style lang="scss" scoped>
  .container {
    margin-top: 40px;
  }
</style>
