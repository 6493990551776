<template lang="pug">
widget-panel(title="Downloads", icon="fa-tasks")
    template(slot="actions")
      router-link(:to="downloadsLink") Alle anzeigen
    template(slot="content")
      table.table
        tbody
          tr
            th METACOM
            td(v-if="!editing") {{ metacomDownloads }}
            td(v-if="editing")
              b-input(
                :value="metacomDownloads"
                @input="metacomDownloadsChanged($event)"
                type="number"
              )
          tr
            th MetaSearch
            td(v-if="!editing") {{ metasearchDownloads }}
            td(v-if="editing")
              b-input(
                :value="metasearchDownloads"
                @input="metasearchDownloadsChanged($event)"
                type="number"
              )
</template>

<script>
import licenseMixin from '@/mixins/license'
import WidgetPanel from '@/components/general/WidgetPanel'
export default {
  components: { WidgetPanel },
  mixins: [licenseMixin],
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    licenseId: {
      type: String,
      required: true
    },
    metacomDownloads: {
      type: Number,
      default: undefined
    },
    metasearchDownloads: {
      type: Number,
      default: undefined
    }
  },
  computed: {
    downloadsLink () {
      return '/backend/download?license=' + this.licenseId
    }
  },
  methods: {
    metacomDownloadsChanged (event) {
      const parsed = parseInt(event)
      this.$emit('update:metacom-downloads', Number.isNaN(parsed) ? 0 : parsed)
    },
    metasearchDownloadsChanged (event) {
      const parsed = parseInt(event)
      this.$emit('update:metasearch-downloads', Number.isNaN(parsed) ? 0 : parsed)
    }
  }
}
</script>
