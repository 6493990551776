<template lang="pug">
  form(
    @submit.prevent="validateForm()"
  )
    b-message(type="is-warning")
      span Sie binden diese Lizenz #[strong einmalig] an Ihr Unternehmen.&nbsp;
      span(v-if="license && license.properties && license.properties.delivery === 'download'") Nach dem Binden dieser Lizenz können Sie METACOM herunterladen und Ihren Mitarbeitern über eine Freigabe oder ein Speichermedium zur Verfügung stellen.
      br
      br
      span Bitte geben Sie eine langfristig gültige Mailadresse und einen Ansprechpartner an, der zur Verwaltung der Lizenz berechtigt ist (z.B. ein Administrator oder Verwaltungsmitarbeiter).

    //- NAME
    b-field(
      label="Name des Lizenznehmers",
      :type="errors.has('name') ? 'is-danger' : ''",
      :message="errors.first('name')"
    )
      b-field(
        :type="errors.has('name') ? 'is-danger' : ''"
      )
        b-input(
          icon="building",
          placeholder="Vollständiger Name des Unternehmens bzw. der Einrichtung ohne Adresse" ,
          expanded,
          v-model="name",
          name="name",
          data-vv-as="den Namen des Lizenznehmers",
          v-validate="'required'",
          :type="errors.has('name') ? 'is-danger' : ''"
        )
        p.control
          b-dropdown(hoverable, position="is-bottom-left")
            p.button.is-dark(slot="trigger") ?
            b-dropdown-item(custom)
              p Die Lizenz wird an dieses Unternehmen bzw diese Einrichtung gebunden. Sie müssen den Namen der Einrichtung in Zukunft exakt eingeben, wenn Sie sich mit Ihrer Lizenz anmelden möchten. Geben Sie daher nur den Namen der Einrichtung ohne Adresse ein.

    b-field(
      label="Ansprechpartner",
      :type="errors.has('contact') ? 'is-danger' : ''",
      :message="errors.first('contact')"
      )
      b-field(
        :type="errors.has('contact') ? 'is-danger' : ''"
      )
          b-input(
            icon="user",
            placeholder="Vor- und Nachname" ,
            expanded,
            name="contact",
            data-vv-as="den Kontakt",
            v-model="contact",
            v-validate="'required'",
            :type="errors.has('contact') ? 'is-danger' : ''"
          )
          p.control
            b-dropdown(hoverable, position="is-bottom-left")
              p.button.is-dark(slot="trigger") ?
              b-dropdown-item(custom)
                p Dies ist der Ansprechpartner in Ihrer Organisation für diese Lizenz. Falls wir Sie bezüglich Ihrer Lizenz kontaktieren oder Ihnen E-Mails mit Lizenzdokumenten schicken, werden wir uns an diese Person wenden.
    //- EMAIL ADDRESS
    b-field(
      label="E-Mail Adresse",
      :type="errors.has('companymail') ? 'is-danger' : ''"
      :message="errors.first('companymail')",
    )
      b-field(:type="errors.has('companymail') ? 'is-danger' : ''")
        b-input(
          icon="envelope",
          placeholder="Ihre E-Mail Adresse" ,
          expanded,
          v-model="email",
          v-validate="'required|email'",
          data-vv-as="der E-Mail Adresse",
          name="companymail",
          ref="companymail"
        )
        p.control
          b-dropdown(hoverable, position="is-bottom-left")
            p.button.is-dark(slot="trigger") ?
            b-dropdown-item(custom)
              p An diese E-Mail Adresse wird Ihre gebundene Lizenz versandt.
    //- EMAIL ADDRESS CONFIRMATION
    b-field(
      :type="errors.has('companymail_confirmation') ? 'is-danger' : ''"
      :message="errors.first('companymail_confirmation')",
    )
      b-field(
        :type="errors.has('companymail_confirmation') ? 'is-danger' : ''",
      )
        b-input(
          icon="envelope",
          placeholder="E-Mail Adresse wiederholen" ,
          expanded,
          v-model="emailConfirmation",
          v-validate="'required|confirmed:companymail'",
          data-vv-as="der Wiederholung der E-Mail Adresse",
          name="companymail_confirmation"
        )
        p.control
          b-dropdown(hoverable, position="is-bottom-left")
            p.button.is-dark(slot="trigger") ?
            b-dropdown-item(custom)
              p An diese E-Mail Adresse wird Ihre gebundene Lizenz versandt.
    //- CONFIRM AUTHORIZATION
    b-field(
      label="Weitere erforderliche Angaben",
      :message="errors.first('authorized')",
      :type="errors.has('authorized') ? 'is-danger' : ''"
    )
      b-checkbox(
        v-validate="'required'",
        name="authorized",
        v-model="authorized",
        :type="errors.has('authorized') ? 'is-danger' : ''"
      ) Ich bin autorisiert diese Lizenz an die angegebene Organisation/Einrichtung bzw. das angegebene Unternehmen zu binden
    //- PRIVACY POLICY
    b-field(
      :message="errors.first('privacy')",
      :type="errors.has('privacy') ? 'is-danger' : ''"
    )
      b-checkbox(
        v-validate="'required'",
        name="privacy",
        v-model="privacy",
        :type="errors.has('privacy') ? 'is-danger' : ''"
      ) Ich habe die #[a(href="https://metacom.shop/datenschutzerklaerung", target="_blank") Datenschutzerklärung] gelesen und akzeptiere, wie meine angegebenen Daten auf dieser Seite verarbeitet werden.

    .field: p.control
      button.submit.button.is-primary.is-fullwidth(type="submit") Diese Lizenz jetzt an diese Angaben binden

</template>

<script>
export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  props: {
    license: {
      default: null,
      type: Object
    }
  },
  data: () => {
    return {
      name: '',
      contact: '',
      email: '',
      emailConfirmation: '',
      authorized: '',
      privacy: ''
    }
  },
  methods: {
    validateForm () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit('submit', {
            name: this.name,
            contact: this.contact,
            email: this.email,
            emailConfirmation: this.emailConfirmation
          })
        }
      })
    }
  }
}
</script>
