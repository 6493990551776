<template lang="pug">
  .tag(v-bind:class="{'is-secondary': progress === 'in-progress', 'is-danger': progress === 'failed', 'is-success': progress === 'completed'}") {{ title }}
</template>

<script>
export default {
  props: {
    progress: {
      type: String,
      default: ''
    }
  },
  computed: {
    title: function () {
      switch (this.progress) {
        case 'in-progress': return 'In Bearbeitung'
        case 'failed': return 'Fehlgeschlagen'
        case 'completed': return 'Erfolgreich'
        default: return this.progress
      }
    }
  }
}
</script>
