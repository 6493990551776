<template>
  <div class="offer-load-form">
    <error-message
      :error="loadOfferError"
      title="Fehler beim Laden des Angebots"
      :message-by-status="{
        403: 'Es konnte kein Angebot zu diesem Angebotscode gefunden werden. Bitte überprüfen Sie Ihre Eingabe und achten Sie auf die Groß- und Kleinschreibung.',
        410: 'Das zu diesem Angebotscode gefundene Angebot ist bereits abgelaufen. Sie könenn den Lizenz-Assistenten erneut durchlaufen, um ein neues Angebot zu erhalten oder die Bestellung direkt aufzugeben.'
      }"
      :allow-retry="false"
    />
    <b-field
      grouped
      class="mt-5"
    >
      <b-input
        v-model="token"
        v-cleave="{
          delimiter: ' ',
          blocks: [3, 3, 3],
          uppercase: false
        }"
        placeholder="Angebotscode"
        @keyup.native.enter="load"
      />
      <b-button
        type="is-primary"
        :loading="isLoadingOffer"
        @click="load"
      >
        Angebot laden
      </b-button>
    </b-field>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ErrorMessage from '@/components/general/ErrorMessage.vue'
export default {
  components: {
    ErrorMessage
  },
  data () {
    return {
      token: null
    }
  },
  computed: {
    ...mapState('assistant', ['isLoadingOffer', 'loadOfferError'])
  },
  methods: {
    async load () {
      const token = this.token.replace(/\s/g, '')
      const session = await this.$store.dispatch('assistant/loadOffer', token)

      if (session.status !== 'offered') {
        this.$buefy.dialog.alert({
          type: 'is-danger',
          title: 'Nicht mehr verfügbar',
          message: 'Das gewählte Angebot wurde bereits bestellt!'
        })
        return
      }
      if (session) {
        this.$emit('loaded')
      }
    }
  }
}
</script>
