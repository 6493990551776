<template lang="pug">
  #delivery-single
    section.section
      .container.page
        error-message(
          :error="fetchDataError"
          :isLoading="isLoading"
          show-raw, @retry="fetchData()"
          prefix="Fehler beim Abrufen der Versand-Aktion."
        )
        .nav.panel
          p.panel-heading Details des Versands {{ $route.params.id }}
          .panel-block
            b-loading(:active.sync="isLoading")
            delivery-details(:delivery="delivery", v-if="delivery != null")
</template>

<script>
import DeliveryAPI from '@/services/api/Delivery'
import ErrorMessage from '@/components/general/ErrorMessage'
import DeliveryDetails from '@/components/admin/delivery/DeliveryDetails'

export default {
  components: {
    ErrorMessage, DeliveryDetails
  },
  data: function () {
    return {
      isLoading: false,
      fetchDataError: null,
      delivery: null
    }
  },
  mounted: function () {
    this.fetchData()
  },
  methods: {
    /**
     * Fetch delivery from api
     */
    fetchData: async function () {
      this.isLoading = true
      this.fetchDataError = null
      try {
        this.isLoading = false
        this.delivery = (await DeliveryAPI.getByID(this.$route.params.id)).data.data.delivery
      } catch (error) {
        this.isLoading = false
        this.fetchDataError = error
        console.error(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .panel-block .delivery-details {
    width: 100%;
  }
</style>
