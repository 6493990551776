<template lang="pug">
  .tag(:class="classes") {{ title }}
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      default: ''
    }
  },
  computed: {
    title: function () {
      switch (this.status) {
        case 'created': return 'Neu erstellt'
        case 'offered': return 'Angebot erstellt'
        case 'purchased': return 'Gekauft'
        case 'in-progress': return 'Lizenz in Bearbeitung'
        case 'failed': return 'Fehlgeschlagen'
        case 'completed': return 'Abgeschlossen'
        case 'expired': return 'Abgelaufen'
        default: return this.status
      }
    },
    classes: function () {
      switch (this.status) {
        case 'created': return 'is-info'
        case 'purchased': return 'is-success'
        case 'completed': return 'is-primary'
        default: return ''
      }
    }
  }
}
</script>
