<template lang="pug">
  .copy-code(
    title="Wert kopieren"
    @click="performCopy()"
  )
    code {{ displayValue || copyValue }}
    i.icon.is-small: i.fas.fa-copy

</template>

<script>
export default {
  props: {
    displayValue: {
      type: String,
      default: null
    },
    copyValue: {
      type: String,
      required: true
    }
  },
  methods: {
    performCopy: function () {
      this.$copyText(this.copyValue)
        .then(() => {
          this.$buefy.toast.open({
            message: `„${this.copyValue}” in Zwischenablage kopiert`,
            type: 'is-success'
          })
        })
        .catch(error => {
          this.$buefy.toast.open({
            message: 'Fehler beim Kopieren: ' + error,
            type: 'is-danger'
          })
        })
    }
  }

}
</script>

<style lang="scss" scoped>
  @import '@/assets/scss/bulma-variables.scss';
  .copy-code {
    display: inline-flex;
    align-items: center;
    cursor: pointer;

    code {
      color: $dark;
    }
    i {
      margin-left: 0.3rem;
      color: $medium;
    }

    &:hover {
      color: $primary;
      code, i {
        color: $primary;
      }
    }

  }
</style>
