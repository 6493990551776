import axios from 'axios'

export default {
  /**
   * Retrieve all licenses
   */
  list: async function (query = null) {
    return axios.get('/api/v1/order-generation/' + (query ? '?' + query : ''))
  },
  /**
   * Fetch deliveries for an order generation with id
   */
  getDeliveries: async function (orderGenerationID) {
    return axios.get(`api/v1/order-generation/${orderGenerationID}/delivery`)
  },
  /**
   * Get an order generation by its id
   * @param {String} orderGenerationID - the order generation id
   */
  getByID: async function (orderGenerationID) {
    return axios.get(`api/v1/order-generation/${orderGenerationID}`)
  }
}
