<template>
  <div class="upgrade-sessions-tab">
    <div class="content">
      <p>
        Hier tauchen Upgrade-Sessions auf, die mit dieser Lizenz in Verbindung stehen.
      </p>
      <b-message v-if="upgradeSessions.length === 0">
        Keine Upgrade-Session gefunden..
      </b-message>
      <ul
        v-for="upgradeSession in upgradeSessions"
        :key="upgradeSession._id"
      >
        <li>
          <router-link
            :to="{ name: 'upgrade-session-details', params: { id: upgradeSession._id }}"
            target="_blank"
            title="In neuem Tab öffnen (Shift-Klick für neues Fenster)"
          >
            <span class="icon"><i class=".fas.fa-external-link" /></span>
            <span>{{ upgradeSession._id }}</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import LicenseAPI from '@/services/api/License'
export default {
  props: {
    license: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      fetchDataError: null,
      upgradeSessions: []
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    async fetchData () {
      this.isLoading = true
      this.fetchDataError = null
      try {
        const response = await LicenseAPI.getUpgradeSessionsForLicense(this.license._id)
        this.isLoading = false
        this.upgradeSessions = response.data.data.upgradeSessions
      } catch (error) {
        console.error(error)
        this.isLoading = false
        this.fetchDataError = {
          status: error.response?.status,
          response: error.response?.data
        }
      }
    }
  }
}
</script>
