import Cleave from 'cleave.js'

const CleavePlugin = {
  install (app, options) {
    app.directive('cleave', {
      inserted: (el, binding, vnode) => {
        const input = el.nodeName === 'INPUT' ? el : el.querySelector('input')
        input.cleave = new Cleave(input, binding.value || {})
      },
      update: (el, binding, vnode) => {
      }
    })
  }
}

export default CleavePlugin
