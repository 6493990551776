<template>
  <div class="offer-create-form">
    <h4 class="subtitle">
      Rechnungsadresse
    </h4>
    <b-loading :active="isCreatingOffer" />
    <address-form
      :value="billing"
      @input="updateBilling"
    />
    <b-field
      class="mt-2"
      label="E-Mail Adresse"
      :type="errors.has('email') ? 'is-danger' : ''"
      :message="errors.first('email')"
    >
      <b-input
        ref="email"
        v-validate="'required|email'"
        :value="billing.email"
        type="email"
        icon="envelope"
        placeholder="Ihre E-Mail Adresse"
        expanded
        name="email"
        data-vv-as="E-Mail Adresse"
        @input="updateBillingField({ key: 'email', value: $event })"
      />
    </b-field>
    <b-field
      label="E-Mail Adresse bestätigen"
      :type="errors.has('email_confirmation') ? 'is-danger' : ''"
      :message="errors.first('email_confirmation')"
    >
      <b-input
        v-model="emailConfirmation"
        v-validate="'required|confirmed:email'"
        icon="envelope"
        placeholder="E-Mail Adresse wiederholen"
        expanded
        name="email_confirmation"
        data-vv-as="Wiederholung der E-Mail Adresse"
        @paste.native="$event => $event.preventDefault()"
      />
    </b-field>

    <h4 class="subtitle">
      Zusätzliche Angaben
    </h4>
    <b-field label="Ihre Referenz (optional, wird auf das Angebot gedruckt)">
      <b-input
        type="text"
        placeholder="Ihre Referenz"
        maxlength="30"
        :value="billing.reference"
        @input="updateBillingField({ key: 'reference', value: $event })"
      />
    </b-field>

    <b-field label="Ihre Notiz (optional, wird auf das Angebot gedruckt)" />
    <b-input
      type="textarea"
      maxlength="160"
      :value="billing.note"
      placeholder="Ihre Notiz"
      @input="updateBillingField({ key: 'note', value: $event })"
    />
    <div
      v-if="orderHasShipping"
      class="pt-4 pb-4"
    >
      <h4 class="subtitle">
        Lieferadresse
      </h4>
      <b-switch v-model="shipToDifferentAddress">
        Abweichende Lieferadresse verwenden
      </b-switch>

      <address-form
        v-if="shipToDifferentAddress"
        :value="shipping"
        @input="updateShipping"
      />
    </div>

    <b-field
      class="mt-5"
      label="Positionen des Angebots"
    >
      <cart-summary />
    </b-field>

    <p>Nach dem Erstellen eines Angebots kann die ausgewählte Lizenz und Lizenzgröße nicht mehr verändert werden.</p>
    <p>Um ein neues Angebot zu erstellen muss der Lizenz-Assistent erneut gestartet werden.</p>
    <b-button
      :disabled="errors.any()"
      type="is-primary"
      expanded
      @click="createOffer"
    >
      Angebot erstellen
    </b-button>
  </div>
</template>

<script>
import AddressForm from '@/components/general/AddressForm.vue'
import CartSummary from '@/components/customer/assistant/CartSummary.vue'
import { mapGetters, mapMutations, mapState } from 'vuex'
export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    AddressForm, CartSummary
  },
  data () {
    return {
      emailConfirmation: null,
      isSubmitted: false
    }
  },
  computed: {
    ...mapGetters('assistant', ['orderHasShipping']),
    ...mapState('assistant', ['billing', 'shipping', 'isCreatingOffer', 'createOfferError']),
    shipToDifferentAddress: {
      get () {
        return this.$store.state.assistant.shipToDifferentAddress
      },
      set (value) {
        this.updateState({ key: 'shipToDifferentAddress', value })
      }
    }
  },
  methods: {
    ...mapMutations('assistant', ['updateState', 'updateBilling', 'updateBillingField', 'updateShipping']),
    async createOffer () {
      if (this.isSubmitted) {
        return
      }
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          if (await this.$store.dispatch('assistant/createOffer') === true) {
            this.isSubmitted = true
            this.$emit('created')
          }
        }
      })
    }
  }
}
</script>
